import './StepsImage.css'
import { AbsoluteCenter } from '@chakra-ui/react'

function StepsImage(props) {
  const { text, number } = props

  return (
    <div className="step-01">
      <div className="frame-2482">
        <div className="number-style publicsans-normal-aqua-deep-24px">
          <AbsoluteCenter>{number}</AbsoluteCenter>
        </div>
      </div>
      <div className="publicsans-normal-nevada-24px">{text}</div>
    </div>
  )
}

export default StepsImage
