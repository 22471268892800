import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  TabPanel,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { type ChangeEvent, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiFile } from 'react-icons/fi'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { postApiV1SchoolStudents } from '../../services/school'
import type { NewBatchStudentsViewModel } from '../../services/types'
import { validateStudentFileRows } from './NovoAlunoUpload.utils'

const validationSchema = yup.object({
  studentsToInvite: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email().required(),
        name: yup.string(),
      }),
    )
    .required(),
})

type Form = yup.InferType<typeof validationSchema>

type NovoAlunoUploadProps = {
  turmaID: string
  schoolId: string
  fetchStudents: () => Promise<void>
}

export const NovoAlunoUpload = (props: NovoAlunoUploadProps) => {
  const { turmaID, schoolId, fetchStudents } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    handleSubmit,
    setValue,
    setError,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Form>({ resolver: yupResolver(validationSchema) })
  const [isParsingFile, setIsParsingFile] = useState(false)

  async function submit(body: Form) {
    const requestBody: NewBatchStudentsViewModel = {
      studentsToInvite: body.studentsToInvite,
      groupId: turmaID,
      schoolId,
      language: 'pt-br',
    }

    try {
      await postApiV1SchoolStudents(requestBody)
      await fetchStudents()
      toast.success(`${body.studentsToInvite} alunos adicionados.`)
      reset()
    } catch (error) {
      console.error('Erro ao adicionar alunos:', error)
    }
  }

  const handleFileParser = async (e: ChangeEvent<HTMLInputElement>) => {
    setError('studentsToInvite', {})
    setIsParsingFile(true)
    const data = await validateStudentFileRows(e.target?.files?.[0])
    setIsParsingFile(false)
    if (data.error) {
      setError('studentsToInvite', { message: data.error })
    }
    if (data.extractedData) {
      setValue('studentsToInvite', data.extractedData)
    }
  }

  return (
    <TabPanel>
      <Text paddingBottom={'16px'} paddingTop={'8px'} paddingLeft={'8px'} className={'publicsans-bold-gray-16px'}>
        Para convidar uma lista de alunos é necessário fornecer um arquivo em formato excel, .xlsx ou .xls.
        <br />
        <br />O arquivo deve conter ao menos as colunas de Nome e E-mail.
      </Text>
      <form onSubmit={handleSubmit(submit)}>
        <VStack>
          <FormControl isInvalid={!!errors.studentsToInvite?.message} isRequired>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                {isParsingFile ? <Spinner size="xs" marginRight="2" /> : <Icon as={FiFile} />}
              </InputLeftElement>
              <Input
                placeholder={'Selectione uma planilha'}
                onClick={() => inputRef?.current?.click()}
                readOnly={true}
                value={inputRef.current?.value || ''}
                isDisabled={isParsingFile}
                cursor={'pointer'}
              />
              <input
                id="studentsToInvite"
                type="file"
                accept=".xlsx, .xls"
                hidden
                {...register('studentsToInvite')}
                ref={inputRef}
                onChange={handleFileParser}
              />
            </InputGroup>
            <FormErrorMessage>{errors?.studentsToInvite?.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            backgroundColor={'#0A41D6'}
            color={'white'}
            width={'80%'}
            height={'48px'}
            marginTop={'15px'}
            isDisabled={!!errors.studentsToInvite?.message}
            isLoading={isSubmitting}>
            Adicionar
          </Button>
        </VStack>
      </form>
    </TabPanel>
  )
}
