import { useDeleteApiV1TeacherId } from '@/services/teacher.ts'
import {
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export function PopoverRemoveMonitor({ isOpen, onOpen, onClose, monitorID }) {
  const navigate = useNavigate()

  const { mutate: removeMonitor, isPending } = useDeleteApiV1TeacherId({
    mutation: {
      onSuccess: () => {
        toast.success('Monitor removido com sucesso')
        navigate('/area-de-monitores')
      },
      onError: () => {
        toast.error('Erro ao remover o monitor')
      },
    },
  })

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end" closeOnBlur={false}>
      <PopoverTrigger>
        <Image src={'/img/deleteIcon.svg'} minWidth={'25px'} marginRight={'5px'} cursor={'pointer'} />
      </PopoverTrigger>
      <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
        <PopoverArrow />
        <PopoverHeader>
          <div className={'text-popover'}>Confirme Remoção do Monitor</div>
        </PopoverHeader>
        <PopoverBody alignSelf={'center'} width={'100%'}>
          <VStack width={'100%'}>
            <Button
              colorScheme="red"
              height="48px"
              width="100%"
              isLoading={isPending}
              onClick={() => removeMonitor({ id: monitorID })}>
              Confirmar Remoção
            </Button>
            <Button variant="outline" height="48px" width="100%" onClick={onClose} isDisabled={isPending}>
              Cancelar
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
