import { HStack, Text } from '@chakra-ui/react'

export function UnselectedStep(props: { number: string; text: string }) {
  const { number, text } = props

  return (
    <HStack className="frame-255-cadastro" hideBelow={'md'}>
      <Text alignItems={'center'} justifyContent={'center'} className="frame-2483-cadastro publicsans-normal-shady-lady-20px">
        {number}
      </Text>
      <Text className="criar-conta-cadastro publicsans-normal-shady-lady-20px">{text}</Text>
    </HStack>
  )
}
