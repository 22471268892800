import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import * as yup from 'yup'
import FormStepNotSelected from '../../components/FormStepNotSelected'
import Headbar from '../../components/Headbar'
import { ImageUploadArea } from '../../components/ImageUploadArea/ImageUploadArea'
import StepsImage from '../../components/StepsImage'
import useCriarEscolaStore from '../../store/useCriarEscolaStore'
import { inputCNPJMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  name: yup.string().required().min(5).max(254),
  documentNumber: yup.string().required().cnpj(),
  thumbnail: yup.string(),
})

function CreateSchoolStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarEscolaData, setCriarEscolaData } = useCriarEscolaStore()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const handleImageUpload = (image) => setValue('thumbnail', image)

  async function submit(data) {
    setCriarEscolaData(data)
    handlingNextStep()
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-escolas'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading alignSelf={'start'} color="brand.200">
          Criar Escola
        </Heading>
        <VStack w={'100%'} mt={5}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(submit)}>
            <StepsImage text={'Sobre a Escola'} number={1} />
            <Grid width={'100%'} gap={5} mt={'32px'}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.thumbnail && !!errors.thumbnail.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Imagem da escola</FormLabel>
                  <ImageUploadArea onUpload={handleImageUpload} />
                  <FormErrorMessage>{errors.thumbnail?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.name?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">Nome da escola</FormLabel>
                  <Input
                    id="name"
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    {...register('name')}
                    defaultValue={criarEscolaData?.name || ''}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.documentNumber?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">CNPJ</FormLabel>
                  <Input
                    id="documentNumber"
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    {...register('documentNumber')}
                    as={InputMask}
                    mask={inputCNPJMask}
                    defaultValue={criarEscolaData?.documentNumber || ''}
                  />
                  <FormErrorMessage>{errors.documentNumber?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <VStack mt={7} gap={2} width={'100%'} alignItems={'start'} className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Endereço'} />
            </VStack>

            <HStack
              display="flex"
              justifyContent="end"
              flexDir={{ md: 'row', base: 'column-reverse' }}
              w={'100%'}
              gap={3}
              my={6}>
              <Button variant={'outline'} height={'48px'} width={'100%'} onClick={handleReturn}>
                Voltar
              </Button>
              <Button variant={'primary'} type={'submit'} width={'100%'} height={'48px'}>
                Continuar
              </Button>
            </HStack>
          </form>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateSchoolStep1
