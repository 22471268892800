import { type ElementType, type ReactNode, useEffect, useState } from 'react'
import LogoSVG from '../LogoSVG'
import LogoffMenu from './LogoffMenu'
import './Menu.css'
import { Box, Icon, Spacer, Stack, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { PiUsersThreeLight } from 'react-icons/pi'
import { RiComputerLine } from 'react-icons/ri'
import { TbPrompt, TbReportMoney } from 'react-icons/tb'
import { useNavigate } from 'react-router'
import { ALLOWED_USERS } from '../../apis/Constants'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import MenuItem from './MenuItem'
import { menuItemsList } from './MenuItemsList'

type MenuItemWrapperProps = {
  render?: ReactNode
  icon?: ElementType
  label?: string
  isMobile?: boolean
  isMinimized?: boolean
  navigateTo?: string
  allowedUsers: string[]
}

function MenuItemWrapper({ render: Item, icon, label, navigateTo, allowedUsers, isMobile, isMinimized }: MenuItemWrapperProps) {
  const { loggedUser } = useLoggedUserStore()
  const navigate = useNavigate()

  const isSelected = document.location.pathname === navigateTo

  if (!allowedUsers.includes(loggedUser.user_role)) return <></>
  if (Item) return Item
  return (
    <Stack
      spacing={2}
      hidden={isMobile && isMinimized}
      width="100%"
      borderRadius="none"
      py={2}
      _hover={{ bg: 'brand.300' }}
      backgroundColor={isSelected ? '#D7E3FB' : undefined}
      textAlign="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => navigateTo && navigate(navigateTo)}>
      <Icon as={icon} width={'30px'} height={'30px'} color="brand.200" />
      <Text fontSize="sm" color={isSelected ? 'brand.200' : 'blackAlpha.600'} fontWeight="bold">
        {label}
      </Text>
    </Stack>
  )
}

function Menu() {
  const [isMobile] = useMediaQuery('(max-width: 767px)')
  const [isMinimized, setIsMinimized] = useState(isMobile)

  useEffect(() => {
    setIsMinimized(isMobile)
  }, [isMobile])

  const toggleMinimized = () => {
    setIsMinimized(!isMinimized)
  }

  return (
    <Box
      className={'menu-container'}
      width={{ base: isMinimized ? '0px' : '106px', md: isMinimized ? '70px' : '106px' }}
      boxShadow={{ base: isMinimized ? 'none' : '2xl', md: 'none' }}
      gap={0}
      position={{ md: 'relative', base: 'absolute' }}
      backgroundColor="brand.600">
      <LogoSVG isMinimized={isMinimized} toggleMinimized={toggleMinimized} />

      <VStack width={'100%'} height={'100%'} gap={2} overflow="auto">
        <MenuItemWrapper
          icon={PiUsersThreeLight}
          isMobile={isMobile}
          isMinimized={isMinimized}
          label="Usuários"
          allowedUsers={ALLOWED_USERS.USERS}
          navigateTo={'/usuarios'}
        />
        <MenuItemWrapper
          icon={TbReportMoney}
          label="Planos"
          isMobile={isMobile}
          isMinimized={isMinimized}
          allowedUsers={ALLOWED_USERS.PLANS}
          navigateTo={'/planos'}
        />
        <MenuItemWrapper
          icon={TbPrompt}
          label="Prompts"
          isMobile={isMobile}
          isMinimized={isMinimized}
          allowedUsers={ALLOWED_USERS.PROMPTS}
          navigateTo={'/prompts'}
        />
        <MenuItemWrapper
          icon={RiComputerLine}
          isMobile={isMobile}
          isMinimized={isMinimized}
          label="Sistema"
          allowedUsers={ALLOWED_USERS.SYSTEM}
          navigateTo={'/monitoramento'}
        />
        {menuItemsList.map((menuItem, key) => (
          <MenuItemWrapper
            key={key}
            render={<MenuItem isMinimized={isMinimized} {...menuItem} />}
            allowedUsers={menuItem.allowedUsers}
          />
        ))}

        <Spacer hideBelow={'md'} />
        <LogoffMenu isMinimized={isMinimized} />
      </VStack>
    </Box>
  )
}

export default Menu
