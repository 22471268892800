import Headbar from '../../components/Headbar'

const CommonLayout = ({ children }) => {
  return (
    <div>
      <Headbar returnTo={'/detalhamento-aluno/123'} />
      {children}
    </div>
  )
}

export default CommonLayout
