import { usePutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency } from '@/services/school.ts'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface Competency {
  id: string
  name: string
  description: string
  institutionId: string
}

interface ModalDisclosure {
  isOpen: boolean
  onClose: () => void
}

interface GetModalEditCompetencyProps {
  editCompetency: Competency
  fetchAllInstitutions: () => Promise<void>
  modalEditCompetencyDisclosure: ModalDisclosure
  schoolId: string
}

interface FormData {
  name: string
  description: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required().min(1).max(2),
  description: yup.string().required(),
})

function GetModalEditCompetency({
  editCompetency,
  fetchAllInstitutions,
  modalEditCompetencyDisclosure,
  schoolId,
}: GetModalEditCompetencyProps) {
  const mutation = usePutApiV1SchoolSchoolIdInstitutionInstitutionIdCompetency()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: editCompetency.name,
      description: editCompetency.description,
    },
  })

  const handleSave = async (data: FormData) => {
    try {
      await mutation.mutateAsync({
        schoolId,
        institutionId: editCompetency.institutionId,
        data: {
          competencyId: editCompetency.id,
          name: data.name,
          description: data.description,
        },
      })
      await fetchAllInstitutions()
      toast.success('Competência alterada com sucesso!')
      modalEditCompetencyDisclosure.onClose()
    } catch (_error) {
      toast.error('Ocorreu um erro ao alterar a competência.')
    }
  }

  return (
    <Modal
      isOpen={modalEditCompetencyDisclosure.isOpen}
      size={'2xl'}
      onClose={modalEditCompetencyDisclosure.onClose}
      isCentered={true}
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'400'} className={'title-modal'}>
          Editar Competência
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <form onSubmit={handleSubmit(handleSave)}>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Nome da Competência</FormLabel>
                <Input className={'input-default-style'} {...register('name')} isDisabled={mutation.isPending} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.description}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Descrição da Competência</FormLabel>
                <Input className={'input-default-style'} {...register('description')} isDisabled={mutation.isPending} />
                <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={'32px'} width={'100%'}>
            <HStack width={'100%'} flexDir={{ base: 'column-reverse', md: 'row' }}>
              <Button
                variant={'outline'}
                height={'48px'}
                width={'100%'}
                isDisabled={mutation.isPending}
                onClick={modalEditCompetencyDisclosure.onClose}>
                Cancelar
              </Button>
              <Button variant={'primary'} height={'48px'} isLoading={mutation.isPending} width={'100%'} type="submit">
                Salvar
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditCompetency
