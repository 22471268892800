/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export type EssayCommentType = typeof EssayCommentType[keyof typeof EssayCommentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EssayCommentType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
