/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { UserRole } from './userRole';

export interface UserViewModel {
  /** @nullable */
  addressId?: string | null;
  blocked?: boolean;
  createdAt?: string;
  deleted?: boolean;
  /** @nullable */
  email?: string | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  /** @nullable */
  phoneNumber?: string | null;
  updatedAt?: string;
  userRole?: UserRole;
}
