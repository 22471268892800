import { Icon } from '@chakra-ui/react'

export const MicIcon = () => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 32 32" fill="none">
      <path
        d="M6.28613 14.5762C6.28613 20.0288 10.7052 24.4479 16.1579 24.4479M16.1579 24.4479C21.6106 24.4479 26.0296 20.0288 26.0296 14.5762M16.1579 24.4479L16.1584 28.1959"
        stroke="#062989"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3259 19.4455H15.9894C13.2948 19.4455 11.1094 17.2614 11.1094 14.5655V8.49816C11.1094 5.80356 13.2948 3.61816 15.9894 3.61816H16.3259C19.0205 3.61816 21.2059 5.80356 21.2059 8.49816V14.5655C21.2059 17.2614 19.0205 19.4455 16.3259 19.4455Z"
        stroke="#062989"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
