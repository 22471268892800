import { Text } from '@chakra-ui/react'

export function SelectedStep({ number }) {
  return (
    <Text
      alignItems={'center'}
      placeContent={'center'}
      minWidth={{ base: '40px', md: '52px' }}
      marginLeft={{ base: '7px', md: '0px' }}
      minHeight={{ base: '40px', md: '52px' }}
      className="frame-2482-cadastro publicsans-normal-aqua-deep-24px">
      {number}
    </Text>
  )
}
