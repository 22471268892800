import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  CircularProgress,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaCheck, FaTrash } from 'react-icons/fa'
import SharedTablePagination from '../../components/SharedTablePagination'
import { usePutApiV1OwnerRecoverUserUserId } from '../../services/owner'
import { useDeleteApiV1OwnerDeleteUserUserId } from '../../services/owner'
import { useGetApiV1StudentListFromAdmAdmId } from '../../services/student'
import { Modal } from '../modal'
import { SchoolAssistantComponent } from '../school-assistant'
import { SchoolPlanDetailsContainer } from '../school-plan-details-container'
import './DetalhamentoProfessorIndependente.css'
import { useDeleteApiV1OwnerRemoveUserUserId } from '../../services/owner'

export const IndependentTeacherDetailsModalBody = ({ independentTeacherId, onClose }) => {
  const {
    data: result,
    isLoading: studentsAreLoading,
    refetch: getStudents,
  } = useGetApiV1StudentListFromAdmAdmId(independentTeacherId)

  const students = result?.data?.message || []

  const { isOpen: deleteItIsOpen, onOpen: deleteItOnOpen, onClose: deleteItOnClose } = useDisclosure()

  const { mutate: deleteIndependentTeacher, isLoading: deleteIsLoading } = useDeleteApiV1OwnerRemoveUserUserId({
    mutation: {
      onSuccess: () => {
        onClose()
        deleteItOnClose()
      },
    },
  })

  async function handleDeleteIndependentTeacher() {
    deleteIndependentTeacher({ userId: independentTeacherId })
  }

  const { mutate: deactivateStudent } = useDeleteApiV1OwnerDeleteUserUserId({
    mutation: {
      onSuccess: () => {
        getStudents()
      },
    },
  })

  const handleDeactivateStudent = (studentId) => {
    deactivateStudent({ userId: studentId })
  }

  const { mutate: activateStudent } = usePutApiV1OwnerRecoverUserUserId({
    mutation: {
      onSuccess: () => getStudents(),
    },
  })

  async function handleActivateStudent(studentId) {
    activateStudent({ userId: studentId })
  }

  return (
    <Tabs paddingTop={4} isFitted variant="enclosed">
      <TabList className="custom-tab-list">
        <Tab>Alunos</Tab>
        <Tab>Assistente IA</Tab>
        <Tab>Plano</Tab>
        <Tab>Outros</Tab>
      </TabList>
      <TabPanels>
        <TabPanel className="custom-tab-panel">
          <Stack spacing={7} maxHeight="100%">
            <Stack>
              <Card size="sm">
                <CardBody className="custom-tab-panel">
                  <Stack textAlign="center" justifyContent="center">
                    {studentsAreLoading ? (
                      <Center>
                        <CircularProgress isIndeterminate size="20px" />
                      </Center>
                    ) : students.length ? (
                      <SharedTablePagination
                        data={students}
                        itemsPerPage={5}
                        className={'area-de-redacoes-table'}
                        columns={[
                          {
                            title: 'Nome',
                            render: (itemData) => <Box className={'pagination-style'}>{itemData.name}</Box>,
                          },
                          {
                            title: 'Número',
                            render: (itemData) => (
                              <Box className={'pagination-style'}>
                                {itemData.phoneNumber ? itemData.phoneNumber : 'Não possui número'}
                              </Box>
                            ),
                          },
                          {
                            title: 'Status',
                            render: (itemData) => (
                              <Box className={'pagination-style'}>
                                {itemData.active ? (
                                  <Box className={'essay-status in-grading-essay-status publicsans-normal-nevada-14px'}>
                                    Ativo
                                  </Box>
                                ) : (
                                  <Box className={'essay-status finished-essay-status publicsans-normal-nevada-14px'}>
                                    Inativo
                                  </Box>
                                )}
                              </Box>
                            ),
                          },
                          {
                            title: 'Opções',
                            render: (itemData) => (
                              <Box className={'pagination-style'}>
                                {itemData.active ? (
                                  <Button
                                    leftIcon={<Icon as={FaTrash} />}
                                    colorScheme="red"
                                    variant="solid"
                                    onClick={() => handleDeactivateStudent(itemData.id)}
                                    size="sm">
                                    Desativar aluno
                                  </Button>
                                ) : (
                                  <Button
                                    leftIcon={<Icon as={FaCheck} />}
                                    colorScheme="green"
                                    variant="solid"
                                    onClick={() => handleActivateStudent(itemData.id)}
                                    size="sm">
                                    Ativar aluno
                                  </Button>
                                )}
                              </Box>
                            ),
                          },
                        ]}
                      />
                    ) : (
                      <Text color="blackAlpha.700">Nenhum aluno ativo</Text>
                    )}
                  </Stack>
                </CardBody>
              </Card>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Box minHeight="260px">
            <SchoolAssistantComponent schoolId={independentTeacherId} />
          </Box>
        </TabPanel>
        <TabPanel>
          <SchoolPlanDetailsContainer />
        </TabPanel>
        <TabPanel>
          <Stack spacing={7} minHeight="260px">
            <Card size="sm" cursor="pointer" onClick={deleteItOnOpen}>
              <CardBody>
                <HStack alignItems="center">
                  <Icon as={FaTrash} w={10} h={10} color="red.500" />
                  <Box>
                    <Text fontSize="16px" fontWeight="700">
                      Excluir usuário
                    </Text>
                    <Text fontSize="14px" color="blackAlpha.700">
                      Remover usuário do sistema completamente.
                    </Text>
                  </Box>
                </HStack>

                <Modal
                  title={'Deletar Usuário'}
                  body={<p>Este usuário será removido do sistema. Essa ação é irreversível, deseja continuar?</p>}
                  isOpen={deleteItIsOpen}
                  onClose={deleteItOnClose}
                  isLoading={deleteIsLoading}
                  onSubmit={handleDeleteIndependentTeacher}
                  submitLabel={'Deletar'}
                />
              </CardBody>
            </Card>
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
