import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API, USER_ROLE_CODE } from '../Constants'

const { OWNER, ADM, INDEPENDENT_TEACHER, MONITOR, TEACHER, STUDENT } = USER_ROLE_CODE

export const listAllUsers = async (
  role = OWNER | ADM | INDEPENDENT_TEACHER | MONITOR | TEACHER | STUDENT,
  page = 1,
  take = 8,
) => {
  const skip = (page - 1) * take
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/owner/list-users/${role}/${skip}/${take}`)

  return response.data.message
}

export async function getAllPromptsApi() {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/assistant/list-assistant-prompts`)

  return response.data.message
}

export async function createNewAssistantTypeApi(name) {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/assistant/assistant-type`, { name })

  return response.data.message
}

export async function createNewPromptApi(data) {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/assistant/assistant-prompt`, data)

  return response.data.message
}

export async function updatePromptApi(data) {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/assistant/assistant-prompt`, data)

  return response.data.message
}
