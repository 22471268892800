import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'
import { Box, Textarea } from '@chakra-ui/react'

export const TextEditor = ({ onChange, essay }) => {
  return (
    <>
      <Box backgroundColor="rgb(243, 245, 255)" paddingTop="30px" borderRadius="12px 12px 0 0">
        <Textarea
          placeholder="Escreva sua redação aqui..."
          backgroundColor="white"
          borderRadius="0 0 12px 12px"
          borderColor="#E7E6E6"
          defaultValue={essay?.draft}
          height={essay ? '480px' : '280px'}
          onChange={(e) => onChange(e.target.value)}
        />
      </Box>
    </>
  )
}
