import { useEffect } from 'react'
import UserProfileHeadbarComponent from '../../UserProfileHeadbarComponent'
import Voltar from '../../Voltar'
import '../Headbar.css'
import { Box, HStack, Select, Spacer, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getDetailsOfLoggedTeacher } from '../../../apis/teacherApi/teacherApi'
import useLoggedUserStore from '../../../store/useLoggedUserStore'
import { StatusType } from '../../../utils/StatusType'
import NotificationsComponent from '../../NotificationsComponent'

function TeacherHeadbar(props) {
  const { className, returnTo, schools, hideHeaderElements } = props
  const { setLoggedUserData, loggedUser, setStatus } = useLoggedUserStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (!schools && loggedUser.schools.length === 0) {
      getDetailsOfLoggedTeacher().then((response) => {
        setLoggedUserData({ schools: response.schools })
        setStatus(StatusType.COMPLETED)
      })
    }
  }, [])

  return (
    <HStack className={`content-19 ${className || ''}`}>
      <Voltar returnTo={returnTo} hideHeaderElements={hideHeaderElements} />

      {hideHeaderElements ? (
        <VStack maxWidth={'300px'} width={'fit-content'} alignItems={'start'} marginLeft={{ md: '20px', base: '10px' }}>
          <Box className="publicsans-bold-nevada-10px"> Escola selecionada:</Box>
          <Select
            borderColor={'#d7e3fb'}
            value={loggedUser.schoolId}
            marginInlineEnd={'50px'}
            onChange={(e) => {
              setLoggedUserData({ schoolId: e.target.value })
              navigate('/inicio-professor')
            }}>
            {loggedUser.schools.map((school, key) => (
              <option value={school.id} key={key}>
                {school.name}
              </option>
            ))}
          </Select>
        </VStack>
      ) : (
        ''
      )}

      <Spacer />

      <NotificationsComponent />
      <UserProfileHeadbarComponent />
    </HStack>
  )
}

export default TeacherHeadbar
