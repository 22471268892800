export const indepententTeacherColumns = [
  {
    Header: 'Tipo',
    accessor: 'role',
    sample: 'Professor',
  },
  {
    Header: 'Nome',
    accessor: 'name',
    sample: 'Joao',
  },
  {
    Header: 'Email',
    accessor: 'email',
    sample: 'clienteanonimo@gmail.com',
  },
  {
    Header: 'Telefone',
    accessor: 'phoneNumber',
    sample: '98103980',
  },
  {
    Header: 'Bloqueado',
    accessor: 'blocked',
    sample: 'Sim',
  },
  {
    Header: 'Detalhes',
    accessor: 'details',
    sample: 'asd',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
