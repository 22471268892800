import UserProfileHeadbarComponent from '../UserProfileHeadbarComponent'
import Voltar from '../Voltar'
import './Headbar.css'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { RoleTypes } from '../../utils/RoleTypes'
import NotificationsComponent from '../NotificationsComponent'
import StudentHeadbar from './StudentHeadbar'
import TeacherHeadbar from './TeacherHeadbar'

function Headbar(props) {
  const { className, returnTo, schools, hideHeaderElements } = props
  const { loggedUser } = useLoggedUserStore()
  const isTeacher = loggedUser.user_role === RoleTypes.TEACHER || loggedUser.user_role === RoleTypes.MONITOR
  const isStudent = loggedUser.user_role === RoleTypes.STUDENT

  return (
    <>
      {isTeacher ? (
        <TeacherHeadbar schools={schools} hideHeaderElements={hideHeaderElements} />
      ) : isStudent ? (
        <StudentHeadbar schools={schools} hideHeaderElements={hideHeaderElements} />
      ) : (
        <div className={`content-19 ${className || ''}`}>
          {<Voltar returnTo={returnTo} hideHeaderElements={hideHeaderElements} />}

          <NotificationsComponent />
          <UserProfileHeadbarComponent />
        </div>
      )}
    </>
  )
}

export default Headbar
