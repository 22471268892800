import { usePutApiV1Activity } from '@/services/activity.ts'
import { formatDate } from '@/utils/formatDateBeforeInput.ts'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { pt } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DatePickerInput } from '../../components/Datepicker'

interface ActivityFormValues {
  subject: string
  description?: string
  validFrom: Date
  deadline: Date
}

const validationSchema = yup.object().shape({
  subject: yup.string().min(5).max(255).required(),
  validFrom: yup.date().required(),
  deadline: yup.date().min(yup.ref('validFrom')).required(),
  description: yup.string().max(500),
})

interface GetModalEditActivityProps {
  modalDisclosure: {
    isOpen: boolean
    onClose: () => void
  }
  activityData: {
    id: string
    subject: string
    description: string
    teacher: { id: string }
    validFrom: string
    deadline: string
  }
  updateActivityData: () => void
}

function GetModalEditActivity({ modalDisclosure, activityData, updateActivityData }: GetModalEditActivityProps) {
  const isDeadlineDisabled = new Date() > new Date(activityData.deadline)
  const isValidFromDisabled = new Date() > new Date(activityData.validFrom)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ActivityFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subject: activityData.subject,
      description: activityData.description,
      validFrom: new Date(activityData.validFrom),
      deadline: new Date(activityData.deadline),
    },
  })

  const putActivityMutation = usePutApiV1Activity()

  const onSubmit = async (formData: ActivityFormValues) => {
    try {
      const body = {
        data: {
          ...formData,
          id: activityData.id,
          teacherId: activityData.teacher.id,
          validFrom: formatDate(new Date(formData.validFrom)),
          deadline: formatDate(new Date(formData.deadline)),
        },
      }

      await putActivityMutation.mutateAsync(body)
      await updateActivityData()

      toast.success('Atividade alterada com sucesso!')
      modalDisclosure.onClose()
    } catch (error) {
      console.error('Erro ao atualizar a atividade', error)
      toast.error('Erro ao atualizar a atividade')
    }
  }

  function calcMinDeadline() {
    const currentDate = new Date()
    const validFromPlusOne = new Date(activityData.validFrom)
    validFromPlusOne.setDate(validFromPlusOne.getDate() + 1)

    return currentDate > validFromPlusOne ? currentDate : validFromPlusOne
  }

  return (
    <Modal isOpen={modalDisclosure.isOpen} size="2xl" onClose={modalDisclosure.onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontWeight="400" className="title-modal-detalhamento-atividade">
          Editar Atividade
        </ModalHeader>
        <ModalCloseButton color="#0a41d6" marginTop="5px" />
        <ModalBody>
          <VStack alignItems="flex-start" gap="16px">
            <FormControl isInvalid={!!errors.subject}>
              <FormLabel>Nome da Atividade</FormLabel>
              <Input {...register('subject')} className="input-default-style" />
              <FormErrorMessage>{errors.subject?.message}</FormErrorMessage>
            </FormControl>

            <HStack width="100%" flexDir={{ md: 'row', base: 'column' }}>
              <VStack width="100%" alignItems="flex-start">
                <FormControl isInvalid={!!errors.validFrom}>
                  <FormLabel>Inicio da Atividade</FormLabel>

                  <DatePicker
                    selected={new Date(watch('validFrom'))}
                    onChange={(date) => date && setValue('validFrom', date)}
                    minDate={new Date()}
                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <DatePickerInput
                        tooltipLabel={
                          isValidFromDisabled ? 'Essa atividade já iniciou e a data não pode ser mais alterada' : null
                        }
                      />
                    }
                    locale={pt}
                    disabled={isValidFromDisabled}
                  />

                  <FormErrorMessage>{errors.validFrom?.message}</FormErrorMessage>
                </FormControl>
              </VStack>

              <VStack width="100%" alignItems="flex-start">
                <FormControl isInvalid={!!errors.deadline}>
                  <FormLabel>Entrega da Atividade</FormLabel>
                  <DatePicker
                    selected={new Date(watch('deadline'))}
                    onChange={(date) => date && setValue('deadline', date)}
                    minDate={calcMinDeadline()}
                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <DatePickerInput
                        tooltipLabel={
                          isDeadlineDisabled ? 'Essa atividade já encerrou e a data não pode ser mais alterada' : null
                        }
                      />
                    }
                    locale={pt}
                    disabled={isDeadlineDisabled}
                  />
                  <FormErrorMessage>{errors.deadline?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </HStack>

            <FormControl isInvalid={!!errors.description}>
              <FormLabel>Descrição</FormLabel>
              <Box position="relative" w="100%" h="100%" backgroundColor="white">
                <Textarea
                  {...register('description')}
                  resize="vertical"
                  w="100%"
                  h="100%"
                  minHeight="150px"
                  borderColor="#d7e3fb"
                />
                <Text fontSize="sm" zIndex={999} position="absolute" bottom="5px" right="20px" color="#a9a8a8">
                  {activityData.description ? activityData.description.length : 0}/500
                </Text>
              </Box>
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter alignSelf="center" gap={{ md: '32px', base: '8px' }} flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Button
            backgroundColor="#f9faff"
            borderColor="#648BF7"
            border="1px"
            borderRadius="8px"
            color="#062989"
            height="48px"
            isDisabled={putActivityMutation.isPending}
            width="218px"
            onClick={modalDisclosure.onClose}>
            Cancelar
          </Button>
          <Button
            backgroundColor="#0A41D6"
            border="1px"
            borderRadius="8px"
            color="#FFFFFF"
            height="48px"
            width="218px"
            isLoading={putActivityMutation.isPending}
            type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditActivity
