/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface NewCompetencyViewModel {
  /**
   * @maxLength 200
   * @nullable
   */
  description?: string | null;
  /**
   * @minLength 1
   * @maxLength 2
   */
  name: string;
}
