import { Box, Image } from '@chakra-ui/react'
import { useRef, useState } from 'react'

const ZoomableImage = (props) => {
  const { essayImageUrl, zoom } = props

  const [isMoving, setIsMoving] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [translateX, setTranslateX] = useState(0)
  const [translateY, setTranslateY] = useState(0)

  const imageRef = useRef() // Reference to the image
  const boxRef = useRef() // Reference to the box

  const handleMouseDown = (event) => {
    setIsMoving(true)
    setStartX(event.clientX - translateX)
    setStartY(event.clientY - translateY)
  }

  const handleMouseMove = (event) => {
    if (isMoving) {
      event.preventDefault()

      let newTranslateX = event.clientX - startX
      let newTranslateY = event.clientY - startY

      const boxDimensions = boxRef.current.getBoundingClientRect()
      const imageDimensions = imageRef.current.getBoundingClientRect()

      // Now this compares the zoomed dimensions with the box dimensions
      if (newTranslateX > 0) {
        newTranslateX = 0
      }
      if (newTranslateY > 0) {
        newTranslateY = 0
      }

      if (zoom > 1 && startX + Math.abs(newTranslateX) > imageDimensions.width) {
        return
      }
      if (newTranslateX < boxDimensions.width - imageDimensions.width) {
        newTranslateX = boxDimensions.width - imageDimensions.width
      }

      if (zoom > 1 && startY + Math.abs(newTranslateY) > imageDimensions.height) {
        return
      }
      if (newTranslateY < boxDimensions.height - imageDimensions.height) {
        newTranslateY = boxDimensions.height - imageDimensions.height
      }

      setTranslateX(newTranslateX)
      setTranslateY(newTranslateY)
    }
  }

  const handleMouseUp = () => {
    setIsMoving(false)
  }

  return (
    <Box
      ref={boxRef}
      marginX="auto"
      overflow="hidden"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      cursor={isMoving ? 'grabbing' : 'grab'}>
      <Image
        ref={imageRef}
        src={essayImageUrl}
        alt="essay"
        draggable="false" // prevent default drag behavior
        transform={`scale(${zoom}) translate(${translateX}px, ${translateY}px)`}
        transition="transform ease-in-out"
        transformOrigin="top left"
      />
    </Box>
  )
}

export default ZoomableImage
