import './Menu.css'
import type { MenuItemType } from '@/components/Menu/MenuItemsList.ts'
import FontsUtils from '@/theme/foundations/FontsUtils'
import { Button, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

function MenuItem(props: MenuItemType & { isMinimized: boolean }) {
  const { urlToBeSelected, selectedPicturePath, menuPicturePath, menuTitle, isMinimized, urlToRedirect } = props
  const location = useLocation()

  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 767px)')
  const isMenuSelected = urlToBeSelected.some((url) => location.pathname.includes(url))

  return (
    <Button
      hidden={isMobile && isMinimized}
      variant="ghost"
      borderRadius="none"
      maxHeight={'75px'}
      minHeight={'60px'}
      height={'100%'}
      py={2}
      width="100%"
      _hover={{ bg: 'brand.300' }}
      onClick={() => navigate(urlToRedirect())}>
      <VStack width={'100%'} height={'100%'}>
        <Image className="menu-item-picture" src={isMenuSelected ? selectedPicturePath : menuPicturePath} alt="home-icon" />
        <Text
          fontSize="sm"
          color={isMenuSelected ? 'brand.200' : FontsUtils.fontGray.color}
          fontFamily={FontsUtils.fontPrimary.fontFamily}
          hidden={isMinimized}>
          {menuTitle}
        </Text>
      </VStack>
    </Button>
  )
}

export default MenuItem
