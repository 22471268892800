import CardTitle from '../CardTitle'
import './GroupWithoutActivityCard.css'
import { Button, Image, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function GroupWithoutActivityCard(props) {
  const { groupName, groupId } = props
  const navigate = useNavigate()
  return (
    <VStack borderRadius="lg" gap={0} align="start" alignContent="center" overflow="hidden" boxShadow="xl">
      <CardTitle groupName={groupName} groupId={groupId} />
      <VStack bgColor="white" w={'100%'} height={'100%'}>
        <VStack w={'100%'} h={'100%'} pt={14}>
          <Image src={'/img/ilustracao-emptystate.svg'} />
          <Text w={'85%'} wordBreak={'break-word'} textAlign={'center'} className="publicsans-normal-nevada-16px">
            Esta turma não possui redações em andamento
          </Text>
        </VStack>
        <Button
          variant={'outline'}
          width={{ md: '80%', base: '90%' }}
          border={'1px solid'}
          borderColor={'brand.400'}
          borderRadius={'8px'}
          p={'24px'}
          marginBottom={'24px'}
          height={'45px'}
          gap={2}
          onClick={() => {
            navigate('/criar-atividade')
          }}>
          <img className="ic-add-2" src={'/img/ic-add-11@2x.png'} alt="ic-add" />
          <Text className="inter-bold-absolute-zero-14px">Criar atividades</Text>
        </Button>
      </VStack>
    </VStack>
  )
}

export default GroupWithoutActivityCard
