import './Voltar.css'
import { useBackNavigation } from '@/utils/useBackNavigation'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'

function Voltar(props) {
  const { returnTo, hideHeaderElements } = props

  const handleBackNavigation = useBackNavigation({ returnTo })

  if (hideHeaderElements) return null

  return (
    <Box className="voltar" hidden={hideHeaderElements}>
      <Button leftIcon={<ArrowBackIcon className="ic-arrowleft" />} variant="ghost" onClick={handleBackNavigation} size="sm">
        Voltar
      </Button>
    </Box>
  )
}

export default Voltar
