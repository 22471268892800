/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AdmViewModel,
  BooleanResultViewModel,
  ChangePasswordViewModel,
  ConfirmEmailViewModel,
  ForgotPasswordViewModel,
  GetApiV1AccountValidateTokenParams,
  LoginUserViewModel,
  NewAdmViewModel,
  PostApiV1AccountSetLanguageParams,
  ProblemDetails,
  ResendEmailConfirmationViewModel,
  UpdateAdmViewModel
} from './types'




/**
 * @summary Creates an ADM user
 */
export const postApiV1AccountAdm = (
    newAdmViewModel: NewAdmViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/adm`,
      newAdmViewModel,options
    );
  }



export const getPostApiV1AccountAdmMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountAdm>>, TError,{data: NewAdmViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountAdm>>, TError,{data: NewAdmViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountAdm>>, {data: NewAdmViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountAdm(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountAdmMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountAdm>>>
    export type PostApiV1AccountAdmMutationBody = NewAdmViewModel
    export type PostApiV1AccountAdmMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates an ADM user
 */
export const usePostApiV1AccountAdm = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountAdm>>, TError,{data: NewAdmViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountAdm>>,
        TError,
        {data: NewAdmViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountAdmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic info of ADM user
 */
export const putApiV1AccountAdm = (
    updateAdmViewModel: UpdateAdmViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/account/adm`,
      updateAdmViewModel,options
    );
  }



export const getPutApiV1AccountAdmMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AccountAdm>>, TError,{data: UpdateAdmViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AccountAdm>>, TError,{data: UpdateAdmViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AccountAdm>>, {data: UpdateAdmViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1AccountAdm(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AccountAdmMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AccountAdm>>>
    export type PutApiV1AccountAdmMutationBody = UpdateAdmViewModel
    export type PutApiV1AccountAdmMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic info of ADM user
 */
export const usePutApiV1AccountAdm = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AccountAdm>>, TError,{data: UpdateAdmViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1AccountAdm>>,
        TError,
        {data: UpdateAdmViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1AccountAdmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Retrieves basic info of ADM user
 */
export const getApiV1AccountAdm = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AdmViewModel>> => {
    
    return axios.get(
      `/api/v1/account/adm`,options
    );
  }


export const getGetApiV1AccountAdmQueryKey = () => {
    return [`/api/v1/account/adm`] as const;
    }

    
export const getGetApiV1AccountAdmQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AccountAdmQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AccountAdm>>> = ({ signal }) => getApiV1AccountAdm({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AccountAdmQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AccountAdm>>>
export type GetApiV1AccountAdmQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AccountAdm<TData = Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AccountAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AccountAdm<TData = Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AccountAdm>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AccountAdm<TData = Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves basic info of ADM user
 */

export function useGetApiV1AccountAdm<TData = Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountAdm>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AccountAdmQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Executes the login in the system already informing in which language the user prefers
 */
export const postApiV1AccountLogin = (
    loginUserViewModel: LoginUserViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/login`,
      loginUserViewModel,options
    );
  }



export const getPostApiV1AccountLoginMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountLogin>>, TError,{data: LoginUserViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountLogin>>, TError,{data: LoginUserViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountLogin>>, {data: LoginUserViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountLogin(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountLogin>>>
    export type PostApiV1AccountLoginMutationBody = LoginUserViewModel
    export type PostApiV1AccountLoginMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Executes the login in the system already informing in which language the user prefers
 */
export const usePostApiV1AccountLogin = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountLogin>>, TError,{data: LoginUserViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountLogin>>,
        TError,
        {data: LoginUserViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Confirms an account
 */
export const postApiV1AccountConfirmEmail = (
    confirmEmailViewModel: ConfirmEmailViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/confirm-email`,
      confirmEmailViewModel,options
    );
  }



export const getPostApiV1AccountConfirmEmailMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>, TError,{data: ConfirmEmailViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>, TError,{data: ConfirmEmailViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>, {data: ConfirmEmailViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountConfirmEmail(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountConfirmEmailMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>>
    export type PostApiV1AccountConfirmEmailMutationBody = ConfirmEmailViewModel
    export type PostApiV1AccountConfirmEmailMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Confirms an account
 */
export const usePostApiV1AccountConfirmEmail = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>, TError,{data: ConfirmEmailViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountConfirmEmail>>,
        TError,
        {data: ConfirmEmailViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountConfirmEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Resends the account confirmation to user
 */
export const postApiV1AccountResendConfirmation = (
    resendEmailConfirmationViewModel: ResendEmailConfirmationViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/resend-confirmation`,
      resendEmailConfirmationViewModel,options
    );
  }



export const getPostApiV1AccountResendConfirmationMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>, TError,{data: ResendEmailConfirmationViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>, TError,{data: ResendEmailConfirmationViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>, {data: ResendEmailConfirmationViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountResendConfirmation(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountResendConfirmationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>>
    export type PostApiV1AccountResendConfirmationMutationBody = ResendEmailConfirmationViewModel
    export type PostApiV1AccountResendConfirmationMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Resends the account confirmation to user
 */
export const usePostApiV1AccountResendConfirmation = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>, TError,{data: ResendEmailConfirmationViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountResendConfirmation>>,
        TError,
        {data: ResendEmailConfirmationViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountResendConfirmationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Changes the password and send it via email
 */
export const postApiV1AccountForgotPassword = (
    forgotPasswordViewModel: ForgotPasswordViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/forgot-password`,
      forgotPasswordViewModel,options
    );
  }



export const getPostApiV1AccountForgotPasswordMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>, TError,{data: ForgotPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>, TError,{data: ForgotPasswordViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>, {data: ForgotPasswordViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountForgotPassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>>
    export type PostApiV1AccountForgotPasswordMutationBody = ForgotPasswordViewModel
    export type PostApiV1AccountForgotPasswordMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Changes the password and send it via email
 */
export const usePostApiV1AccountForgotPassword = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>, TError,{data: ForgotPasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountForgotPassword>>,
        TError,
        {data: ForgotPasswordViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Changes the password with a new password
 */
export const postApiV1AccountChangePassword = (
    changePasswordViewModel: ChangePasswordViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/Change-password`,
      changePasswordViewModel,options
    );
  }



export const getPostApiV1AccountChangePasswordMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountChangePassword>>, TError,{data: ChangePasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountChangePassword>>, TError,{data: ChangePasswordViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountChangePassword>>, {data: ChangePasswordViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AccountChangePassword(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountChangePassword>>>
    export type PostApiV1AccountChangePasswordMutationBody = ChangePasswordViewModel
    export type PostApiV1AccountChangePasswordMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Changes the password with a new password
 */
export const usePostApiV1AccountChangePassword = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountChangePassword>>, TError,{data: ChangePasswordViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountChangePassword>>,
        TError,
        {data: ChangePasswordViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Sets the language where the responses of API will be send. Default is pt-br
 */
export const postApiV1AccountSetLanguage = (
    params?: PostApiV1AccountSetLanguageParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/account/set-language`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostApiV1AccountSetLanguageMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>, TError,{params?: PostApiV1AccountSetLanguageParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>, TError,{params?: PostApiV1AccountSetLanguageParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>, {params?: PostApiV1AccountSetLanguageParams}> = (props) => {
          const {params} = props ?? {};

          return  postApiV1AccountSetLanguage(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AccountSetLanguageMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>>
    
    export type PostApiV1AccountSetLanguageMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Sets the language where the responses of API will be send. Default is pt-br
 */
export const usePostApiV1AccountSetLanguage = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>, TError,{params?: PostApiV1AccountSetLanguageParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AccountSetLanguage>>,
        TError,
        {params?: PostApiV1AccountSetLanguageParams},
        TContext
      > => {

      const mutationOptions = getPostApiV1AccountSetLanguageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Validates if a token is still valid
 */
export const getApiV1AccountValidateToken = (
    params?: GetApiV1AccountValidateTokenParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.get(
      `/api/v1/account/validate-token`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AccountValidateTokenQueryKey = (params?: GetApiV1AccountValidateTokenParams,) => {
    return [`/api/v1/account/validate-token`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1AccountValidateTokenQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1AccountValidateTokenParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AccountValidateTokenQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>> = ({ signal }) => getApiV1AccountValidateToken(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AccountValidateTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>>
export type GetApiV1AccountValidateTokenQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AccountValidateToken<TData = Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1AccountValidateTokenParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AccountValidateToken>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AccountValidateToken<TData = Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AccountValidateTokenParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AccountValidateToken>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AccountValidateToken<TData = Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AccountValidateTokenParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Validates if a token is still valid
 */

export function useGetApiV1AccountValidateToken<TData = Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AccountValidateTokenParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AccountValidateToken>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AccountValidateTokenQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



