import { useDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId } from '@/services/school.ts'
import { Button, HStack, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import type { AxiosError } from 'axios'
import { toast } from 'react-toastify'

interface GetModalDeleteCompetencyProps {
  modalDeleteCompetencyDisclosure: {
    isOpen: boolean
    onClose: () => void
  }
  editCompetency: {
    id: string
    name: string
    institutionId: string
  }
  fetchAllInstitutions: () => Promise<void>
  schoolId: string
}

function GetModalDeleteCompetency({
  modalDeleteCompetencyDisclosure,
  editCompetency,
  fetchAllInstitutions,
  schoolId,
}: GetModalDeleteCompetencyProps) {
  const { mutate, isPending } = useDeleteApiV1SchoolSchoolIdInstitutionInstitutionIdCompetencyCompetencyId<AxiosError>({
    mutation: {
      onSuccess: async () => {
        await fetchAllInstitutions()
        toast.success(`Competência '${editCompetency.name}' removida com sucesso!`)
        modalDeleteCompetencyDisclosure.onClose()
      },
    },
  })

  const handleDelete = async () => {
    try {
      mutate({
        schoolId,
        institutionId: editCompetency.institutionId,
        competencyId: editCompetency.id,
      })
    } catch (error) {
      console.error('Erro ao remover competência', error)
    }
  }

  return (
    <Modal
      isOpen={modalDeleteCompetencyDisclosure.isOpen}
      size="2xl"
      onClose={modalDeleteCompetencyDisclosure.onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="400" className="title-modal">
          {`Confirme remoção da competência '${editCompetency.name}'`}
        </ModalHeader>
        <ModalCloseButton color="#0a41d6" marginTop="5px" onClick={modalDeleteCompetencyDisclosure.onClose} />
        <ModalFooter alignSelf="center" gap="32px" width="100%">
          <HStack width="100%" flexDir={{ base: 'column-reverse', md: 'row' }}>
            <Button
              variant="outline"
              height="48px"
              width="100%"
              isDisabled={isPending}
              onClick={modalDeleteCompetencyDisclosure.onClose}>
              Cancelar
            </Button>
            <Button colorScheme="red" height="48px" width="100%" isLoading={isPending} onClick={handleDelete}>
              Remover Competência
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalDeleteCompetency
