import './ThemeArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1ThemeSchoolSchoolId } from '@/services/theme'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import SharedCardPagination from '../../components/SharedCardPagination'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import sanitizedDate from '../../utils/DateSatinizerInput'
import Page from '../Page'
import ThemeCard from './ThemeCard'

function ThemeArea() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10
  const { loggedUser } = useLoggedUserStore()

  const [filterText, setFilterText] = useState('')
  const getApiV1ThemeSchool = useGetApiV1ThemeSchoolSchoolId(loggedUser.schoolId)
  const themesData = getApiV1ThemeSchool.data?.data?.message || []
  const themes = themesData.filter((item) => {
    return item.subject?.toLowerCase().includes(filterText.toLowerCase()) || sanitizedDate(item.createdAt).includes(filterText)
  })

  useIsHistoryEmpty()

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  return (
    <Page authPage={true} allowedUserType={'teacher monitor independentteacher'}>
      <LayoutMenu isLoading={getApiV1ThemeSchool?.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Temas"
            button={{
              isVisible: loggedUser.user_role !== 'student',
              navigateTo: '/criar-tema',
              label: 'Criar tema',
            }}
          />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
          {themes.length > 0 ? (
            <SharedCardPagination data={themes} RenderComponent={ThemeCard} itemsPerPage={itemsPerPage} />
          ) : (
            <Flex
              width={'100%'}
              alignSelf={'center'}
              backgroundColor={'#f3f5ff'}
              height={'64px'}
              marginRight={'32px'}
              borderRadius={'16px'}>
              <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'} alignSelf={'center'}>
                Nenhum tema foi criado ainda.
              </Text>
            </Flex>
          )}
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default ThemeArea
