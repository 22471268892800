import './LogoSVG.css'
import { Box, Image } from '@chakra-ui/react'

function LogoSVG({ isMinimized, toggleMinimized }) {
  return (
    <Box width={'100%'} minHeight={'90px'} className="perfil">
      <Box>
        <Image className="logo-image" src="/img/logo-compacted.svg" alt="logo-image" />
        <Box
          position={'absolute'}
          backgroundColor={'#f9faff'}
          left={{ base: isMinimized ? '-5px' : '95px', md: isMinimized ? '58px' : '85px' }}
          transition={'left 0.5s ease'}
          borderRadius="lg"
          width={'40px'}
          height={'40px'}
          alignContent={'center'}
          cursor={'pointer'}
          hideFrom={'md'}
          onClick={toggleMinimized}
          top={'25px'}>
          <Image width={'100%'} src={isMinimized ? '/img/arrowRightPagination.svg' : '/img/arrowLeftPagination.svg'} />
        </Box>
      </Box>
    </Box>
  )
}

export default LogoSVG
