import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

function formatDate(date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

  return `${year}${month}${day}`
}

export async function getLogsApi(startDate, endDate) {
  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/log/list/${formattedStartDate}/${formattedEndDate}`)

  return response.data.message
}

export async function clearLogsApi(ids) {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/log/clear-by-ids`, {
    ids,
  })

  return response.data.message
}
