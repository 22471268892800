import { AreaHeader } from '@/components/AreaHeader'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { getAllPlansApi } from '../../apis/planApi'
import { PlanModalBody } from '../../components/PlanModalBody'
import { Modal } from '../../components/modal'
import Page from '../Page'
import { PlansSearch } from './search'

function MonetizationPlans(_props) {
  const [plans, setPlans] = useState([])
  const [page] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)

  const { isOpen: detailsIsOpen, onClose: detailsOnClose, onOpen: detailsOnOpen } = useDisclosure()

  function handleDetailsOnClose() {
    detailsOnClose()
    setSelectedPlan(null)
  }

  function handleDetailsOnOpen(prompt) {
    setSelectedPlan(prompt)
    detailsOnOpen()
  }

  async function fetchAllPlans() {
    try {
      setIsLoading(true)
      const result = await getAllPlansApi()
      setPlans(result)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!detailsIsOpen) {
      fetchAllPlans()
    }
  }, [detailsIsOpen])

  return (
    <Page authPage={true} allowedUserType={'owner'}>
      <LayoutMenu isLoading={isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Planos"
            button={{
              isVisible: true,
              onClick: detailsOnOpen,
              label: 'Adicionar plano',
            }}
          />
          <PlansSearch hits={plans} isLoading={isLoading} detailsAction={handleDetailsOnOpen} page={page} />
        </LayoutMenu.Content>
      </LayoutMenu>
      <Modal
        title={selectedPlan ? selectedPlan.name : 'Novo Plano'}
        isOpen={detailsIsOpen}
        onClose={handleDetailsOnClose}
        body={<PlanModalBody plan={selectedPlan || null} onClose={handleDetailsOnClose} />}
        size="xl"
      />
    </Page>
  )
}

export default MonetizationPlans
