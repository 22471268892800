import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import 'react-datepicker/dist/react-datepicker.css'
import { usePostApiV1Activity } from '@/services/activity'
import { RoleTypes } from '@/utils/RoleTypes'
import { yupResolver } from '@hookform/resolvers/yup'
import { pt } from 'date-fns/locale'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DatePickerInput } from '../../components/Datepicker'
import { useGetApiV1Teacher, usePatchApiV1TeacherListFromGroups } from '../../services/teacher'
import type { ListGroups, NewActivityViewModel } from '../../services/types'
import useCreateActivityStore from '../../store/useCreateActivityStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { formatDate } from '../../utils/formatDateBeforeInput'

type Option = {
  value: string
  label: string
  id: string
}

enum SENDING_TYPE {
  TEXT_OR_IMAGE = 2,
  TEXT_ONLY = 0,
}

const validationSchema = yup.object({
  groups: yup.array().required('Selecione ao menos uma turma.'),
  teacherId: yup.string().required('Selecione um professor.'),
  validFrom: yup.date().required('Informe a data de início da atividade.'),
  deadline: yup.date().required('Informe a data de entrega da atividade.'),
  activityType: yup.number().required('Selecione o modo de envio.'),
})

type Form = yup.InferType<typeof validationSchema>

function CreateActivityStep2(props: {
  handleReturn: () => void
  handlingNextStep: () => void
}) {
  const { setActivityData, activityData, clearActivityData } = useCreateActivityStore()
  const { loggedUser } = useLoggedUserStore()

  const { handleReturn, handlingNextStep } = props

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const [turmaOptions, setTurmaOptions] = useState<Option[]>([])
  const [teacherOptions, setTeacherOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState(true)

  const currentDate = new Date()
  const deadlineDefaultValue = new Date()
  deadlineDefaultValue.setDate(currentDate.getDate() + 1)
  const endOfYear = new Date(new Date().getFullYear(), 11, 31)

  const { data: response, isPending: isPendingGetTeacher } = useGetApiV1Teacher()
  const schools = response?.data?.message?.schools

  useEffect(() => {
    if (!schools) return
    const groups = schools.filter((school) => school.id === loggedUser.schoolId).flatMap((school) => school.groups)

    const mappedGroups = groups?.map((turma) => ({
      value: turma?.name || '',
      label: turma?.name || '',
      id: turma?.id || '',
    }))

    setTurmaOptions(mappedGroups || [])
    setLoading(false)
  }, [schools, loggedUser.schoolId])

  useEffect(() => {
    if (loggedUser.user_role === RoleTypes.TEACHER) {
      setValue('teacherId', loggedUser.user_id)
    }
  }, [])

  const { mutate: patch, isPending: isPendingTeacherList } = usePatchApiV1TeacherListFromGroups({
    mutation: {
      onSuccess: (response) => {
        const teachersToCurrentGroups = response.data?.message
        if (!teachersToCurrentGroups) return
        setTeacherOptions(
          teachersToCurrentGroups.map((teacher) => ({
            value: teacher.id ?? '',
            label: teacher.name ?? '',
            id: teacher.id ?? '',
          })),
        )
      },
    },
  })

  const postApiV1Activity = usePostApiV1Activity({
    mutation: {
      onSuccess: (response) => {
        const activityId = response?.data?.message
        if (!activityId) return toast.error('Erro ao carregar o ID da atividade')
        clearActivityData(activityId)
        handlingNextStep()
      },
    },
  })

  const handleGroupChange = (selectedGroups: Option[]) => {
    if (!selectedGroups) return
    const requestBody: ListGroups = {
      groups: selectedGroups.map((group) => group.id),
    }
    patch({ data: requestBody })
  }

  const submit = (body: Form) => {
    const result = {
      ...activityData,
      deadline: formatDate(body.deadline),
      validFrom: formatDate(body.validFrom),
      groups: body.groups.map((group) => group.id),
      activityType: body.activityType,
      teacherId: body.teacherId,
    } as NewActivityViewModel

    setActivityData(result)
    postApiV1Activity.mutate({ data: result })
  }

  const teacherFieldPlaceholder = () => {
    if (loading || isPendingTeacherList) return 'Aguarde um momento...'
    if (!watch('groups') || watch('groups').length === 0) return 'Selecione uma turma primeiro'
    return 'Selecione um professor'
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-atividades'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading alignSelf={'start'} className={'publicsans-extra-extra-bold-absolute-zero-32px'}>
          Criar atividade
        </Heading>
        <VStack w={'100%'} mt={'32px'}>
          <Box display="flex" alignItems="center" gap={7} w="100%">
            <Image maxW="50px" src="/img/frame-2482@2x.png" />
            <Box>
              <Text color="gray.500" fontSize="sm">
                Sobre a atividade
              </Text>
              <Text color="gray.600" fontSize="lg">
                {activityData?.subject}
              </Text>
            </Box>
          </Box>
          <Box w={'100%'} className="cards-step-2-container">
            <StepsImage text={'Configurações da atividade'} number={2} />
            <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(submit)} noValidate>
              <Grid width={'100%'} gap={'32px'} mt={'32px'}>
                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl paddingRight={{ base: '32px', md: '0px' }} isInvalid={errors.groups && !!errors.groups.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Turmas*</FormLabel>
                    <Controller
                      name="groups"
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          placeholder={loading ? 'Aguarde um momento...' : 'Selecione ao menos uma turma'}
                          className="input-style"
                          disabled={loading}
                          options={turmaOptions}
                          w="100%"
                          value={field.value}
                          onChange={(values) => {
                            field.onChange(values)
                            handleGroupChange(values as Option[])
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.groups?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }} hidden={loggedUser.user_role === 'teacher'}>
                  <FormControl
                    paddingRight={{ base: '32px', md: '0px' }}
                    isInvalid={errors.teacherId && !!errors.teacherId.message}>
                    <FormLabel className={'publicsans-bold-nevada-16px'}>Professor Responsável*</FormLabel>
                    <Select
                      placeholder={teacherFieldPlaceholder()}
                      className={'input-style'}
                      id={'teacherId'}
                      isDisabled={loading || isPendingTeacherList || !watch('groups')}
                      {...register('teacherId')}
                      w={'100%'}>
                      {teacherOptions.map((option, key) => (
                        <option className={'select-tema-text'} key={key} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.teacherId?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 2, lg: 1 }}>
                  <FormControl isInvalid={errors.validFrom && !!errors.validFrom.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Início da atividade</FormLabel>
                    <Controller
                      name="validFrom"
                      control={control}
                      defaultValue={currentDate}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          minDate={currentDate}
                          maxDate={endOfYear}
                          dateFormat="dd/MM/yyyy"
                          customInput={<DatePickerInput />}
                          locale={pt}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.validFrom?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 2, lg: 1 }}>
                  <FormControl isInvalid={errors.deadline && !!errors.deadline.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Entrega da atividade</FormLabel>
                    <Controller
                      name="deadline"
                      control={control}
                      defaultValue={deadlineDefaultValue}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          minDate={watch('validFrom')}
                          maxDate={endOfYear}
                          dateFormat="dd/MM/yyyy"
                          customInput={<DatePickerInput />}
                          locale={pt}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.deadline?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl isInvalid={!!errors?.activityType}>
                    <FormLabel className="publicsans-bold-nevada-16px">Modo de envio*</FormLabel>
                    <Controller
                      name="activityType"
                      control={control}
                      render={({ field: { value, ...radioProps } }) => (
                        <RadioGroup defaultValue={String(SENDING_TYPE.TEXT_OR_IMAGE)} value={String(value)} {...radioProps}>
                          <HStack width={'100%'} gap={{ base: '12px', md: '32px' }} display="flex" alignItems="stretch">
                            <Box
                              backgroundColor={Number(value) === SENDING_TYPE.TEXT_OR_IMAGE ? '#E4E9FF' : '#F3F5FF'}
                              borderColor="brand.400"
                              borderWidth="1px"
                              borderStyle="solid"
                              borderRadius="md"
                              display="flex"
                              flexDirection="column"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              maxW="180px"
                              p={5}
                              onClick={() => radioProps.onChange(SENDING_TYPE.TEXT_OR_IMAGE)}>
                              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                <Image
                                  width="50px"
                                  src={
                                    value === SENDING_TYPE.TEXT_OR_IMAGE
                                      ? '/img/imagem-selecionado.svg'
                                      : '/img/camera-2851779@2x.png'
                                  }
                                  alt="selecione a opção texto ou imagem"
                                />
                                <Text color="gray.500">Envio por imagem ou texto</Text>
                              </Box>
                              <Radio
                                value={String(SENDING_TYPE.TEXT_OR_IMAGE)}
                                size="lg"
                                color="brand.200"
                                border="2px"
                                mt={5}
                                _checked={{
                                  borderWidth: '6px',
                                }}
                                defaultChecked
                              />
                            </Box>
                            <Box
                              backgroundColor={Number(value) === SENDING_TYPE.TEXT_ONLY ? '#E4E9FF' : '#F3F5FF'}
                              borderColor="brand.400"
                              borderWidth="1px"
                              borderStyle="solid"
                              borderRadius="md"
                              display="flex"
                              flexDirection="column"
                              cursor="pointer"
                              justifyContent="center"
                              alignItems="center"
                              maxW="180px"
                              p={5}
                              onClick={() => radioProps.onChange(SENDING_TYPE.TEXT_ONLY)}>
                              <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                <Image
                                  width="50px"
                                  src={
                                    Number(value) === SENDING_TYPE.TEXT_ONLY
                                      ? '/img/document-2851788@2x.png'
                                      : '/img/texto-nao-selecionado.svg'
                                  }
                                  alt="selecione a opção de cadastrar somente texto"
                                />
                                <Text color="gray.500">Envio apenas por texto</Text>
                              </Box>
                              <Radio
                                className="radio-button-step-2"
                                value={String(SENDING_TYPE.TEXT_ONLY)}
                                variant="primary"
                                mt={5}
                                size="lg"
                                color="brand.200"
                                _checked={{
                                  borderWidth: '6px',
                                }}
                                border="2px"
                              />
                            </Box>
                          </HStack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>{errors.activityType?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>

              <Box display="flex" w={'100%'} gap={3} my={6}>
                <Button flex={1} variant="outline" onClick={handleReturn}>
                  Voltar
                </Button>
                <Button
                  flex={1}
                  type="submit"
                  variant="primary"
                  isLoading={isPendingGetTeacher || isPendingTeacherList || postApiV1Activity.isPending}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Box>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateActivityStep2
