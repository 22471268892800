import Headbar from '@/components/Headbar'
import Menu from '@/components/Menu'
import { Center, HStack, Spinner, VStack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

type LayoutMenuProps = {
  isLoading: boolean
  children: ReactNode
}

function LayoutMenu(props: LayoutMenuProps) {
  const { isLoading, children } = props
  return (
    <HStack gap={0} width="100%" height="100%">
      <Menu />
      <VStack width="100%" alignSelf="stretch" gap={0} overflow="auto">
        <Headbar hideHeaderElements={true} />
        <HStack gap={0} width={'100%'} flex={1} align="start">
          {isLoading ? (
            <Center width="100%" height="100%">
              <Spinner size="xl" color="brand.200" />
            </Center>
          ) : (
            children
          )}
        </HStack>
      </VStack>
    </HStack>
  )
}

type LayoutMenuContentProps = {
  children: ReactNode
}

LayoutMenu.Content = (props: LayoutMenuContentProps) => {
  const { children } = props
  return (
    <VStack gap={6} p={{ base: 3, md: 6 }} width="100%">
      {children}
    </VStack>
  )
}

export default LayoutMenu
