export const colors = {
  brand: {
    100: '#062989',
    200: '#0A41D6',
    250: '#0130B0',
    300: '#D7E3FB',
    400: '#e5e3ff',
    500: '#f9faff',
    600: '#F3F5FF',
  },
  brandGreen: {
    500: '#21FDBB',
  },
  brandGray: {
    100: '#a9a8a8',
  },
}
