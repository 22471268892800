import './ActivityDetails.css'
import { VStack } from '@chakra-ui/react'
import Headbar from '../../components/Headbar'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'
import ActivityDetailsStudent from './ActivityDetailsStudent'
import ActivityDetailsTeacher from './ActivityDetailsTeacher'

function ActivityDetails() {
  const { loggedUser } = useLoggedUserStore()

  function isStudent() {
    return loggedUser.user_role === 'student'
  }

  return (
    <div className="details-container screen">
      <VStack width={'100%'} height={'100%'} alignSelf={'start'} gap={0}>
        <Headbar />
        <Page authPage={true} allowedUserType={''} />
        {isStudent() ? <ActivityDetailsStudent /> : <ActivityDetailsTeacher />}
      </VStack>
    </div>
  )
}

export default ActivityDetails
