const FontsUtils = {
  publicSansBoldAbsoluteZero: {
    fontFamily: 'Public Sans',
    fontWeight: '700',
    color: '#0a41d6',
  },
  publicSansBoldWhite: {
    fontFamily: 'Public Sans',
    fontWeight: '700',
    color: 'white',
  },
  fontPrimary: {
    color: '#0a41d6',
    fontFamily: 'Public Sans',
    fontWeight: 700,
    lineHeight: '130%',
  },
  fontGray: {
    color: '#6b6b79',
    fontFamily: 'Public Sans',
    fontWeight: 400,
    lineHeight: '130%',
  },
}

export default FontsUtils
