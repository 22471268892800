import { Center, CircularProgress, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { getUserPlanApi } from '../../apis/planApi'

export const SchoolPlanDetailsContainer = () => {
  const [plan, setPlan] = useState()
  const [isLoading, setIsLoading] = useState(true)

  async function fetchUserPlan() {
    try {
      const userId = localStorage.getItem('user_id')
      const plan = await getUserPlanApi(userId)
      setPlan(plan)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserPlan()
  }, [])

  return (
    <Stack spacing={7} minHeight="260px">
      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate size="20px" />
        </Center>
      ) : plan ? (
        <p>plano aqui</p>
      ) : (
        <Center>
          <Text color="blackAlpha.700">Nenhum plano ativo</Text>
        </Center>
      )}
    </Stack>
  )
}
