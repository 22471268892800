/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';

export interface ActivityForStudentViewModel {
  createdAt?: string;
  deadline?: string;
  /** @nullable */
  description?: string | null;
  essayStatus?: number;
  id?: string;
  /** @nullable */
  subject?: string | null;
  theme?: KeyValueViewModel;
  validFrom?: string;
}
