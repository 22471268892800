import { tabs } from '@/pages/InitialPageStudent/Tabs.ts'
import FontsUtils from '@/theme/foundations/FontsUtils'
import { Box, Button, HStack } from '@chakra-ui/react'

export type SelectedTab = 'todas' | 'disponiveis' | 'rascunhos' | 'submetidas' | 'revisadas' | 'concluidas'

type InitialPageStudentTabsProps = {
  selectedTab: SelectedTab
  setSelectedTab: (selectedTab: SelectedTab) => void
}

export const InitialPageStudentTabs = (props: InitialPageStudentTabsProps) => {
  const { selectedTab, setSelectedTab } = props

  return (
    <HStack maxW="5xl" gap={0} height="100%" width="100%" overflowX={'auto'}>
      {tabs.map((tab) => (
        <Box
          key={tab.id}
          width="100%"
          py={2}
          textAlign="center"
          borderBottom={selectedTab === tab.id ? '2px' : '1px'}
          borderColor={selectedTab === tab.id ? '#02e39f' : 'gray.200'}
          onClick={() => setSelectedTab(tab.id as SelectedTab)}>
          <Button
            variant="ghost"
            width="100%"
            size="sm"
            color={FontsUtils.fontGray.color}
            fontFamily={FontsUtils.fontPrimary.fontFamily}
            fontWeight={selectedTab === tab.id ? 'bold' : 'normal'}>
            {tab.label}
          </Button>
        </Box>
      ))}
    </HStack>
  )
}
