import { Box, HStack, Image, Text } from '@chakra-ui/react'
import { addMonths, eachDayOfInterval, endOfMonth, format, getDay, isToday, setDay, startOfMonth, subMonths } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useState } from 'react'

function Calendar({ atividades }) {
  const [currentDate, setCurrentDate] = useState(new Date())

  const currentMonthDays = eachDayOfInterval({
    start: startOfMonth(currentDate),
    end: endOfMonth(currentDate),
  })

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1))
  }

  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1))
  }

  const firstDayOfWeek = getDay(startOfMonth(currentDate))
  const paddingDays = Array.from({ length: firstDayOfWeek }, (_, i) => i)

  const weekdays = Array.from({ length: 7 }, (_, i) => format(setDay(new Date(), i), 'eeee', { locale: ptBR }))

  const sortedAtividades = [...atividades].sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

  return (
    <Box width={'100%'}>
      <Box
        borderTopRadius={'12px'}
        backgroundColor={'#e4e9ff'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={'16px 24px 8px'}>
        <Text className={'publicsans-bold-absolute-zero-16px'}>{format(currentDate, 'MMMM yyyy', { locale: ptBR })}</Text>
        <HStack>
          <Image src={'/img/arrowLeftPagination.svg'} onClick={prevMonth} cursor={'pointer'} />
          <Image src={'/img/arrowRightPagination.svg'} onClick={nextMonth} cursor={'pointer'} />
        </HStack>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(7, 1fr)"
        justifyItems={'center'}
        paddingLeft={'12px'}
        paddingRight={'12px'}
        backgroundColor={'white'}
        borderBottomRadius={'12px'}>
        {weekdays.map((weekday, index) => (
          <Box p={1} paddingTop={'12px'} key={index}>
            <Text className={'poppins-normal-nevada-12px'}>{weekday.slice(0, 3)}</Text>
          </Box>
        ))}
        {paddingDays.map((_, index) => (
          <Box key={`padding-${index}`} p={4} />
        ))}
        {currentMonthDays.map((day, index) => {
          // Check if the day matches any deadline
          const isReminderDay = atividades.some((item) => {
            const deadlineDate = new Date(item.deadline)
            return day.toDateString() === deadlineDate.toDateString()
          })
          return (
            <Box
              p={4}
              className={`${isReminderDay ? 'reminder-day' : isToday(day) ? 'current-day' : ''} inter-normal-congress-blue-16px`}
              key={index}>
              {format(day, 'd')}
            </Box>
          )
        })}
      </Box>
      {sortedAtividades.map((item, index) => {
        const deadlineDate = new Date(item.deadline)
        const subject = item.subject
        if (format(currentDate, 'yyyy-MM') === format(deadlineDate, 'yyyy-MM')) {
          return (
            <div className="lembrete-calendrio" key={index}>
              <div className="legenda">
                <div className="ellipse-5" />
                <div className="text-2 valign-text-middle">{format(deadlineDate, 'dd/MM')}</div>
              </div>
              <div className="legenda-1">
                <div className="frame-2473">
                  <div className="frame-2474">
                    <img className="calendar-2851774" src={'/img/calendar-2851774-16@2x.png'} alt="calendar-2851774" />
                    <div className="data-final-para-alunos valign-text-middle">{'DATA FINAL PARA ALUNOS'}</div>
                  </div>
                  <Text className="entregas-atividade-iii-unidade-2 valign-text-middle">{subject}</Text>
                </div>
              </div>
            </div>
          )
        }
      })}
    </Box>
  )
}

export default Calendar
