import './CardTitle.css'
import { Box, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import useNavigationStore from '../../store/useNavigationStore'

function CardTitle(props) {
  const { groupName, groupId, linkReturnTo } = props
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  return (
    <Box p={6}>
      <Text
        className="publicsans-normal-absolute-zero-20px"
        cursor={'pointer'}
        onClick={() => {
          setReturnTo(linkReturnTo)
          navigate(`/detalhamento-turma/${groupId}`, { state: {} })
        }}>
        {groupName}
      </Text>
    </Box>
  )
}

export default CardTitle
