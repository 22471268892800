import { Box, Center, Heading, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import ReactDropzone from 'react-dropzone'
import { AddImageIcon } from '../icons/AddImage'

export const UploadArea = ({ onDrop, essayImageUrl }) => {
  const [errorMessage, setErrorMessage] = useState('')

  const validateImageDimensions = (file, callback) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    const minWidth = 1000
    const maxWidth = 2000
    const minHeight = 1400
    const maxHeight = 2800

    img.onload = () => {
      const width = img.width
      const height = img.height

      if (width >= minWidth && width <= maxWidth && height >= minHeight && height <= maxHeight) {
        setErrorMessage('')
        callback(file)
      } else {
        setErrorMessage('A imagem deve ter no mínimo 1000x1400 pixels e no máximo 2000x2800 pixels.')
      }
    }
  }

  return (
    <>
      <ReactDropzone
        accept={{
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        }}
        onDropAccepted={(acceptedFiles) => {
          const file = acceptedFiles[0]
          validateImageDimensions(file, onDrop)
        }}
        multiple={false}>
        {({ getRootProps, getInputProps }) =>
          !essayImageUrl && (
            <Center
              {...getRootProps()}
              cursor="pointer"
              flexDirection="column"
              backgroundColor="#F3F5FF"
              borderRadius="12px"
              border="2px solid #E4E9FF"
              padding="24px 16px 24px 16px"
              gap="24px">
              <Stack>
                <Stack textAlign="center">
                  <Box backgroundColor="#E4E9FF" borderRadius="68px" p="16px" gap="10px" alignItems="flex-start">
                    <AddImageIcon />
                  </Box>
                  <Heading fontSize="14px" fontWeight="400">
                    <span style={{ fontWeight: 700 }}>Clique</span> para escolher
                    <br /> uma imagem
                  </Heading>
                </Stack>
              </Stack>
              <input {...getInputProps()} />
            </Center>
          )
        }
      </ReactDropzone>
      {errorMessage && (
        <Text color="red" mt={2}>
          {errorMessage}
        </Text>
      )}
    </>
  )
}
