export const essayStatus = {
  0: { label: 'Disponível' },
  10: { label: 'Rascunho' },
  20: { label: 'Submetida' },
  30: { label: 'Revisada' },
  40: { label: 'Concluída' },
}

export const ESSAY_STATUS_CONST = {
  NOT_STARTED: 0,
  IN_PROGRESS: 10,
  SUBMITTED: 20,
  REVIEWED: 30,
  FINISHED: 40,
}

export const returnEssayStatusClassName = (status) => {
  let backgroundColor
  if (status === 0) {
    backgroundColor = 'essay-status in-available-essay-status'
  } else if (status === 10) {
    backgroundColor = 'essay-status in-progress-essay-status'
  } else if (status === 20) {
    backgroundColor = 'essay-status in-grading-essay-status'
  } else if (status === 30) {
    backgroundColor = 'essay-status in-review-essay-status'
  } else if (status === 40) {
    backgroundColor = 'essay-status finished-essay-status'
  }
  return backgroundColor
}
