import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { getThemeApi, putEditTheme, putEditThemeImage } from '../../apis/themeApi/themeApi'

function GetModalEditTema({ loading, setLoading, modalDisclosure, temasData, setTemasData }) {
  const [imageUploadError, setImageUploadError] = useState('')

  async function fetchTheme() {
    await Promise.all([getThemeApi(temasData.id)]).then(([themeResult]) => {
      setTemasData(themeResult)
    })
  }

  const maxCharacteres = 500
  const maxFileSizeMB = 2 * 1024 * 1024
  const acceptedFileTypes = {
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
  }
  const acceptString = Object.values(acceptedFileTypes.accept).flat().join(', ')

  const validationSchema = yup.object({
    image: yup.string(),
    subject: yup.string().min(6).max(255).required(),
    description: yup.string().max(500),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subject: temasData.subject,
      description: temasData.description,
    },
  })

  const [editTemaData, setEditTemaData] = useState({
    id: temasData.id,
    subject: temasData.subject,
    description: temasData.description,
    base64Image: temasData.base64Image,
  })

  const [previewImage, setPreviewImage] = useState(editTemaData.base64Image)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageUpload = (e) => {
    const file = e.target.files[0]

    if (file && file.size > maxFileSizeMB) {
      setImageUploadError('O tamanho da imagem deve ser menor que 2MB')
      return
    }

    setImageUploadError('')
    setSelectedImage(file)

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]
        setPreviewImage(reader.result)
        setEditTemaData((prevData) => ({
          ...prevData,
          base64Image: base64String,
        }))
        setValue('image', reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      setPreviewImage('')
      setValue('image', '')
    }
  }

  const onSaveClick = async (data) => {
    const requestBodyEditTheme = {
      id: editTemaData.id,
      subject: data.subject,
      description: data.description,
    }
    try {
      setLoading(true)
      await putEditTheme(requestBodyEditTheme).then(async () => {
        if (selectedImage) {
          const requestBody = { base64Image: editTemaData.base64Image }

          await putEditThemeImage(temasData.id, requestBody)
        }
      })
      await fetchTheme()
    } finally {
      setLoading(false)
      modalDisclosure.onClose()
    }
  }

  return (
    <Modal
      isOpen={modalDisclosure.isOpen}
      size={'2xl'}
      onClose={modalDisclosure.onClose}
      isCentered={true}
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSaveClick)} noValidate>
          <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
            Editar Tema
          </ModalHeader>
          <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <FormControl isInvalid={!!errors.image}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Imagem do tema</FormLabel>
                {previewImage && <img src={previewImage} alt="Imagem do tema" width={200} height={200} />}
                <Input type="file" accept={acceptString} onChange={(e) => handleImageUpload(e)} />
                {imageUploadError && <FormErrorMessage>{imageUploadError}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={errors.subject && !!errors.subject.message}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Título*</FormLabel>
                <Input
                  className={'input-default-style'}
                  {...register('subject')}
                  defaultValue={editTemaData.subject}
                  onChange={(e) => setEditTemaData({ ...editTemaData, subject: e.target.value })}
                />
                <FormErrorMessage>{errors.subject?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.description && !!errors.description.message}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Descrição</FormLabel>
                <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
                  <Textarea
                    placeholder=""
                    defaultValue={editTemaData.description}
                    {...register('description')}
                    onChange={(e) => setEditTemaData({ ...editTemaData, description: e.target.value })}
                    resize="vertical"
                    w={'100%'}
                    h={'100%'}
                    minHeight={'150px'}
                    borderColor={'#d7e3fb'}
                  />
                  <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="#a9a8a8" fontFamily={'Public Sans'}>
                    {editTemaData.description ? editTemaData.description.length : 0}/{maxCharacteres}
                  </Text>
                  <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                </Box>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter alignSelf={'center'} gap={{ md: '32px', base: '8px' }} flexDir={{ md: 'row', base: 'column-reverse' }}>
            <Button
              backgroundColor={'#f9faff'}
              borderColor={'#648BF7'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#062989'}
              height={'48px'}
              width={'218px'}
              isDisabled={loading}
              onClick={modalDisclosure.onClose}>
              Cancelar
            </Button>
            <Button
              backgroundColor={'#0A41D6'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#FFFFFF'}
              height={'48px'}
              width={'218px'}
              isLoading={loading}
              type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditTema
