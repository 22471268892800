import { useState } from 'react'
import './InitialPageStudent.css'
import { AreaHeader } from '@/components/AreaHeader'
import CalendarComponent from '@/components/CalendarComponent'
import SearchComponent from '@/components/SearchComponent'
import SharedCardPagination from '@/components/SharedCardPagination'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays } from '@/services/activity'
import useLoggedUserStore from '@/store/useLoggedUserStore'
import sanitizedDate from '@/utils/DateSatinizerInput'
import { ESSAY_STATUS_CONST } from '@/utils/essayStatus'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Flex, Text } from '@chakra-ui/react'
import Page from '../Page'
import InitialPageStudentCard from './InitialPageStudentCard.tsx'
import { InitialPageStudentSidebar } from './InitialPageStudentSidebar.tsx'
import { InitialPageStudentTabs, type SelectedTab } from './InitialPageStudentTabs.tsx'

const tabStatusMap = {
  disponiveis: ESSAY_STATUS_CONST.NOT_STARTED,
  rascunhos: ESSAY_STATUS_CONST.IN_PROGRESS,
  submetidas: ESSAY_STATUS_CONST.SUBMITTED,
  revisadas: ESSAY_STATUS_CONST.REVIEWED,
  concluidas: ESSAY_STATUS_CONST.FINISHED,
}

function InitialPageStudent() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const { loggedUser } = useLoggedUserStore()
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('todas')
  const [filterText, setFilterText] = useState('')
  const getApiV1ActivitySchool = useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays(
    loggedUser.user_id,
    loggedUser.schoolId,
    30,
  )

  const activitiesData = getApiV1ActivitySchool?.data?.data?.message || []

  useIsHistoryEmpty()

  const activities = activitiesData
    .filter((item) => {
      const result =
        (item?.theme?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          sanitizedDate(item.createdAt).includes(filterText) ||
          item?.subject?.toLowerCase().includes(filterText.toLowerCase()) ||
          sanitizedDate(item.deadline).includes(filterText)) &&
        filterBySelectedTab(item)

      return result
    })
    .sort((a, b) => {
      const essayStatusA = a.essayStatus ?? ''
      const essayStatusB = b.essayStatus ?? ''

      if (essayStatusA < essayStatusB) return -1
      if (essayStatusA > essayStatusB) return 1

      const dateA = new Date(a.deadline || '')
      const dateB = new Date(b.deadline || '')

      return dateA.getTime() - dateB.getTime()
    })

  function filterBySelectedTab(item) {
    if (selectedTab === 'todas') {
      return true
    }

    const expectedStatus = tabStatusMap[selectedTab]
    return expectedStatus !== undefined ? item.essayStatus === expectedStatus : true
  }

  function isInProgress(item) {
    const currentDate = new Date()

    return new Date(item.validFrom) <= currentDate && new Date(item.deadline) >= currentDate
  }

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  return (
    <Page authPage={true} allowedUserType={''}>
      <LayoutMenu isLoading={getApiV1ActivitySchool?.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Atividades"
            rightSidebar={<CalendarComponent activities={activities} />}
            button={{
              isVisible: loggedUser.user_role !== 'student',
              navigateTo: '/criar-atividade',
              label: 'Criar atividade',
            }}
          />
          <InitialPageStudentTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

          {activities?.length > 0 ? (
            <SharedCardPagination data={activities} RenderComponent={InitialPageStudentCard} itemsPerPage={itemsPerPage} />
          ) : (
            <Flex
              width={'100%'}
              alignSelf={'center'}
              backgroundColor={'#f3f5ff'}
              height={'64px'}
              marginRight={'32px'}
              borderRadius={'16px'}>
              <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'} alignSelf={'center'}>
                Nenhuma atividade foi encontrada.
              </Text>
            </Flex>
          )}
        </LayoutMenu.Content>
        <InitialPageStudentSidebar activities={activities} isInProgress={isInProgress} />
      </LayoutMenu>
    </Page>
  )
}

export default InitialPageStudent
