import axios from 'axios'
import { toast } from 'react-toastify'
import { BACKEND_API } from './Constants'

/*
// Interceptor para forçar erro 500 nas requisições de estudante, utilizado na área de turma e aluno (para testes)
axios.interceptors.request.use((config) => {
  if (config.url.includes('/v1/student')) {
    return Promise.reject({ response: { status: 500 } })
  }
  return config
}, (error) => {
  return Promise.reject(error)
})
*/
axios.defaults.baseURL = BACKEND_API?.replace('/api', '')

axios.interceptors.request.use(async (request) => {
  const accessToken = localStorage.getItem('user_token')
  if (accessToken) request.headers.Authorization = `bearer ${accessToken}`
  return request
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.name === 'CanceledError') return // In dev mode, React's strict mode double calls components, causing react-query to cancel requests.

    const errorMessageArray = error?.response?.data?.message
    if (errorMessageArray?.length) {
      toast.error(errorMessageArray[0])
    } else {
      toast.error('Um erro inesperado ocorreu. Tente novamente mais tarde.')
    }

    redirectIfNotAuthorizedOrServerError(error)

    return Promise.reject(error)
  },
)

const get = async (url) => {
  const response = await axios({
    method: 'get',
    url,
  })
  return response
}

const getWithAuth = async (url) => {
  const response = await axios({
    method: 'get',
    url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  })
  return response
}

const patchWithAuth = async (url, body) => {
  const response = await axios({
    method: 'patch',
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  })
  return response
}

const post = async (url, body) => {
  const response = await axios({
    method: 'post',
    url,
    data: body,
  })
  return response
}

const postWithAuth = async (url, body) => {
  const response = await axios({
    method: 'post',
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  })
  return response
}

const putWithAuth = async (url, body) => {
  const response = await axios({
    method: 'put',
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  })
  return response
}

const delWithAuth = async (url) => {
  const response = await axios({
    method: 'delete',
    url,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    },
  })
  return response
}

function redirectIfNotAuthorizedOrServerError(error) {
  if (error.response?.status === 401) {
    console.warn('unauthorized')
    window.location.href = '/login'
  } else if (error.response?.status >= 500 && error.response?.status < 600) {
    console.warn('internal server error')
    window.location.href = '/internal-error'
  }
}

export const httpRequestHelper = {
  get,
  getWithAuth,
  post,
  postWithAuth,
  putWithAuth,
  delWithAuth,
  patchWithAuth,
}
