import { usePutApiV1SchoolSchoolIdInstitution } from '@/services/school.ts'
import { institutionGradeTypeLabels } from '@/utils/institutionGradeTypes.ts'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface InstitutionFormValues {
  name: string
  institutionGradeType: number
  maxGrade: number
}

const validationSchema = yup.object().shape({
  name: yup.string().required().min(2).max(60),
  institutionGradeType: yup.number().required(),
  maxGrade: yup.number().typeError('Obrigatório').min(1).max(100000).required(),
})

interface GetModalEditInstitutionProps {
  itemData: {
    id: string
    name: string
    institutionGradeType: number
    maxGrade: number
  }
  loading: boolean
  setLoading: (loading: boolean) => void
  fetchAllInstitutions: () => Promise<void>
  modalEditDisclosure: {
    isOpen: boolean
    onClose: () => void
  }
  schoolId: string
}

function GetModalEditInstitution({
  itemData,
  fetchAllInstitutions,
  modalEditDisclosure,
  schoolId,
}: GetModalEditInstitutionProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InstitutionFormValues>({
    defaultValues: {
      name: itemData.name,
      institutionGradeType: itemData.institutionGradeType,
      maxGrade: itemData.maxGrade,
    },
    resolver: yupResolver(validationSchema),
  })

  const putInstitutionMutation = usePutApiV1SchoolSchoolIdInstitution()

  const onSubmit = async (formData: InstitutionFormValues) => {
    try {
      await putInstitutionMutation.mutateAsync({
        schoolId,
        data: {
          ...formData,
          institutionId: itemData.id,
        },
      })
      fetchAllInstitutions()
      toast.success('Instituição alterada com sucesso!')
      modalEditDisclosure.onClose()
    } catch (error) {
      console.error('Erro ao atualizar instituição', error)
      toast.error('Erro ao atualizar instituição')
    }
  }

  return (
    <Modal
      isOpen={modalEditDisclosure.isOpen}
      size="2xl"
      onClose={modalEditDisclosure.onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontWeight="400" className="title-modal">
          Editar Instituição
        </ModalHeader>
        <ModalCloseButton color="#0a41d6" marginTop="5px" />
        <ModalBody>
          <VStack alignItems="flex-start" gap="16px">
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Nome da Instituição</FormLabel>
              <Input {...register('name')} className="input-default-style" />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.institutionGradeType}>
              <FormLabel>Tipo de Avaliação</FormLabel>
              <Select {...register('institutionGradeType')} borderColor="#d7e3fb" backgroundColor="white">
                {Object.entries(institutionGradeTypeLabels).map(([value, label], key) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.institutionGradeType?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.maxGrade}>
              <FormLabel>Nota Máxima</FormLabel>
              <Input {...register('maxGrade')} className="input-default-style" />
              <FormErrorMessage>{errors.maxGrade?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter alignSelf="center" gap="32px" width="100%">
          <HStack width="100%" flexDir={{ base: 'column-reverse', md: 'row' }}>
            <Button
              variant="outline"
              height="48px"
              width="100%"
              isDisabled={putInstitutionMutation.isPending}
              onClick={modalEditDisclosure.onClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              height="48px"
              isLoading={putInstitutionMutation.isPending}
              width="100%"
              onClick={handleSubmit(onSubmit)}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditInstitution
