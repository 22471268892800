import { Box, Button, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import notFoundImage from '../../assets/404-not-found.svg'
import handleRedirectPath from '../../utils/handleRedirectPath'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      padding="20px"
      boxSizing="border-box">
      <Image src={notFoundImage} alt="Página não encontrada" maxWidth="600px" objectFit="cover" mb={4} />
      <Text fontSize="32px" fontWeight="400" color="brand.200" mb={4}>
        Oops! Página não encontrada.
      </Text>
      <Button
        variant={'primary'}
        minWidth={'250px'}
        size={'lg'}
        onClick={() => {
          navigate(handleRedirectPath())
        }}>
        Página inicial
      </Button>
    </Box>
  )
}

export default NotFound
