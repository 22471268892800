import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { createNewPlanApi, updatePlanApi } from '../../apis/planApi'

export const PlanModalBody = ({ plan, onClose }) => {
  const [name, setName] = useState(plan?.name)
  const [price, setPrice] = useState(plan?.price)
  const [maxActiveStudents, setMaxActiveStudents] = useState(plan?.maxActiveStudents)
  const [isLoading, setIsLoading] = useState(false)

  const enableCreationButton = useMemo(() => !!name && !!price && !!maxActiveStudents, [name, price, maxActiveStudents])

  async function createNewPlan() {
    try {
      setIsLoading(true)
      const data = {
        name,
        price: typeof price === 'string' ? Number(price.replace(/,/g, '.')) : Number(price),
        maxActiveStudents,
      }

      await createNewPlanApi(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  async function updatePlan() {
    try {
      setIsLoading(true)
      const data = {
        id: plan.id,
        name,
        price: typeof price === 'string' ? Number(price.replace(/,/g, '.')) : Number(price),
        maxActiveStudents,
      }

      await updatePlanApi(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <Box>
      <Stack spacing={4}>
        <Grid templateColumns="repeat(12, 1fr)" gap={5}>
          <GridItem colSpan={{ base: 5, lg: 8 }}>
            <FormControl>
              <FormLabel>Titulo</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 5, lg: 4 }}>
            <FormControl>
              <FormLabel>Valor Mensal</FormLabel>
              <CurrencyInput
                placeholder="Preço"
                defaultValue={0}
                decimalsLimit={2}
                allowDecimals
                decimalSeparator=","
                groupSeparator="."
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                value={price}
                onValueChange={(stringValue) => setPrice(stringValue)}
                style={{
                  borderRadius: '5px',
                  border: '1px solid gray',
                  padding: '7px 10px',
                  maxWidth: '150px',
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 12 }}>
            <FormControl>
              <FormLabel>Limite de estudantes ativos</FormLabel>
              <NumberInput
                size="md"
                width="120px"
                borderColor="gray"
                defaultValue={maxActiveStudents}
                min={1}
                max={10000}
                onChange={(stringValue) => setMaxActiveStudents(Number(stringValue))}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      {plan ? (
        <Button float="right" variant="primary" onClick={updatePlan}>
          {isLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}Salvar alterações
        </Button>
      ) : (
        <Button float="right" variant="primary" isDisabled={!enableCreationButton} onClick={createNewPlan}>
          {isLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}Criar plano
        </Button>
      )}
    </Box>
  )
}
