import { HStack, Image, Text, VStack } from '@chakra-ui/react'

export default function StepFormFilled({ title, content }) {
  return (
    <HStack alignItems={'center'} justifyContent={'start'} width={'100%'}>
      <Image w={'42px'} h={'42px'} src="/img/frame-2482@2x.png" alt="Frame 2482" />
      <VStack width={'100%'} alignItems={'start'} gap={0}>
        <Text className="publicsans-normal-gray-16px">{title}</Text>
        <Text className="publicsans-normal-nevada-20px">{content}</Text>
      </VStack>
    </HStack>
  )
}
