import { Link } from 'react-router-dom'
import './HeaderCadastro.css'
import { Box, Button, useMediaQuery } from '@chakra-ui/react'
import FontsUtils from '../../theme/foundations/FontsUtils'

function HeaderCadastro() {
  const [isMobile] = useMediaQuery('(max-width: 375px)')

  function LogoPositivaHorizontal() {
    return (
      <Box marginLeft={isMobile ? '16px' : '32px'}>
        <img src={'/img/logo-positiva-horizontal.svg'} alt="Group" />
      </Box>
    )
  }

  function SignUpButton() {
    return (
      <Link to={'/cadastro'}>
        <Button
          className={'publicsans-bold-absolute-zero-14px'}
          variant={'outline'}
          marginRight={isMobile ? '16px' : '32px'}
          padding={'24px'}
          sx={FontsUtils.publicSansBoldAbsoluteZero}>
          {'Cadastre-se'}
        </Button>
      </Link>
    )
  }

  return (
    <div className="header-cadastro">
      <LogoPositivaHorizontal />
      <SignUpButton />
    </div>
  )
}

export default HeaderCadastro
