export const logColumns = [
  {
    Header: 'Data',
    accessor: 'date',
    sample: '2023-10-12T16:40:20 sadasd',
  },
  {
    Header: 'Tipo',
    accessor: 'level',
    sample: 'Error',
  },
  {
    Header: 'Logger',
    accessor: 'logger',
    sample: 'HealthChecks.UI.Core.HostedService.HealthCheckReportCollector',
  },
  {
    Header: 'Mensagem',
    accessor: 'message',
    sample: 'GetHealthReport threw an exception when trying to get report from.',
  },
  // {
  //   Header: 'Detalhes',
  //   accessor: 'details',
  //   sample: 'asd'
  // },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
