/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  ActivityForStudentViewModelListResultViewModel,
  ActivityWithStudentViewModelResultViewModel,
  ActivityWithTotalsViewModelListResultViewModel,
  BooleanResultViewModel,
  GuidResultViewModel,
  NewActivityViewModel,
  ProblemDetails,
  UpdateActivityViewModel
} from './types'




/**
 * @summary Adds a new Activity to be available to students
 */
export const postApiV1Activity = (
    newActivityViewModel: NewActivityViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GuidResultViewModel>> => {
    
    return axios.post(
      `/api/v1/activity`,
      newActivityViewModel,options
    );
  }



export const getPostApiV1ActivityMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Activity>>, TError,{data: NewActivityViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1Activity>>, TError,{data: NewActivityViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Activity>>, {data: NewActivityViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Activity(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1ActivityMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Activity>>>
    export type PostApiV1ActivityMutationBody = NewActivityViewModel
    export type PostApiV1ActivityMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new Activity to be available to students
 */
export const usePostApiV1Activity = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Activity>>, TError,{data: NewActivityViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1Activity>>,
        TError,
        {data: NewActivityViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1ActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic activity information
 */
export const putApiV1Activity = (
    updateActivityViewModel: UpdateActivityViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BooleanResultViewModel>> => {
    
    return axios.put(
      `/api/v1/activity`,
      updateActivityViewModel,options
    );
  }



export const getPutApiV1ActivityMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Activity>>, TError,{data: UpdateActivityViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1Activity>>, TError,{data: UpdateActivityViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1Activity>>, {data: UpdateActivityViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1Activity(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1ActivityMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1Activity>>>
    export type PutApiV1ActivityMutationBody = UpdateActivityViewModel
    export type PutApiV1ActivityMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic activity information
 */
export const usePutApiV1Activity = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Activity>>, TError,{data: UpdateActivityViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1Activity>>,
        TError,
        {data: UpdateActivityViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1ActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets all info about a given Activity
 */
export const getApiV1ActivityActivityId = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityWithStudentViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/${activityId}`,options
    );
  }


export const getGetApiV1ActivityActivityIdQueryKey = (activityId: string,) => {
    return [`/api/v1/activity/${activityId}`] as const;
    }

    
export const getGetApiV1ActivityActivityIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivityActivityIdQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>> = ({ signal }) => getApiV1ActivityActivityId(activityId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivityActivityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>>
export type GetApiV1ActivityActivityIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityActivityId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets all info about a given Activity
 */

export function useGetApiV1ActivityActivityId<TData = Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError = AxiosError<ProblemDetails | void>>(
 activityId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityActivityId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivityActivityIdQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Removes the activity
 */
export const deleteApiV1ActivityActivityId = (
    activityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/activity/${activityId}`,options
    );
  }



export const getDeleteApiV1ActivityActivityIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>, TError,{activityId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>, TError,{activityId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>, {activityId: string}> = (props) => {
          const {activityId} = props ?? {};

          return  deleteApiV1ActivityActivityId(activityId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1ActivityActivityIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>>
    
    export type DeleteApiV1ActivityActivityIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes the activity
 */
export const useDeleteApiV1ActivityActivityId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>, TError,{activityId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1ActivityActivityId>>,
        TError,
        {activityId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1ActivityActivityIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List Activities of a given theme
 */
export const getApiV1ActivityThemeThemeId = (
    themeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityWithTotalsViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/theme/${themeId}`,options
    );
  }


export const getGetApiV1ActivityThemeThemeIdQueryKey = (themeId: string,) => {
    return [`/api/v1/activity/theme/${themeId}`] as const;
    }

    
export const getGetApiV1ActivityThemeThemeIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivityThemeThemeIdQueryKey(themeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>> = ({ signal }) => getApiV1ActivityThemeThemeId(themeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(themeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivityThemeThemeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>>
export type GetApiV1ActivityThemeThemeIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivityThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Activities of a given theme
 */

export function useGetApiV1ActivityThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivityThemeThemeIdQueryOptions(themeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Gets all activities assigned to a given teacher
 */
export const getApiV1ActivityTeacherTeacherId = (
    teacherId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityWithTotalsViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/teacher/${teacherId}`,options
    );
  }


export const getGetApiV1ActivityTeacherTeacherIdQueryKey = (teacherId: string,) => {
    return [`/api/v1/activity/teacher/${teacherId}`] as const;
    }

    
export const getGetApiV1ActivityTeacherTeacherIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivityTeacherTeacherIdQueryKey(teacherId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>> = ({ signal }) => getApiV1ActivityTeacherTeacherId(teacherId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(teacherId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivityTeacherTeacherIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>>
export type GetApiV1ActivityTeacherTeacherIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivityTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets all activities assigned to a given teacher
 */

export function useGetApiV1ActivityTeacherTeacherId<TData = Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError = AxiosError<ProblemDetails | void>>(
 teacherId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityTeacherTeacherId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivityTeacherTeacherIdQueryOptions(teacherId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieves all activities from a given school
 */
export const getApiV1ActivitySchoolSchoolId = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityWithTotalsViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/school/${schoolId}`,options
    );
  }


export const getGetApiV1ActivitySchoolSchoolIdQueryKey = (schoolId: string,) => {
    return [`/api/v1/activity/school/${schoolId}`] as const;
    }

    
export const getGetApiV1ActivitySchoolSchoolIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivitySchoolSchoolIdQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>> = ({ signal }) => getApiV1ActivitySchoolSchoolId(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivitySchoolSchoolIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>>
export type GetApiV1ActivitySchoolSchoolIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivitySchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivitySchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivitySchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all activities from a given school
 */

export function useGetApiV1ActivitySchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivitySchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivitySchoolSchoolIdQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiV1ActivityGroupGroupId = (
    groupId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityWithTotalsViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/group/${groupId}`,options
    );
  }


export const getGetApiV1ActivityGroupGroupIdQueryKey = (groupId: string,) => {
    return [`/api/v1/activity/group/${groupId}`] as const;
    }

    
export const getGetApiV1ActivityGroupGroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivityGroupGroupIdQueryKey(groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>> = ({ signal }) => getApiV1ActivityGroupGroupId(groupId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivityGroupGroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>>
export type GetApiV1ActivityGroupGroupIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivityGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiV1ActivityGroupGroupId<TData = Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError = AxiosError<ProblemDetails | void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityGroupGroupId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivityGroupGroupIdQueryOptions(groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieves all activitiess that a given student has submited an essay
 */
export const getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays = (
    studentId: string,
    schoolId: string,
    days: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ActivityForStudentViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/activity/student/${studentId}/school/${schoolId}/days/${days}`,options
    );
  }


export const getGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryKey = (studentId: string,
    schoolId: string,
    days: number,) => {
    return [`/api/v1/activity/student/${studentId}/school/${schoolId}/days/${days}`] as const;
    }

    
export const getGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError = AxiosError<ProblemDetails | void>>(studentId: string,
    schoolId: string,
    days: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryKey(studentId,schoolId,days);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>> = ({ signal }) => getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays(studentId,schoolId,days, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(studentId && schoolId && days), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>>
export type GetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays<TData = Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    schoolId: string,
    days: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays<TData = Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    schoolId: string,
    days: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays<TData = Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    schoolId: string,
    days: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Retrieves all activitiess that a given student has submited an essay
 */

export function useGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays<TData = Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError = AxiosError<ProblemDetails | void>>(
 studentId: string,
    schoolId: string,
    days: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDaysQueryOptions(studentId,schoolId,days,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



