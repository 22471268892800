import { Container, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spacer } from '@chakra-ui/react'

export default function CompetenciesList({
  itemData,
  modalEditCompetencyDisclosure,
  setEditCompetency,
  modalDeleteCompetencyDisclosure,
}) {
  if (itemData.competencies.length > 0) {
    return itemData.competencies.map((item, key) => {
      return (
        <div className="competency-row" key={key}>
          <HStack width={'100%'}>
            <div className="competency-row-text publicsans-normal-nevada-14px">{item.name}</div>

            <Spacer />

            <Menu placement={'bottom-start'}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<img src={'/img/more-vert.svg'} />}
                variant="outline"
                marginRight={'16px'}
              />
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setEditCompetency(item)
                    modalEditCompetencyDisclosure.onOpen()
                  }}>
                  Editar Competência
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setEditCompetency(item)
                    modalDeleteCompetencyDisclosure.onOpen()
                  }}>
                  Remover Competência
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </div>
      )
    })
  }
  return <Container height={'50px'} />
}
