import DateWithCalendarIcon from '@/components/DateWithCalendarIcon'
import useNavigationStore from '@/store/useNavigationStore'
import { essayStatus, returnEssayStatusClassName } from '@/utils/essayStatus'
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function InitialPageStudentCard(props) {
  const { itemData } = props

  const currentDate = new Date()
  const deadlineDate = new Date(itemData.deadline)
  const startDate = new Date(itemData.validFrom)
  const startDateText = currentDate >= startDate ? 'Iniciou em:' : 'Inicia em:'
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  const backgroundColor = returnEssayStatusClassName(itemData.essayStatus)

  return (
    <VStack borderRadius="lg" align="start" alignContent="center" overflow="hidden" boxShadow="xl">
      <Box p={6}>
        <Text className="publicsans-bold-absolute-zero-16px" noOfLines={1}>
          {itemData.subject}
        </Text>
        <Box mt={4}>
          <Text className="publicsans-bold-nevada-10px">TEMA</Text>
          <Text className="publicsans-normal-nevada-14px" noOfLines={1}>
            {itemData.theme.name}
          </Text>
        </Box>
      </Box>
      <VStack p={6} gap={6} bgColor="white" height="100%" width="100%">
        <Box w={'100%'}>
          <Text className={`publicsans-normal-nevada-16px ${backgroundColor}`}>{essayStatus[itemData.essayStatus].label}</Text>
        </Box>
        <HStack
          width="100%"
          justifyContent={'space-between'}
          gap={{ md: 10, base: 5 }}
          flexDir={{ md: 'row', base: 'column' }}
          alignItems={'start'}>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-16px">{startDateText}</Text>
            <DateWithCalendarIcon imagePath={'/img/calendar-icon.svg'} date={startDate} />
          </VStack>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-16px">Entrega final:</Text>
            <DateWithCalendarIcon imagePath={'/img/calendar-icon.svg'} date={deadlineDate} shouldHighlight={true} />
          </VStack>
        </HStack>
        <Button
          variant={'primary'}
          padding={'24px'}
          width={'100%'}
          onClick={() => {
            setReturnTo('/area-de-atividades')
            navigate(`/detalhamento-atividade/${itemData.id}`)
          }}>
          Ver detalhes
        </Button>
      </VStack>
    </VStack>
  )
}

export default InitialPageStudentCard
