import { AudioPlayer } from '@/components/AudioPlayer'
import { useGetApiV1EssayEssayIdComments } from '@/services/essay'
import { EssayCommentType } from '@/services/types'
import { Center, Heading, Textarea, VStack } from '@chakra-ui/react'

type GeneralCommentProps = {
  essayId: string
}

export const GeneralComment = (props: GeneralCommentProps) => {
  const { essayId } = props

  // Get
  const getCommentsAPI = useGetApiV1EssayEssayIdComments(essayId)
  const comments = getCommentsAPI.data?.data.message
  const generalComment = comments?.find((comment) => comment.competencyId === null)

  const hasComment = !!generalComment?.comment
  const isAudio = generalComment?.commentType === EssayCommentType.NUMBER_1
  const isText = generalComment?.commentType === EssayCommentType.NUMBER_0

  if (getCommentsAPI.isLoading) return null

  return (
    <VStack spacing={6} width={'100%'}>
      <Heading size={'md'} w={'100%'}>
        Comentários gerais
      </Heading>

      {/* Visualize comment */}
      {hasComment && isAudio && <AudioPlayer src={generalComment.comment || ''} />}
      {hasComment && isText && (
        <Textarea
          backgroundColor="white"
          border="none"
          resize="none"
          minH="190px"
          value={generalComment.comment || ''}
          readOnly={true}
        />
      )}
      {!hasComment && (
        <Center backgroundColor="gray.100" color="gray.400" borderRadius="xl" width="100%" minH="100px">
          Nenhum comentário adicionado
        </Center>
      )}
    </VStack>
  )
}
