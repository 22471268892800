import DateWithCalendarIcon from '@/components/DateWithCalendarIcon'
import useNavigationStore from '@/store/useNavigationStore'
import { Button, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function ThemeCard(props) {
  const { itemData } = props
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  const atividadesVinculadas = itemData.activities.length
  const tema = itemData.subject
  const base64Image = itemData.base64Image

  return (
    <VStack borderRadius="lg" align="start" alignContent="center" overflow="hidden" boxShadow="xl">
      <HStack p={6} gap={5}>
        <Image boxSize="120px" objectFit="contain" src={base64Image} />
        <VStack alignItems="start">
          <Text className="publicsans-bold-absolute-zero-16px" wordBreak={'break-word'} noOfLines={2}>
            {tema}
          </Text>
        </VStack>
      </HStack>
      <VStack p={6} gap={6} bgColor="white" height="100%" width="100%">
        <HStack
          width="100%"
          gap={3}
          flexDir={{ md: 'row', base: 'column' }}
          alignItems={'start'}
          justifyContent={'space-between'}>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-14px">Atividades vinculadas</Text>
            <HStack w={'100%'}>
              <Image src={'/img/document-icon.svg'} />
              <Text>{atividadesVinculadas}</Text>
            </HStack>
          </VStack>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-14px">Criada em:</Text>
            <DateWithCalendarIcon imagePath={'/img/calendar-icon.svg'} date={itemData.createdAt} />
          </VStack>
        </HStack>
        <Button
          variant={'primary'}
          padding={'24px'}
          width={'100%'}
          onClick={() => {
            setReturnTo('/area-de-temas')
            navigate(`/detalhamento-tema/${itemData.id}`)
          }}>
          Ver detalhes
        </Button>
      </VStack>
    </VStack>
  )
}

export default ThemeCard
