import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react'
import { useWavesurfer } from '@wavesurfer/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { GrStatusGoodSmall } from 'react-icons/gr'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'
import { toSeconds, toTimer } from '../../utils/toHour'
import { MicIcon } from '../icons/Mic'

import './VoiceRecorder.css'

enum PlayerState {
  UNINITIALIZED = 'uninitialized',
  RECORDING = 'recording',
}

type VoiceRecorderProps = {
  onStartRecording: () => void
  onRecordingComplete: (blob: Blob) => void
}

const record = RecordPlugin.create({ renderRecordedAudio: false })

export const VoiceRecorder = ({ onStartRecording, onRecordingComplete }: VoiceRecorderProps) => {
  const containerRef = useRef(null)
  const [recordState, setRecordState] = useState(PlayerState.UNINITIALIZED)
  const [currentTimeRecord, setCurrentTimeRecord] = useState<number>()

  useWavesurfer({
    container: containerRef,
    waveColor: 'rgb(200, 200, 200)',
    height: 'auto',
    progressColor: 'rgb(10, 65, 214)',
    plugins: useMemo(() => [record], []),
  })

  useEffect(() => {
    record.on('record-start', () => {
      onStartRecording()
      setRecordState(PlayerState.RECORDING)
    })
    record.on('record-progress', (time) => setCurrentTimeRecord(toSeconds(time)))
    record.on('record-end', (blob) => onRecordingComplete(blob))
  }, [])

  const onToggleRecording = async () => {
    switch (recordState) {
      case PlayerState.UNINITIALIZED:
        await record.startRecording()
        break
      case PlayerState.RECORDING:
        record.stopRecording()
        break
    }
  }

  return (
    <HStack
      w="100%"
      display="flex"
      gap={4}
      padding={3}
      borderColor="brand.300"
      borderWidth="1px"
      borderStyle="solid"
      backgroundColor="white"
      position="relative"
      borderRadius="lg">
      <Button
        variant="outline"
        borderRadius="full"
        borderColor="brand.200"
        width="60px"
        height="60px"
        boxShadow="0px 0px 10px 0px rgba(107, 107, 121, 0.24)"
        onClick={onToggleRecording}>
        {recordState === PlayerState.UNINITIALIZED && <MicIcon />}
        {recordState === PlayerState.RECORDING && <GrStatusGoodSmall className="voice-recorde-pulse" color="red" />}
      </Button>
      <Box
        ref={containerRef}
        height="60px"
        flexGrow={1}
        display={recordState === PlayerState.UNINITIALIZED ? 'none' : 'block'}
      />
      {recordState === PlayerState.UNINITIALIZED && (
        <Stack display="flex" flex={1}>
          <Text fontSize="md" color="gray.500">
            Iniciar gravação
          </Text>
          <Box width="100%" height="5px" backgroundColor="gray.200" />
        </Stack>
      )}
      <Box>{toTimer(currentTimeRecord)}</Box>
    </HStack>
  )
}
