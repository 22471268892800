import { Box, SimpleGrid } from '@chakra-ui/react'
import { type ComponentType, useState } from 'react'

import ReactPaginate from 'react-paginate'
import './SharedCardPagination.css'

type Data = {
  id?: string
}

// TODO: This data and type should be generic
type InstitutionProps = {
  fetchAllInstitutions: () => void
  schoolId: string
}

type SharedCardPaginationProps = {
  data: Data[]
  RenderComponent: ComponentType<{ itemData: Data; parentProps?: InstitutionProps }>
  itemsPerPage: number
  props?: InstitutionProps
}

export default function SharedCardPagination({ data, RenderComponent, itemsPerPage, props }: SharedCardPaginationProps) {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageClick = ({ selected: selectedPage }) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setCurrentPage(selectedPage)
  }

  const offset = currentPage * itemsPerPage
  const currentPageData = data
    ? data
        .slice(offset, offset + itemsPerPage)
        .map((item) => <RenderComponent key={item.id} itemData={item} parentProps={props} />)
    : null
  const pageCount = Math.ceil(data.length / itemsPerPage)

  return (
    <Box width="100%">
      <SimpleGrid templateColumns="repeat(auto-fill, minmax(300px,  1fr))" spacing="30px">
        {currentPageData}
      </SimpleGrid>
      <Box>
        <ReactPaginate
          previousLabel={<img className="ic-arrow-pagination" src="/img/ic-arrowleft@2x.png" alt="ic-arrowleft" />}
          nextLabel={<img className="ic-arrow-pagination" src="/img/ic-arrowright@2x.png" alt="ic-arrowright" />}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'current-page-pagination'}
          pageLinkClassName={'number-pagination'}
        />
      </Box>
    </Box>
  )
}
