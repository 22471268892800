import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.locale('pt-br')

// Reformata o campo em formato data YYYY-MM-DD:T para DD/MM/YYYY facilitando a digitacao do usuario
export default function sanitizedDate(input) {
  const dayjsInstance = dayjs(input).utc()
  return dayjsInstance.format('DD/MM/YYYY')
}

export function toUtc(date) {
  return `${date}Z`
}

export function relativeDate(date) {
  return dayjs(toUtc(date)).toNow(true)
}

export function abbreviatedMonth(date) {
  function firstLetterMonthUpperCase(date) {
    const chunks = date.split('/')
    chunks[1] = chunks[1].charAt(0).toUpperCase() + chunks[1].slice(1).toLowerCase()
    return chunks.join('/')
  }

  const dayjsInstance = dayjs(date).utc()
  const formattedDate = dayjsInstance.format('DD/MMM/YYYY')
  const result = firstLetterMonthUpperCase(formattedDate)
  return result
}
