import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import 'react-datepicker/dist/react-datepicker.css'
import { debounce } from '@/utils/debounce'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import * as yup from 'yup'
import StepFormFilled from '../../components/StepFormFilled'
import { usePostApiV1School } from '../../services/school'
import { usePutApiV1SharedAddressSchoolSchoolId } from '../../services/shared'
import useCriarEscolaStore from '../../store/useCriarEscolaStore'
import { inputCEPMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  street: yup.string().required().min(5).max(150),
  number: yup.string().required().min(1).max(10),
  zipCode: yup.string().required().zipCode(),
  city: yup.string().required().max(50),
  state: yup.string().required().max(50),
  complement: yup.string().max(50),
})

function CreateSchoolStep2(props) {
  const { handleReturn, handlingNextStep } = props
  const { criarEscolaData, setCriarEscolaData } = useCriarEscolaStore()

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      street: criarEscolaData?.address?.street || '',
      number: criarEscolaData?.address?.number || '',
      zipCode: criarEscolaData?.address?.zipCode || '',
      city: criarEscolaData?.address?.city || '',
      state: criarEscolaData?.address?.state || '',
      complement: criarEscolaData?.address?.complement || '',
    },
  })

  const putApiV1SharedAddressSchoolSchoolId = usePutApiV1SharedAddressSchoolSchoolId()

  const postApiV1School = usePostApiV1School()

  const fetchAddress = async (zipCode) => {
    try {
      await validationSchema.validateAt('zipCode', { zipCode: zipCode })

      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      const data = await response.json()

      if (data.erro) {
        resetAddress('CEP não encontrado')
      } else {
        setValue('street', data.logradouro)
        setValue('city', data.localidade)
        setValue('state', data.uf)
        clearErrors()
      }
    } catch (error) {
      resetAddress(error.message)
    }
  }

  const fetchAddressDebounce = useCallback(debounce(fetchAddress), [])
  const resetAddress = (message) => {
    setValue('street', '')
    setValue('city', '')
    setValue('state', '')
    setError('zipCode', { type: 'manual', message })
  }

  const onSubmit = (body) => {
    postApiV1School.mutate(
      {
        data: {
          document: criarEscolaData.documentNumber,
          name: criarEscolaData.name,
          thumbnail: criarEscolaData.thumbnail,
        },
      },
      {
        onSuccess: (response) => {
          const schoolId = response?.data?.message
          setCriarEscolaData({ schoolId: schoolId })

          putApiV1SharedAddressSchoolSchoolId.mutate(
            {
              schoolId: schoolId,
              data: {
                ...body,
                address: {
                  street: body.street,
                  city: body.city,
                  state: body.state,
                  zipCode: body.zipCode,
                },
              },
            },
            {
              onSuccess: () => {
                handlingNextStep()
              },
            },
          )
        },
      },
    )
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-escolas'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar escola
        </Heading>
        <VStack w={'100%'} mt={3} gap={4} alignItems={'start'}>
          <StepFormFilled title={'Sobre a escola'} content={criarEscolaData.name} />
          <Box w={'100%'}>
            <StepsImage text={'Endereço'} number={2} />
            <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <Grid mt={4} gap={5} width={'100%'}>
                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl isInvalid={errors.zipCode?.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">CEP</FormLabel>
                    <Input
                      id="zipCode"
                      as={InputMask}
                      mask={inputCEPMask}
                      borderColor={'brand.300'}
                      h={'48px'}
                      w={'100%'}
                      backgroundColor={'white'}
                      {...register('zipCode')}
                      onChange={(e) => {
                        fetchAddressDebounce(e.target.value.replace(/\D/g, ''))
                      }}
                    />
                    <FormErrorMessage>{errors.zipCode?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <Grid templateColumns={{ sm: '1fr', md: '3fr 1fr' }} gap={4}>
                    <GridItem>
                      <FormControl isInvalid={errors.street?.message}>
                        <FormLabel className="publicsans-bold-nevada-16px">Rua</FormLabel>
                        <Input
                          h={'48px'}
                          w={'100%'}
                          borderColor={'brand.300'}
                          backgroundColor={'white'}
                          {...register('street')}
                          isDisabled={true}
                        />
                        <FormErrorMessage>{errors.street?.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>

                    <GridItem>
                      <FormControl isInvalid={errors.number?.message}>
                        <FormLabel className="publicsans-bold-nevada-16px">Número</FormLabel>
                        <Input
                          h={'48px'}
                          w={'100%'}
                          borderColor={'brand.300'}
                          backgroundColor={'white'}
                          {...register('number')}
                        />
                        <FormErrorMessage>{errors.number?.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl isInvalid={errors.complement?.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Complemento</FormLabel>
                    <Input
                      h={'48px'}
                      w={'100%'}
                      borderColor={'brand.300'}
                      backgroundColor={'white'}
                      {...register('complement')}
                    />
                    <FormErrorMessage>{errors.complement?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl isInvalid={errors.city?.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Cidade</FormLabel>
                    <Input
                      h={'48px'}
                      w={'100%'}
                      borderColor={'brand.300'}
                      backgroundColor={'white'}
                      {...register('city')}
                      isDisabled={true}
                    />
                    <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={{ sm: 3, lg: 2 }}>
                  <FormControl isInvalid={errors.state?.message}>
                    <FormLabel className="publicsans-bold-nevada-16px">Estado</FormLabel>
                    <Input
                      h={'48px'}
                      w={'100%'}
                      borderColor={'brand.300'}
                      backgroundColor={'white'}
                      {...register('state')}
                      isDisabled={true}
                    />
                    <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
              <HStack
                display="flex"
                justifyContent="end"
                flexDir={{ md: 'row', base: 'column-reverse' }}
                w={'100%'}
                gap={3}
                my={6}>
                <Button
                  variant={'outline'}
                  disabled={postApiV1School.isPending}
                  height={'48px'}
                  width={'100%'}
                  onClick={handleReturn}>
                  Voltar
                </Button>
                <Button variant={'primary'} type="submit" width={'100%'} height={'48px'} isLoading={postApiV1School.isPending}>
                  Continuar
                </Button>
              </HStack>
            </form>
          </Box>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateSchoolStep2
