import { useState } from 'react'
import './ActivityArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import CalendarComponent from '@/components/CalendarComponent'
import SearchComponent from '@/components/SearchComponent'
import SharedCardPagination from '@/components/SharedCardPagination'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1ActivitySchoolSchoolId } from '@/services/activity'
import useLoggedUserStore from '@/store/useLoggedUserStore'
import sanitizedDate from '@/utils/DateSatinizerInput'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Flex, Text } from '@chakra-ui/react'
import Page from '../Page'
import ActivityCard from './ActivityAreaCard'
import { ActivityAreaSidebar } from './ActivityAreaSidebar'
import { ActivityAreaTabs, type SelectedTab } from './ActivityAreaTabs'

function ActivityArea() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10

  const { loggedUser } = useLoggedUserStore()
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('todas')
  const [filterText, setFilterText] = useState('')
  const getApiV1ActivitySchool = useGetApiV1ActivitySchoolSchoolId(loggedUser.schoolId)
  const activitiesData = getApiV1ActivitySchool?.data?.data?.message || []

  useIsHistoryEmpty()

  const activities = activitiesData.filter((item) => {
    const result =
      (item?.theme?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        sanitizedDate(item.createdAt).includes(filterText) ||
        item?.subject?.toLowerCase().includes(filterText.toLowerCase()) ||
        sanitizedDate(item.deadline).includes(filterText)) &&
      filterBySelectedTab(item)

    return result
  })

  function filterBySelectedTab(item) {
    const currentDate = new Date()
    if (selectedTab === 'todas') {
      return true
    }
    if (selectedTab === 'em-andamento') {
      return isInProgress(item)
    }
    if (selectedTab === 'finalizadas') {
      return new Date(item.deadline) <= currentDate
    }
    return true
  }

  function isInProgress(item) {
    const currentDate = new Date()

    return new Date(item.validFrom) <= currentDate && new Date(item.deadline) >= currentDate
  }

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  return (
    <Page authPage={true} allowedUserType={''}>
      <LayoutMenu isLoading={getApiV1ActivitySchool?.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Atividades"
            rightSidebar={<CalendarComponent activities={activities} />}
            button={{
              isVisible: loggedUser.user_role !== 'student',
              navigateTo: '/criar-atividade',
              label: 'Criar atividade',
            }}
          />
          <ActivityAreaTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

          {activities?.length > 0 ? (
            <SharedCardPagination data={activities} RenderComponent={ActivityCard} itemsPerPage={itemsPerPage} />
          ) : (
            <Flex
              width={'100%'}
              alignSelf={'center'}
              backgroundColor={'#f3f5ff'}
              height={'64px'}
              marginRight={'32px'}
              borderRadius={'16px'}>
              <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'} alignSelf={'center'}>
                Nenhuma atividade foi associado ao seu perfil ainda.
              </Text>
            </Flex>
          )}
        </LayoutMenu.Content>
        <ActivityAreaSidebar activities={activities} isInProgress={isInProgress} />
      </LayoutMenu>
    </Page>
  )
}

export default ActivityArea
