import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  Spinner,
} from '@chakra-ui/react'
import { useRef } from 'react'

export const Modal = ({ isOpen, onClose, title, body, submitLabel, onSubmit, isLoading, size = 'lg' }) => {
  const cancelRef = useRef()

  return (
    <Box>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered size={size}>
        <AlertDialogOverlay>
          <AlertDialogContent ml={2} mr={2} mt={5} pb={3}>
            <CloseButton position={'absolute'} top="10px" right={'10px'} onClick={onClose} />
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            {onClose && onSubmit && submitLabel && (
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button variant="primary" onClick={onSubmit} ml={3}>
                  {isLoading ? <Spinner size="xs" marginRight="2" color="white" /> : null}
                  {submitLabel}
                </Button>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
