import { useToggleURLQueryParams } from '../../utils/useToogleQueryParam'

export enum EssayReviewSidebar {
  GENERAL_COMMENTS = 'generalComments',
  DETAILS = 'details',
}

const essayReviewSidebarItems = Object.values(EssayReviewSidebar)

export const useEssayReviewSidebar = () => {
  const { toggleURLQueryParam, hasURLQueryParams, deleteURLQueryParams } = useToggleURLQueryParams()

  const toogleSidebar = (sidebar: EssayReviewSidebar) => {
    const previousOpenedSidebar = essayReviewSidebarItems.filter((sidebarItem) => sidebarItem === sidebar)
    deleteURLQueryParams(previousOpenedSidebar)
    toggleURLQueryParam(sidebar, 'open')
  }
  const isSidebarOpen = hasURLQueryParams(essayReviewSidebarItems)

  const isCurrentSideBarOpen = (sidebarName: EssayReviewSidebar) => hasURLQueryParams([sidebarName])
  return {
    toogleSidebar,
    isSidebarOpen,
    isCurrentSideBarOpen,
  }
}
