import { AreaHeader } from '@/components/AreaHeader'
import LayoutMenu from '@/layouts/LayoutMenu'
import { HStack, Select, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { USER_ROLE, USER_ROLE_CODE, userRolesOptions } from '../../apis/Constants'
import { listAllUsers } from '../../apis/ownerApi/ownerApi'
import { NewAdminModalBody } from '../../components/CadastrarAdmin'
import { NewIndependentTeacherModalBody } from '../../components/CadastrarProfessorIndependente'
import { IndependentTeacherDetailsModalBody } from '../../components/DetalhamentoProfessorIndependente'
import { Modal } from '../../components/modal'
import Page from '../Page'
import { IndependentTeacherSearch } from './independentTeacherSearch'

const ownerCanCreate = (role) => [USER_ROLE_CODE.ADM, USER_ROLE_CODE.INDEPENDENT_TEACHER].includes(role)

function InitialPageOwner(_props) {
  const [users, setUsers] = useState([])
  const [selectedUserRole, setSelectedUserRole] = useState(USER_ROLE_CODE.INDEPENDENT_TEACHER)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState(null)

  const { isOpen: detailsIsOpen, onClose: detailsOnClose, onOpen: detailsOnOpen } = useDisclosure()
  const { isOpen: createIsOpen, onClose: createOnClose, onOpen: createOnOpen } = useDisclosure()

  function handleDetailsOnClose() {
    detailsOnClose()
    setSelectedTeacher(null)
  }

  function handleDetailsOnOpen(teacher) {
    setSelectedTeacher(teacher)
    detailsOnOpen()
  }

  async function fetchUsers() {
    try {
      setIsLoading(true)
      const result = await listAllUsers(selectedUserRole, page)

      setUsers(result)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!detailsIsOpen && !createIsOpen) {
      fetchUsers()
    }
  }, [selectedUserRole, detailsIsOpen, createIsOpen, page])

  const createModal = {
    [USER_ROLE_CODE.ADM]: <NewAdminModalBody onClose={createOnClose} />,
    [USER_ROLE_CODE.INDEPENDENT_TEACHER]: <NewIndependentTeacherModalBody onClose={createOnClose} />,
  }

  return (
    <Page authPage={true} allowedUserType={'owner'}>
      <LayoutMenu isLoading={false}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Usuários"
            button={{
              isVisible: ownerCanCreate(selectedUserRole),
              onClick: createOnOpen,
              label: 'Adicionar usuários',
            }}
          />
          <HStack w="100%" justify={{ base: 'center', md: 'start' }}>
            <Select
              width="250px"
              backgroundColor="white"
              borderColor="blackAlpha.300"
              defaultValue={selectedUserRole}
              onChange={(e) => {
                setSelectedUserRole(Number(e.target.value))
                setPage(1)
              }}>
              {userRolesOptions.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Select>
          </HStack>
          <IndependentTeacherSearch
            hits={users}
            isLoading={isLoading}
            detailsAction={handleDetailsOnOpen}
            page={page}
            setPage={setPage}
          />
        </LayoutMenu.Content>
        <Modal
          title={`Professor (a), ${selectedTeacher?.name}`}
          isOpen={detailsIsOpen}
          onClose={handleDetailsOnClose}
          body={
            <IndependentTeacherDetailsModalBody independentTeacherId={selectedTeacher?.id} onClose={handleDetailsOnClose} />
          }
          size="xl"
        />
        <Modal
          title={`Cadastrar novo ${USER_ROLE[selectedUserRole]} `}
          isOpen={createIsOpen}
          onClose={createOnClose}
          body={createModal[selectedUserRole]}
          size="2xl"
        />
      </LayoutMenu>
    </Page>
  )
}

export default InitialPageOwner
