import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const createAvailableEssay = async (body) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/essay`, body)

  return response.data.message
}

export const fetchEssay = async (id) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/essay/${id}`)

  return response.data.message
}

export const editExitingEssay = async (id, newContent) => {
  const body = {
    essayId: id,
    draft: newContent,
    isText: true,
  }
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/essay`, body)

  return response.data.message
}

export const submitEssayForCorrection = async (id) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/essay/${id}/submit`)

  return response.data.message
}

export const deleteEssayApi = async (id) => {
  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/essay/${id}`)

  return response.data.message
}

export const fetchComments = async (id) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/essay/${id}/comments`)

  return response.data.message
}

export const updateGradesApi = async (essayId, body) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/essay/${essayId}/update-grades`, body)

  return response.data.message
}

export const postcommentApi = async (essayId, body) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/essay/${essayId}/comments`, body)

  return response.data.message
}

export const deleteCommentApi = async (essayId, commentId) => {
  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/essay/${essayId}/comments/${commentId}`)

  return response.data.message
}

export const sendReviewApi = async (essayId) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/essay/${essayId}/finish-review`)

  return response.data.message
}

export const closeReviewApi = async (essayId) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/essay/${essayId}/close-review`)

  return response.data.message
}

export const getEssaysByTeacherIdApi = async (daysOfEssays) => {
  const teacherID = localStorage.getItem('user_id')

  const response = await httpRequestHelper.getWithAuth(
    `${BACKEND_API}/v1/essay/teacher/${teacherID}${daysOfEssays ? `?days=${daysOfEssays}` : ''}`,
  )

  return response.data.message
}

export const getEssaysByStudentIdApi = async (studentID, daysOfEssays) => {
  const response = await httpRequestHelper.getWithAuth(
    `${BACKEND_API}/v1/essay/student/${studentID}${daysOfEssays ? `?days=${daysOfEssays}` : ''}`,
  )

  return response.data.message
}

export const getEssaysByGroupIdApi = async (groupID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/essay/group/${groupID}`)

  return response.data.message
}

export const getEssaysByGroupIdListApi = async (body) => {
  const response = await httpRequestHelper.patchWithAuth(`${BACKEND_API}/v1/essay/groups`, body)

  return response.data.message
}
