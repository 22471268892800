import * as XLSX from 'xlsx'
import { emailRegex } from '../../utils/emailRegex'

type Row = {
  name: string
  email: string
}

type StudentFileRows = {
  extractedData?: Row[]
  error?: string
}
export const validateStudentFileRows = async (file?: File): Promise<StudentFileRows> => {
  if (!file) return { error: 'Arquivo inválido' }

  // Parse file
  const dataFile = await file.arrayBuffer()
  const workbook = XLSX.read(dataFile, { type: 'array' })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
  const extractedData = jsonData
    .slice(1)
    .map((row) => ({ name: row?.[0], email: row?.[1] }))
    .filter((row) => row.name && row.email)

  // Validate rows
  const invalidEmails = extractedData.filter((student) => !student.email || !emailRegex.test(student.email))
  const invalidNames = extractedData.filter((student) => !student.name || student.name.length < 3 || student.name.length > 50)

  // Data valid, return extracted data
  if (invalidEmails.length === 0 && invalidNames.length === 0) {
    return { extractedData }
  }

  // Data invalid, return error messages
  let error = `${extractedData.length} alunos encontrados. `
  if (invalidEmails.length > 0) {
    error += invalidEmails.length === 1 ? '1 e-mail inválido' : `${invalidEmails.length} e-mails inválidos`
  }
  if (invalidNames.length > 0) {
    if (error.length > 0) error += '. '
    error += invalidNames.length === 1 ? '1 nome inválido' : `${invalidNames.length} nomes inválidos`
  }

  return { error }
}
