import { useEffect, useState } from 'react'
import './EssayArea.css'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Box, HStack, Select, Text, Tooltip, VStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { getEssaysByStudentIdApi, getEssaysByTeacherIdApi } from '../../apis/essayApi/essayApi'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import sanitizedDate from '../../utils/DateSatinizerInput'
import { essayStatus, returnEssayStatusClassName } from '../../utils/essayStatus'
import Page from '../Page'

function EssayArea() {
  const { loggedUser } = useLoggedUserStore()
  const location = useLocation()

  const initialDaysOfEssays = location.state?.daysOfEssays

  const [redacoes, setRedacoes] = useState([])
  const [redacoesData, setRedacoesData] = useState([])
  const [filterText, setFilterText] = useState(location.state?.filterText || '')
  const [daysOfEssays, setDaysOfEssays] = useState(initialDaysOfEssays || 30)

  async function handleInitialData() {
    if (loggedUser.user_role === 'student') {
      setRedacoesData(await getEssaysByStudentIdApi(loggedUser.user_id, daysOfEssays))
    } else {
      setRedacoesData(await getEssaysByTeacherIdApi(daysOfEssays))
    }
  }

  useIsHistoryEmpty()

  useEffect(() => {
    handleInitialData()
  }, [])

  useEffect(() => {
    handleInitialData()
  }, [daysOfEssays])

  useEffect(() => {
    if (redacoesData) {
      setRedacoes(
        redacoesData.filter((item) => {
          const result =
            (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(filterText)) ||
            essayStatus[item.status].label.toLowerCase().includes(filterText.toLowerCase())

          return result
        }),
      )
    }
  }, [filterText, redacoesData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function handleDataDeEntrega(dataDeEntrega) {
    let date = ''
    if (dataDeEntrega) {
      date = `${new Date(dataDeEntrega).toLocaleDateString()} às ${new Date(dataDeEntrega).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
    return (
      <div className={'table-cell-container publicsans-normal-black-12px'}>
        {date !== '' ? (
          <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-icon.svg'} alt="calendar-2851774" />
        ) : (
          ''
        )}
        {date}
      </div>
    )
  }

  function HandleStatusInput(status) {
    const backgroundColor = returnEssayStatusClassName(status)
    return (
      <div className={'atividade-txt-detalhamento-aluno publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{essayStatus[status]?.label}</div>
      </div>
    )
  }

  function handleTema(itemData) {
    return <Text noOfLines={3}>{itemData.theme?.name}</Text>
  }

  function handleDetalhes(itemData) {
    if (itemData.status < 20 && loggedUser.user_role !== 'student') {
      return (
        <Tooltip label="Redações só podem ser vistas por professor após entrega.">
          <div className={'teste publicsans-normal-black-12px'}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </div>
        </Tooltip>
      )
    }
    const url = loggedUser.user_role === 'student' ? `/redacao?id=${itemData.id}` : `/correcao-redacao/${itemData.id}`
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={url}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightblue' : null,
    }),
    control: (provided) => ({
      ...provided,
    }),
  }

  return (
    <Box className="screen-container">
      <HStack width={'100%'} height={'100%'} gap={0}>
        <Menu />
        <Page authPage={true} allowedUserType={''} />
        <VStack width={'100%'} maxHeight={'100vh'} alignSelf={'start'} overflow={'auto'}>
          <Headbar hideHeaderElements={true} />
          <Box className={'body-no-right'}>
            <Box backgroundColor={'brand.600'} width={'100%'} maxWidth={'100%'} padding={'24px'} borderRadius={'12px'}>
              <Text
                textAlign={{ base: 'center', md: 'start' }}
                fontSize={{ base: '25px', md: '40px' }}
                className={'publicsans-normal-absolute-zero-40px'}>
                Área de Redações
              </Text>
            </Box>
            <div className="search-filter-1">
              <div className="search-filtro">
                <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

                <Select
                  onChange={(e) => {
                    setDaysOfEssays(Number.parseInt(e.target.value))
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  styles={customStyles}
                  defaultValue={daysOfEssays || 30}
                  height={'48px'}
                  maxW={'120px'}>
                  <option className={'select-days-text'} value={30}>
                    {'30 dias'}
                  </option>
                  <option className={'select-days-text'} value={90}>
                    {'90 dias'}
                  </option>
                  <option className={'select-days-text'} value={180}>
                    {'180 dias'}
                  </option>
                  <option className={'select-days-text'} value={365}>
                    {'365 dias'}
                  </option>
                </Select>
              </div>
            </div>

            <SharedTablePagination
              data={redacoes}
              itemsPerPage={5}
              className={'area-de-redacoes-table'}
              columns={[
                {
                  title: 'Aluno',
                  render: (itemData) => (
                    <div className="atividade-txt-2-area-de-monitores">{<Text>{itemData.student?.name}</Text>}</div>
                  ),
                },
                {
                  title: 'Tema',
                  render: (itemData) => <div className="atividade-txt-2-area-de-monitores">{handleTema(itemData)}</div>,
                },
                {
                  title: 'Status',
                  render: (itemData) => <div>{HandleStatusInput(itemData.status)}</div>,
                },
                {
                  title: 'Data de entrega',
                  render: (itemData) => <div>{handleDataDeEntrega(itemData.dateSubmitted)}</div>,
                },
                {
                  title: 'Detalhes',
                  render: (itemData) => <div>{handleDetalhes(itemData)}</div>,
                },
              ]}
            />
          </Box>
        </VStack>
      </HStack>
    </Box>
  )
}

export default EssayArea
