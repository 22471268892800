import { ActivityType, type NewActivityViewModel } from '@/services/types'
import { create } from 'zustand'
import { formatDate } from '../utils/formatDateBeforeInput'

export enum STEP_ACTIVITY {
  ABOUT = 1,
  CONFIGURATION = 2,
  CREATED = 3,
}

interface CreateActivityStore {
  currentStep: STEP_ACTIVITY
  activityId?: string
  activityData: Partial<NewActivityViewModel>
  setCurrentStep: (step: STEP_ACTIVITY) => void
  setActivityData: (data: Partial<NewActivityViewModel>) => void
  clearActivityData: (activityId: string) => void
}

const useCreateActivityStore = create<CreateActivityStore>((set) => ({
  currentStep: STEP_ACTIVITY.ABOUT,
  activityData: {
    validFrom: formatDate(new Date()),
    activityType: ActivityType.NUMBER_2,
  },
  setCurrentStep: (step) => set({ currentStep: step }),
  setActivityData: (data) => {
    set((state) => ({ activityData: { ...state.activityData, ...data } }))
  },
  clearActivityData: (activityId) => {
    set({ activityId, activityData: {} })
  },
}))

export default useCreateActivityStore
