import { Badge, Box, Center, Stack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { USER_ROLE } from '../../../apis/Constants'
import { Pagination } from '../../../components/Pagination'
import { Table } from '../../../components/Table'
import { NoResults } from '../../../components/Table/no-result'
import { ArrowRightIcon } from '../../../components/icons/ArrowRight'
import { indepententTeacherColumns } from './utils'

export const IndependentTeacherSearch = ({ hits, page, setPage, isLoading, detailsAction }) => {
  const data = useMemo(() => {
    if (hits) {
      return hits.map((hit) => {
        return {
          ...hit,
          role: () => (
            <Center>
              <Badge colorScheme="facebook">{USER_ROLE[hit.userRole]}</Badge>
            </Center>
          ),
          blocked: () => (hit.blocked ? 'Sim' : 'Não'),
          details: () => (
            <Center cursor={'pointer'}>
              <ArrowRightIcon />
            </Center>
          ),
          action: () => detailsAction(hit),
        }
      })
    }
  }, [hits])

  return (
    <Stack spacing={4} width="100%" marginBottom={7}>
      <Box>
        <Table data={data} columns={indepententTeacherColumns} isLoading={isLoading} />
        {!data.length && !isLoading && (
          <NoResults label="Nenhum usuário cadastrado" buttonLabel="Cadastrar novo usuário" buttonPath="/cliente/novo" />
        )}
      </Box>
      <Pagination
        page={page}
        onNext={() => setPage((page) => page + 1)}
        onPrevious={() => setPage((page) => page - 1)}
        limitReached={hits.length < 8}
      />
    </Stack>
  )
}
