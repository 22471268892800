import { Box, HStack } from '@chakra-ui/react'
import { useState } from 'react'
import './SharedTablePagination.css'
import ReactPaginate from 'react-paginate'

export default function SharedTablePagination({ data, itemsPerPage, columns, className, showPagination = true }) {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageClick = ({ selected }) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setCurrentPage(selected)
  }
  const offset = currentPage * itemsPerPage
  const currentPageData = data.slice(offset, offset + itemsPerPage)

  const pageCount = Math.ceil(data.length / itemsPerPage)

  return (
    <Box className={'shared-table-pagination'} zIndex={0}>
      <Box overflowX={'auto'} className={`turmas-2-shared-table-pagination ${className}`}>
        <div className="frame-2582-shared-table-pagination">
          <div className="tabela-atividades-shared-table-pagination">
            {columns.map(({ title, render, size }) => (
              <Box
                key={title}
                maxWidth={size}
                className={`tabela-02-1-shared-table-pagination ${title === 'Detalhes' ? 'detalhes-table-style' : ''}`}>
                <Box className="tabela-title-shared-table-pagination">
                  <div className="turma-shared-table-pagination publicsans-normal-gray-14px">{title}</div>
                </Box>
                <Box className="table-paginated-items">
                  {currentPageData.map((itemData, index) => (
                    <Box width={'100%'} key={index} className="column">
                      {render(itemData)}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </div>
        </div>
      </Box>
      {showPagination && (
        <HStack justifyContent="center" mt={4} width="100%">
          <ReactPaginate
            pageCount={pageCount}
            nextLabel={'>'}
            nextClassName={'shared-table-arrow-style'}
            previousClassName={'shared-table-arrow-style'}
            pageClassName={'shared-table-text-style'}
            activeClassName={'shared-table-active-style'}
            previousLabel={'<'}
            className={'pagination-style-new'}
            renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        </HStack>
      )}
    </Box>
  )
}
