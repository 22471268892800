import { Box, Icon } from '@chakra-ui/react'

const addIconStyles = {
  position: 'absolute',
  left: '21px',
  top: 0,
}

export const AddImageIcon = (props) => (
  <Box position="relative">
    <Icon xmlns="http://www.w3.org/2000/svg" style={addIconStyles} width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M8.5 0.5C4.084 0.5 0.5 4.084 0.5 8.5C0.5 12.916 4.084 16.5 8.5 16.5C12.916 16.5 16.5 12.916 16.5 8.5C16.5 4.084 12.916 0.5 8.5 0.5ZM12.5 9.3H9.3V12.5H7.7V9.3H4.5V7.7H7.7V4.5H9.3V7.7H12.5V9.3Z"
        fill="#0A41D6"
      />
    </Icon>
    <Icon xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none" {...props}>
      <path
        d="M29.0309 5.31299H13.8952C8.61898 5.31299 5.31323 9.04749 5.31323 14.3325V28.5932C5.31323 33.8782 8.60498 37.6127 13.8952 37.6127H29.0292C34.3212 37.6127 37.6129 33.8782 37.6129 28.5932V14.3325C37.6129 9.04749 34.3212 5.31299 29.0309 5.31299Z"
        stroke="#0A41D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2308 15.8745C19.2308 17.6578 17.7836 19.105 16.0004 19.105C14.2189 19.105 12.7716 17.6578 12.7716 15.8745C12.7716 14.0913 14.2189 12.644 16.0004 12.644C17.7836 12.644 19.2308 14.0913 19.2308 15.8745Z"
        stroke="#0A41D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.612 26.6636C35.8725 25.1498 34.3587 23.3281 32.3637 22.1258C30.3652 20.9236 28.218 21.6656 26.832 23.4593C25.4932 25.1936 24.6567 27.5263 22.6355 28.6201C20.1452 29.9693 18.6822 27.7941 16.6049 26.9226C14.2861 25.9513 12.5256 27.6978 11.1711 29.3761C9.81488 31.0561 8.44113 32.7186 7.06213 34.3811"
        stroke="#0A41D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  </Box>
)
