import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'brand.100',
  },
  sizes: {
    md: () => ({
      fontWeight: 400,
      lineHeight: '31.2px',
      fontSize: '24px',
      px: '0',
    }),
  },
}
