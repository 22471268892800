/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';
import type { EssayCompetencyViewModel } from './essayCompetencyViewModel';

export interface CompleteEssayViewModel {
  activity?: KeyValueViewModel;
  /** @nullable */
  attachment?: string | null;
  /** @nullable */
  competencies?: EssayCompetencyViewModel[] | null;
  createdAt?: string;
  /** @nullable */
  dateSubmitted?: string | null;
  deadline?: string;
  /** @nullable */
  draft?: string | null;
  finalGrade?: number;
  group?: KeyValueViewModel;
  id?: string;
  institution?: KeyValueViewModel;
  status?: number;
  teacher?: KeyValueViewModel;
  theme?: KeyValueViewModel;
  updatedAt?: string;
}
