import { usePostApiV1SchoolSchoolIdGroup } from '@/services/school'
import { shiftMapping } from '@/utils/shiftENUM'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import useCreateGroupStore from '../../store/useCreateGroupStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'

const validationSchema = yup
  .object({
    name: yup.string().required().min(6).max(50),
    shift: yup.string().required().default('0'),
    description: yup.string().max(200).nullable().default(null),
  })
  .required()

interface FormValues {
  name: string
  shift: string
  description: string | null
}

interface CreateGroupStep1Props {
  handlingNextStep: () => void
}

function CreateGroupStep1({ handlingNextStep }: CreateGroupStep1Props) {
  const maxCharacteres = 300
  const { setGroupIdStep } = useCreateGroupStore()
  const { loggedUser } = useLoggedUserStore()

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  })

  const { mutate: postApiGroup, isPending } = usePostApiV1SchoolSchoolIdGroup({
    mutation: {
      onSuccess: (response) => {
        // @ts-ignore
        const groupId = response?.data?.message
        if (!groupId) {
          return toast.error('Erro ao carregar o ID da turma')
        }
        setGroupIdStep(groupId)
        handlingNextStep()
      },
    },
  })

  const submit: SubmitHandler<FormValues> = (body) => {
    const requestBody = {
      schoolId: loggedUser.schoolId,
      data: {
        ...body,
        shift: Number(body.shift),
      },
    }
    postApiGroup(requestBody)
  }

  return (
    <VStack h={'100%'} w={'100%'}>
      <Headbar returnTo={'/area-de-turmas'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar turma
        </Heading>
        <Box w={'100%'} mt={'12px'}>
          <StepsImage text={'Sobre a turma'} number={1} />
          <form style={{ width: '100%' }} onSubmit={handleSubmit(submit)} noValidate>
            <Grid width={'100%'} gap={'32px'} mt={{ base: '12px', md: '32px' }}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>{'Nome*'}</FormLabel>
                  <Input
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    borderColor={'brand.300'}
                    disabled={isSubmitting}
                    {...register('name')}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={!!errors.shift}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Selecione o turno da turma*</FormLabel>
                  <Select
                    placeholder={'Selecionar Turno da turma'}
                    {...register('shift')}
                    className={'input-style'}
                    isDisabled={isSubmitting}
                    defaultValue={'0'}
                    w={'100%'}>
                    {Object.entries(shiftMapping).map(([value, label], key) => (
                      <option className={'select-tema-text'} key={key} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.shift?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={!!errors.description}>
                  <FormLabel className="publicsans-bold-nevada-16px">{'Mensagem personalizada'}</FormLabel>
                  <Textarea
                    placeholder=""
                    {...register('description')}
                    resize="vertical"
                    w={'100%'}
                    h={'100%'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                  />
                  <Text fontSize="sm" position="absolute" bottom="5px" right="20px" color="brandGray.100" zIndex={2}>
                    {watch('description')?.length ?? 0}/{maxCharacteres}
                  </Text>
                  <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Box display="flex" justifyContent="end" w={'100%'} gap={3} my={6}>
              <Button flex={0.5} type="submit" variant="primary" isLoading={isPending}>
                Finalizar
              </Button>
            </Box>
          </form>
        </Box>
      </VStack>
    </VStack>
  )
}

export default CreateGroupStep1
