import { validateCNPJ, validateCPF, validateCep, validatePhone } from 'validations-br'
import * as yup from 'yup'
import { ptShort } from 'yup-locale-pt'

declare module 'yup' {
  interface StringSchema {
    phone(message?: string): StringSchema
    cnpj(message?: string): StringSchema
    cpf(message?: string): StringSchema
    zipCode(message?: string): StringSchema
  }
}

const addYupMethod = (schema, name, validateFn, defaultMessage) => {
  yup.addMethod<yup.StringSchema>(schema, name, function (message) {
    return this.test(name, message || defaultMessage, function (value) {
      const { path, createError } = this
      return validateFn(value) || createError({ path, message: message || defaultMessage })
    })
  })
}

addYupMethod(yup.string, 'cnpj', validateCNPJ, 'CNPJ inválido')
addYupMethod(yup.string, 'cpf', validateCPF, 'CPF inválido')
addYupMethod(yup.string, 'phone', validatePhone, 'Telefone inválido')
addYupMethod(yup.string, 'zipCode', validateCep, 'CEP inválido')

yup.setLocale(ptShort)
