import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllInstitutionsFromSchool = async (schoolId) => {
  const schoolID = schoolId || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolID}/institution`)

  return response.data.message
}

export const createInstitutionApi = async (schoolId, data) => {
  const schoolID = schoolId || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/school/${schoolID}/institution`, data)

  return response.data.message
}

export const getInstitutionApi = async (schoolIdParam, institutionId) => {
  const schoolId = schoolIdParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolId}/institution/${institutionId}`)

  return response.data.message
}

export const deleteInstitutionApi = async (institutionId, schoolIdParam) => {
  const schoolID = schoolIdParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/school/${schoolID}/institution/${institutionId}`)

  return response.data.message
}

export const putInstitutionApi = async (data, schoolIdParam) => {
  const schoolID = schoolIdParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school/${schoolID}/institution`, data)

  return response.data.message
}
