import type { NewThemeViewModel } from '@/services/types'
import { create } from 'zustand'

export enum STEP_THEME {
  ABOUT = 1,
  CREATED = 2,
}

interface CreateThemeStore {
  currentStep: STEP_THEME
  themeId?: string
  themeData: Partial<NewThemeViewModel>
  setCurrentStep: (step: STEP_THEME) => void
  setThemeData: (data: Partial<NewThemeViewModel>) => void
  clearThemeData: (themeId: string) => void
}

const useCreateThemeStore = create<CreateThemeStore>((set) => ({
  currentStep: 1,
  themeId: '',
  themeData: {},
  setCurrentStep: (step) => set({ currentStep: step }),
  setThemeData: (data) =>
    set((state) => ({
      themeData: { ...state.themeData, ...data },
    })),
  clearThemeData: (themeId) =>
    set({
      themeData: {},
      themeId,
      currentStep: 1,
    }),
}))

export default useCreateThemeStore
