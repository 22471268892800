/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AssistantPromptViewModelListResultViewModel,
  AssistantReactionsViewModelListResultViewModel,
  AssistantTipViewModelResultViewModel,
  AssistantUsageBasicInfoViewModelListResultViewModel,
  AssistantUsageContentViewModel,
  AssistantUsageContentViewModelListResultViewModel,
  AssistantUsageReactionViewModel,
  ExperimentThemeAssistantPromptResponseViewModel,
  ExperimentThemeAssistantPromptViewModel,
  GetApiV1AssistantAssistantAnalysisParams,
  GetApiV1AssistantListAssistantUsagesEssayParams,
  GetApiV1AssistantListAssistantUsagesSchoolParams,
  GetApiV1AssistantListAssistantUsagesSchoolTeacherParams,
  NewAssistantPromptViewModel,
  NewAssistantUsageViewModel,
  NewThemeAssistantPromptUsageViewModel,
  NewThemeAssistantPromptViewModel,
  ProblemDetails,
  StringListResultViewModel,
  ThemeAssistantPromptReactionViewModel,
  ThemeAssistantPromptUsageResponseViewModel,
  ThemeAssistantPromptViewModelIEnumerableResultViewModel,
  UpdateAssistantPromptViewModel,
  UpdateThemeAssistantPromptViewModel
} from './types'




/**
 * @summary Adds a new Assistant prompt.
 */
export const postApiV1AssistantAssistantPrompt = (
    newAssistantPromptViewModel: NewAssistantPromptViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/assistant/assistant-prompt`,
      newAssistantPromptViewModel,options
    );
  }



export const getPostApiV1AssistantAssistantPromptMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>, TError,{data: NewAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>, TError,{data: NewAssistantPromptViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>, {data: NewAssistantPromptViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantAssistantPrompt(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantAssistantPromptMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>>
    export type PostApiV1AssistantAssistantPromptMutationBody = NewAssistantPromptViewModel
    export type PostApiV1AssistantAssistantPromptMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new Assistant prompt.
 */
export const usePostApiV1AssistantAssistantPrompt = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>, TError,{data: NewAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantAssistantPrompt>>,
        TError,
        {data: NewAssistantPromptViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantAssistantPromptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic data from Assistant prompt
 */
export const putApiV1AssistantAssistantPrompt = (
    updateAssistantPromptViewModel: UpdateAssistantPromptViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/assistant/assistant-prompt`,
      updateAssistantPromptViewModel,options
    );
  }



export const getPutApiV1AssistantAssistantPromptMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>, TError,{data: UpdateAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>, TError,{data: UpdateAssistantPromptViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>, {data: UpdateAssistantPromptViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1AssistantAssistantPrompt(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AssistantAssistantPromptMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>>
    export type PutApiV1AssistantAssistantPromptMutationBody = UpdateAssistantPromptViewModel
    export type PutApiV1AssistantAssistantPromptMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic data from Assistant prompt
 */
export const usePutApiV1AssistantAssistantPrompt = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>, TError,{data: UpdateAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1AssistantAssistantPrompt>>,
        TError,
        {data: UpdateAssistantPromptViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1AssistantAssistantPromptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteApiV1AssistantAssistantPromptId = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/assistant/assistant-prompt/${id}`,options
    );
  }



export const getDeleteApiV1AssistantAssistantPromptIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV1AssistantAssistantPromptId(id,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AssistantAssistantPromptIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>>
    
    export type DeleteApiV1AssistantAssistantPromptIdMutationError = AxiosError<ProblemDetails | void>

    export const useDeleteApiV1AssistantAssistantPromptId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1AssistantAssistantPromptId>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1AssistantAssistantPromptIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Adds a new Assistant prompt and already returns with the response
 */
export const postApiV1AssistantAssistantUsage = (
    newAssistantUsageViewModel: NewAssistantUsageViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantUsageContentViewModel>> => {
    
    return axios.post(
      `/api/v1/assistant/assistant-usage`,
      newAssistantUsageViewModel,options
    );
  }



export const getPostApiV1AssistantAssistantUsageMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>, TError,{data: NewAssistantUsageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>, TError,{data: NewAssistantUsageViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>, {data: NewAssistantUsageViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantAssistantUsage(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantAssistantUsageMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>>
    export type PostApiV1AssistantAssistantUsageMutationBody = NewAssistantUsageViewModel
    export type PostApiV1AssistantAssistantUsageMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new Assistant prompt and already returns with the response
 */
export const usePostApiV1AssistantAssistantUsage = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>, TError,{data: NewAssistantUsageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantAssistantUsage>>,
        TError,
        {data: NewAssistantUsageViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantAssistantUsageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Reacts to a given Assistant Usage
 */
export const postApiV1AssistantAssistantUsageReact = (
    assistantUsageReactionViewModel: AssistantUsageReactionViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/assistant/assistant-usage/react`,
      assistantUsageReactionViewModel,options
    );
  }



export const getPostApiV1AssistantAssistantUsageReactMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>, TError,{data: AssistantUsageReactionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>, TError,{data: AssistantUsageReactionViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>, {data: AssistantUsageReactionViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantAssistantUsageReact(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantAssistantUsageReactMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>>
    export type PostApiV1AssistantAssistantUsageReactMutationBody = AssistantUsageReactionViewModel
    export type PostApiV1AssistantAssistantUsageReactMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Reacts to a given Assistant Usage
 */
export const usePostApiV1AssistantAssistantUsageReact = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>, TError,{data: AssistantUsageReactionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantAssistantUsageReact>>,
        TError,
        {data: AssistantUsageReactionViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantAssistantUsageReactMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all available prompts from the system
 */
export const getApiV1AssistantListAssistantPrompts = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantPromptViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-prompts`,options
    );
  }


export const getGetApiV1AssistantListAssistantPromptsQueryKey = () => {
    return [`/api/v1/assistant/list-assistant-prompts`] as const;
    }

    
export const getGetApiV1AssistantListAssistantPromptsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantPromptsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>> = ({ signal }) => getApiV1AssistantListAssistantPrompts({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantPromptsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>>
export type GetApiV1AssistantListAssistantPromptsQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantPrompts<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantPrompts<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantPrompts<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List all available prompts from the system
 */

export function useGetApiV1AssistantListAssistantPrompts<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPrompts>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantPromptsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List possible metadata that can be used on a prompt generation
 */
export const getApiV1AssistantListAssistantPromptMetadata = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StringListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-prompt-metadata`,options
    );
  }


export const getGetApiV1AssistantListAssistantPromptMetadataQueryKey = () => {
    return [`/api/v1/assistant/list-assistant-prompt-metadata`] as const;
    }

    
export const getGetApiV1AssistantListAssistantPromptMetadataQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantPromptMetadataQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>> = ({ signal }) => getApiV1AssistantListAssistantPromptMetadata({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantPromptMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>>
export type GetApiV1AssistantListAssistantPromptMetadataQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantPromptMetadata<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantPromptMetadata<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantPromptMetadata<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List possible metadata that can be used on a prompt generation
 */

export function useGetApiV1AssistantListAssistantPromptMetadata<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantPromptMetadata>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantPromptMetadataQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List Assistant usages from a given school
 */
export const getApiV1AssistantListAssistantUsagesSchool = (
    params?: GetApiV1AssistantListAssistantUsagesSchoolParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantUsageBasicInfoViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-usages-school`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AssistantListAssistantUsagesSchoolQueryKey = (params?: GetApiV1AssistantListAssistantUsagesSchoolParams,) => {
    return [`/api/v1/assistant/list-assistant-usages-school`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1AssistantListAssistantUsagesSchoolQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1AssistantListAssistantUsagesSchoolParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantUsagesSchoolQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>> = ({ signal }) => getApiV1AssistantListAssistantUsagesSchool(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantUsagesSchoolQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>>
export type GetApiV1AssistantListAssistantUsagesSchoolQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantUsagesSchool<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1AssistantListAssistantUsagesSchoolParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesSchool<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesSchool<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Assistant usages from a given school
 */

export function useGetApiV1AssistantListAssistantUsagesSchool<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchool>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantUsagesSchoolQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List Assistant usages from a given teacher for an school
 */
export const getApiV1AssistantListAssistantUsagesSchoolTeacher = (
    params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantUsageBasicInfoViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-usages-school-teacher`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AssistantListAssistantUsagesSchoolTeacherQueryKey = (params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams,) => {
    return [`/api/v1/assistant/list-assistant-usages-school-teacher`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1AssistantListAssistantUsagesSchoolTeacherQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantUsagesSchoolTeacherQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>> = ({ signal }) => getApiV1AssistantListAssistantUsagesSchoolTeacher(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantUsagesSchoolTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>>
export type GetApiV1AssistantListAssistantUsagesSchoolTeacherQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantUsagesSchoolTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesSchoolTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesSchoolTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Assistant usages from a given teacher for an school
 */

export function useGetApiV1AssistantListAssistantUsagesSchoolTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesSchoolTeacherParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesSchoolTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantUsagesSchoolTeacherQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary List Assistant usages from a given logged teacher regardless of school
 */
export const getApiV1AssistantListAssistantUsagesTeacher = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantUsageBasicInfoViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-usages-teacher`,options
    );
  }


export const getGetApiV1AssistantListAssistantUsagesTeacherQueryKey = () => {
    return [`/api/v1/assistant/list-assistant-usages-teacher`] as const;
    }

    
export const getGetApiV1AssistantListAssistantUsagesTeacherQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantUsagesTeacherQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>> = ({ signal }) => getApiV1AssistantListAssistantUsagesTeacher({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantUsagesTeacherQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>>
export type GetApiV1AssistantListAssistantUsagesTeacherQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantUsagesTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary List Assistant usages from a given logged teacher regardless of school
 */

export function useGetApiV1AssistantListAssistantUsagesTeacher<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesTeacher>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantUsagesTeacherQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get the assistant usages from a given essay
 */
export const getApiV1AssistantListAssistantUsagesEssay = (
    params?: GetApiV1AssistantListAssistantUsagesEssayParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantUsageContentViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/list-assistant-usages-essay`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AssistantListAssistantUsagesEssayQueryKey = (params?: GetApiV1AssistantListAssistantUsagesEssayParams,) => {
    return [`/api/v1/assistant/list-assistant-usages-essay`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1AssistantListAssistantUsagesEssayQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1AssistantListAssistantUsagesEssayParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantListAssistantUsagesEssayQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>> = ({ signal }) => getApiV1AssistantListAssistantUsagesEssay(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantListAssistantUsagesEssayQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>>
export type GetApiV1AssistantListAssistantUsagesEssayQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantListAssistantUsagesEssay<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1AssistantListAssistantUsagesEssayParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesEssay<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesEssayParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantListAssistantUsagesEssay<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesEssayParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get the assistant usages from a given essay
 */

export function useGetApiV1AssistantListAssistantUsagesEssay<TData = Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantListAssistantUsagesEssayParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantListAssistantUsagesEssay>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantListAssistantUsagesEssayQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new theme assistant prompt
 */
export const postApiV1AssistantThemeAssistant = (
    newThemeAssistantPromptViewModel: NewThemeAssistantPromptViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/assistant/theme-assistant`,
      newThemeAssistantPromptViewModel,options
    );
  }



export const getPostApiV1AssistantThemeAssistantMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>, TError,{data: NewThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>, TError,{data: NewThemeAssistantPromptViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>, {data: NewThemeAssistantPromptViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantThemeAssistant(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantThemeAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>>
    export type PostApiV1AssistantThemeAssistantMutationBody = NewThemeAssistantPromptViewModel
    export type PostApiV1AssistantThemeAssistantMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new theme assistant prompt
 */
export const usePostApiV1AssistantThemeAssistant = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>, TError,{data: NewThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantThemeAssistant>>,
        TError,
        {data: NewThemeAssistantPromptViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantThemeAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates a given theme assistant prompt
 */
export const putApiV1AssistantThemeAssistant = (
    updateThemeAssistantPromptViewModel: UpdateThemeAssistantPromptViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/assistant/theme-assistant`,
      updateThemeAssistantPromptViewModel,options
    );
  }



export const getPutApiV1AssistantThemeAssistantMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>, TError,{data: UpdateThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>, TError,{data: UpdateThemeAssistantPromptViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>, {data: UpdateThemeAssistantPromptViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1AssistantThemeAssistant(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AssistantThemeAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>>
    export type PutApiV1AssistantThemeAssistantMutationBody = UpdateThemeAssistantPromptViewModel
    export type PutApiV1AssistantThemeAssistantMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates a given theme assistant prompt
 */
export const usePutApiV1AssistantThemeAssistant = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>, TError,{data: UpdateThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1AssistantThemeAssistant>>,
        TError,
        {data: UpdateThemeAssistantPromptViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1AssistantThemeAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists all theme assistant prompts
 */
export const getApiV1AssistantThemeAssistant = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ThemeAssistantPromptViewModelIEnumerableResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/theme-assistant`,options
    );
  }


export const getGetApiV1AssistantThemeAssistantQueryKey = () => {
    return [`/api/v1/assistant/theme-assistant`] as const;
    }

    
export const getGetApiV1AssistantThemeAssistantQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantThemeAssistantQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>> = ({ signal }) => getApiV1AssistantThemeAssistant({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantThemeAssistantQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>>
export type GetApiV1AssistantThemeAssistantQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantThemeAssistant<TData = Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantThemeAssistant<TData = Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantThemeAssistant<TData = Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists all theme assistant prompts
 */

export function useGetApiV1AssistantThemeAssistant<TData = Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantThemeAssistant>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantThemeAssistantQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates the given theme assistant prompt to be the default
 */
export const putApiV1AssistantThemeAssistantThemeAssistantIdDefault = (
    themeAssistantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/assistant/theme-assistant/${themeAssistantId}/default`,undefined,options
    );
  }



export const getPutApiV1AssistantThemeAssistantThemeAssistantIdDefaultMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>, TError,{themeAssistantId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>, TError,{themeAssistantId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>, {themeAssistantId: string}> = (props) => {
          const {themeAssistantId} = props ?? {};

          return  putApiV1AssistantThemeAssistantThemeAssistantIdDefault(themeAssistantId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AssistantThemeAssistantThemeAssistantIdDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>>
    
    export type PutApiV1AssistantThemeAssistantThemeAssistantIdDefaultMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates the given theme assistant prompt to be the default
 */
export const usePutApiV1AssistantThemeAssistantThemeAssistantIdDefault = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>, TError,{themeAssistantId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1AssistantThemeAssistantThemeAssistantIdDefault>>,
        TError,
        {themeAssistantId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1AssistantThemeAssistantThemeAssistantIdDefaultMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates the given assistant prompt to be the default
 */
export const putApiV1AssistantAssistantAssistantIdDefault = (
    assistantId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/assistant/assistant/${assistantId}/default`,undefined,options
    );
  }



export const getPutApiV1AssistantAssistantAssistantIdDefaultMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>, TError,{assistantId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>, TError,{assistantId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>, {assistantId: string}> = (props) => {
          const {assistantId} = props ?? {};

          return  putApiV1AssistantAssistantAssistantIdDefault(assistantId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AssistantAssistantAssistantIdDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>>
    
    export type PutApiV1AssistantAssistantAssistantIdDefaultMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates the given assistant prompt to be the default
 */
export const usePutApiV1AssistantAssistantAssistantIdDefault = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>, TError,{assistantId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1AssistantAssistantAssistantIdDefault>>,
        TError,
        {assistantId: string},
        TContext
      > => {

      const mutationOptions = getPutApiV1AssistantAssistantAssistantIdDefaultMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Sends a request to create a new theme prompt usage and gets the result back
 */
export const postApiV1AssistantThemeAssistantUsage = (
    newThemeAssistantPromptUsageViewModel: NewThemeAssistantPromptUsageViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ThemeAssistantPromptUsageResponseViewModel>> => {
    
    return axios.post(
      `/api/v1/assistant/theme-assistant-usage`,
      newThemeAssistantPromptUsageViewModel,options
    );
  }



export const getPostApiV1AssistantThemeAssistantUsageMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>, TError,{data: NewThemeAssistantPromptUsageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>, TError,{data: NewThemeAssistantPromptUsageViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>, {data: NewThemeAssistantPromptUsageViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantThemeAssistantUsage(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantThemeAssistantUsageMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>>
    export type PostApiV1AssistantThemeAssistantUsageMutationBody = NewThemeAssistantPromptUsageViewModel
    export type PostApiV1AssistantThemeAssistantUsageMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Sends a request to create a new theme prompt usage and gets the result back
 */
export const usePostApiV1AssistantThemeAssistantUsage = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>, TError,{data: NewThemeAssistantPromptUsageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsage>>,
        TError,
        {data: NewThemeAssistantPromptUsageViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantThemeAssistantUsageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postApiV1AssistantThemeAssistantUsageReact = (
    themeAssistantPromptReactionViewModel: ThemeAssistantPromptReactionViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/assistant/theme-assistant-usage/react`,
      themeAssistantPromptReactionViewModel,options
    );
  }



export const getPostApiV1AssistantThemeAssistantUsageReactMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>, TError,{data: ThemeAssistantPromptReactionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>, TError,{data: ThemeAssistantPromptReactionViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>, {data: ThemeAssistantPromptReactionViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantThemeAssistantUsageReact(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantThemeAssistantUsageReactMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>>
    export type PostApiV1AssistantThemeAssistantUsageReactMutationBody = ThemeAssistantPromptReactionViewModel
    export type PostApiV1AssistantThemeAssistantUsageReactMutationError = AxiosError<ProblemDetails | void>

    export const usePostApiV1AssistantThemeAssistantUsageReact = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>, TError,{data: ThemeAssistantPromptReactionViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantThemeAssistantUsageReact>>,
        TError,
        {data: ThemeAssistantPromptReactionViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantThemeAssistantUsageReactMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postApiV1AssistantExperimentPrompt = (
    experimentThemeAssistantPromptViewModel: ExperimentThemeAssistantPromptViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ExperimentThemeAssistantPromptResponseViewModel>> => {
    
    return axios.post(
      `/api/v1/assistant/experiment-prompt`,
      experimentThemeAssistantPromptViewModel,options
    );
  }



export const getPostApiV1AssistantExperimentPromptMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>, TError,{data: ExperimentThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>, TError,{data: ExperimentThemeAssistantPromptViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>, {data: ExperimentThemeAssistantPromptViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AssistantExperimentPrompt(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AssistantExperimentPromptMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>>
    export type PostApiV1AssistantExperimentPromptMutationBody = ExperimentThemeAssistantPromptViewModel
    export type PostApiV1AssistantExperimentPromptMutationError = AxiosError<ProblemDetails | void>

    export const usePostApiV1AssistantExperimentPrompt = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>, TError,{data: ExperimentThemeAssistantPromptViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1AssistantExperimentPrompt>>,
        TError,
        {data: ExperimentThemeAssistantPromptViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1AssistantExperimentPromptMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get a random tip for a student, teacher, monitor or independent teacher
 */
export const getApiV1AssistantAssistantTip = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantTipViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/assistant-tip`,options
    );
  }


export const getGetApiV1AssistantAssistantTipQueryKey = () => {
    return [`/api/v1/assistant/assistant-tip`] as const;
    }

    
export const getGetApiV1AssistantAssistantTipQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError = AxiosError<ProblemDetails | void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantAssistantTipQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>> = ({ signal }) => getApiV1AssistantAssistantTip({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantAssistantTipQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>>
export type GetApiV1AssistantAssistantTipQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantAssistantTip<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError = AxiosError<ProblemDetails | void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantAssistantTip<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantAssistantTip<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get a random tip for a student, teacher, monitor or independent teacher
 */

export function useGetApiV1AssistantAssistantTip<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError = AxiosError<ProblemDetails | void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantTip>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantAssistantTipQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiV1AssistantAssistantAnalysis = (
    params?: GetApiV1AssistantAssistantAnalysisParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AssistantReactionsViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/assistant/assistant-analysis`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetApiV1AssistantAssistantAnalysisQueryKey = (params?: GetApiV1AssistantAssistantAnalysisParams,) => {
    return [`/api/v1/assistant/assistant-analysis`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV1AssistantAssistantAnalysisQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError = AxiosError<ProblemDetails | void>>(params?: GetApiV1AssistantAssistantAnalysisParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AssistantAssistantAnalysisQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>> = ({ signal }) => getApiV1AssistantAssistantAnalysis(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV1AssistantAssistantAnalysisQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>>
export type GetApiV1AssistantAssistantAnalysisQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1AssistantAssistantAnalysis<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError = AxiosError<ProblemDetails | void>>(
 params: undefined |  GetApiV1AssistantAssistantAnalysisParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantAssistantAnalysis<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantAssistantAnalysisParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetApiV1AssistantAssistantAnalysis<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantAssistantAnalysisParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetApiV1AssistantAssistantAnalysis<TData = Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError = AxiosError<ProblemDetails | void>>(
 params?: GetApiV1AssistantAssistantAnalysisParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AssistantAssistantAnalysis>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV1AssistantAssistantAnalysisQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



