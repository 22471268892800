export const phoneNumberFormatter = (rawPhonenNumber) => {
  const cleanedValue = rawPhonenNumber.replace(/\D/g, '') // Remove non-digit characters
  const truncatedValue = cleanedValue.slice(0, 11) // Truncate to 11 characters
  const formattedValue = formatTelefone(truncatedValue)

  return formattedValue
}

const formatTelefone = (value) => {
  // Remove all non-digit characters from the input value
  const cleanedValue = value.replace(/\D/g, '')

  // Format the value as (XX) XXXX-XXXX
  const formattedValue = cleanedValue.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3')

  return formattedValue
}
