import type { CompetencyViewModel, EssayCompetencyViewModel } from '@/services/types'

export const gradeColors = {
  undefined: 'red',
  0: 'red',
  40: '#f5e982',
  80: '#d7db95',
  120: '#92e8ce',
  160: '#63dbb7',
  200: '#02E39F',
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const toListOfEssayCompetencies = (institutionCompentences: CompetencyViewModel[]): EssayCompetencyViewModel[] => {
  return institutionCompentences.map((competency) => ({
    grade: 0,
    ...competency,
  }))
}
