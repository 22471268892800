/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export type AssistantUsageReaction = typeof AssistantUsageReaction[keyof typeof AssistantUsageReaction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssistantUsageReaction = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;
