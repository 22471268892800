export function formatDate(date: string, locale = 'pt-BR'): string {
  return new Date(date).toLocaleDateString(locale)
}

export function formatTime(date: string, locale = 'pt-BR'): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  }
  return new Date(date).toLocaleTimeString(locale, options)
}
