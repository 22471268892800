import { Icon } from '@chakra-ui/react'

export const PencilIcon = () => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="none" height="49" viewBox="0 0 48 49" fill="none">
      <rect
        x="1.5"
        y="1.60156"
        width="45"
        height="45"
        rx="22.5"
        fill="url(#paint0_linear_542_6469)"
        fillOpacity="0.5"
        stroke="#21FDBB"
        strokeWidth="3"
      />
      <path
        d="M29.4368 10.9187L31.8617 12.144C33.742 13.0942 34.496 15.3887 33.5458 17.269L25.9338 32.3325L21.8755 34.6287L19.8463 35.7768L18.8132 36.3614C18.3393 36.6294 17.7453 36.3292 17.68 35.7888L17.5378 34.6102L17.2585 32.2956L16.6998 27.6663L24.3118 12.6028C25.262 10.7225 27.5565 9.96851 29.4368 10.9187Z"
        fill="#21FDBB"
      />
      <path
        d="M25.9338 32.3325L33.5458 17.269C34.496 15.3887 33.742 13.0942 31.8617 12.144L29.4368 10.9187C27.5565 9.96851 25.262 10.7225 24.3118 12.6028L16.6998 27.6663M25.9338 32.3325L16.6998 27.6663M25.9338 32.3325L21.8755 34.6287M16.6998 27.6663L17.2585 32.2956M21.8755 34.6287L17.2585 32.2956M21.8755 34.6287L19.8463 35.7768L18.8132 36.3614C18.3393 36.6294 17.7453 36.3292 17.68 35.7888L17.5378 34.6102L17.2585 32.2956"
        stroke="#295FF5"
        strokeWidth="0.762919"
        strokeLinecap="round"
      />
      <path
        d="M27.1741 12.6733L19.0082 28.8329M29.4826 13.8399L21.3167 29.9994M31.7911 15.0064L23.6252 31.166"
        stroke="#295FF5"
        strokeWidth="0.762919"
        strokeLinecap="round"
      />
      <path
        d="M21.8756 34.6285L17.2585 32.2954L17.6801 35.7886C17.7453 36.3291 18.3394 36.6293 18.8132 36.3612L21.8756 34.6285Z"
        fill="#295FF5"
        stroke="#295FF5"
        strokeWidth="0.762919"
        strokeLinecap="round"
      />
      <path
        d="M33.5164 17.3272L24.2824 12.661C25.2488 10.7486 27.5826 9.98167 29.495 10.9481L31.8035 12.1146C33.7159 13.081 34.4828 15.4148 33.5164 17.3272Z"
        fill="#295FF5"
        stroke="#295FF5"
        strokeWidth="0.762919"
        strokeLinecap="round"
      />
      <mask id="mask0_542_6469" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="42" height="43">
        <path
          d="M3 24.1016C3 12.5036 12.402 3.10156 24 3.10156C35.598 3.10156 45 12.5036 45 24.1016C45 35.6995 35.598 45.1016 24 45.1016C12.402 45.1016 3 35.6995 3 24.1016Z"
          fill="#EDEDF1"
        />
      </mask>
      <g mask="url(#mask0_542_6469)" />
      <defs>
        <linearGradient
          id="paint0_linear_542_6469"
          x1="-33"
          y1="130.823"
          x2="-72.6055"
          y2="83.3824"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A41D6" stopOpacity="0.8" />
          <stop offset="0.571265" stopColor="#A3B7EC" stopOpacity="0.32" />
          <stop offset="1" stopColor="#648BF7" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </Icon>
  )
}
