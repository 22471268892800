import { Modal } from '@/components/modal'
import {
  putApiV1EssayEssayIdCloseReview,
  putApiV1EssayEssayIdFinishReview,
  usePutApiV1EssayReturnEssayToDraft,
} from '@/services/essay'
import type { CompleteEssayViewModel } from '@/services/types'
import useNavigationStoreHistory from '@/store/useNavigationStoreHistory.ts'
import { useBackNavigation } from '@/utils/useBackNavigation.ts'
import { Box, Button, Input, Portal, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { IoIosSend } from 'react-icons/io'
import { IoMdCheckmark } from 'react-icons/io'
import { MdOutlineReplay } from 'react-icons/md'
import { toast } from 'react-toastify'

const essayIDErrorMessage = 'URL faltando ID da dissertação: essayId'

const inputStyle = {
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #d7e3fb',
  padding: '25px 20px',
}

type EssayActionsProps = {
  essay: CompleteEssayViewModel
  refetchEssay: () => Promise<number | string | undefined>
}

export const EssayActions = (props: EssayActionsProps) => {
  const { essay, refetchEssay } = props
  const goBack = useNavigationStoreHistory((state) => state.goBack)
  const handleBackNavigation = useBackNavigation()

  const [actionLoading, setActionLoading] = useState(false)
  const { isOpen: returnReviewIsOpen, onOpen: returnReviewOnOpen, onClose: returnReviewOnClose } = useDisclosure()
  const { isOpen: finishReviewIsOpen, onOpen: finishReviewOnOpen, onClose: finishReviewOnClose } = useDisclosure()
  const { isOpen: closeReviewIsOpen, onOpen: closeReviewOnOpen, onClose: closeReviewOnClose } = useDisclosure()

  const handleReturnSuccess = async () => {
    handleBackNavigation()
    goBack()
  }
  const [returnReason, setReturnReason] = useState<string>()
  const returnEssay = usePutApiV1EssayReturnEssayToDraft({
    mutation: { onSuccess: handleReturnSuccess },
  })

  async function sendReview() {
    if (!essay.id) return toast.error(essayIDErrorMessage)

    try {
      setActionLoading(true)
      await putApiV1EssayEssayIdFinishReview(essay.id)
      await refetchEssay()
      finishReviewOnClose()
    } catch (error) {
      console.error(error)
    } finally {
      setActionLoading(false)
    }
  }

  async function closeReview() {
    if (!essay.id) return toast.error(essayIDErrorMessage)

    try {
      setActionLoading(true)
      await putApiV1EssayEssayIdCloseReview(essay.id)
      await refetchEssay()
      closeReviewOnClose()
    } catch (error) {
      console.error(error)
    } finally {
      setActionLoading(false)
    }
  }
  return (
    <>
      {essay.status === 20 && (
        <VStack width="100%">
          <Button
            width="100%"
            size="sm"
            leftIcon={<MdOutlineReplay />}
            variant="outline"
            onClick={returnReviewOnOpen}
            padding={5}>
            Devolver
          </Button>
          <Button width="100%" variant="primary" leftIcon={<IoIosSend />} onClick={finishReviewOnOpen} padding={6}>
            Enviar correção
          </Button>
        </VStack>
      )}
      {essay.status === 30 && (
        <Button width="100%" variant="primary" leftIcon={<IoMdCheckmark />} onClick={closeReviewOnOpen} padding={6}>
          Finalizar
        </Button>
      )}
      <Portal>
        <Modal
          title={'Devolver redação?'}
          isOpen={returnReviewIsOpen}
          onClose={returnReviewOnClose}
          submitLabel={'Devolver'}
          isLoading={returnEssay.isPending}
          onSubmit={() => returnEssay.mutate({ data: { essayId: essay.id, reason: returnReason } })}
          body={
            <Box>
              <Text>Comentários e notas serão removidos. Deseja continuar?</Text>
              <Input
                style={inputStyle}
                borderColor="brand.300"
                mt={2}
                onChange={(e) => setReturnReason(e.target.value)}
                placeholder="Motivo da devolução"
              />
            </Box>
          }
        />
        <Modal
          title={'Enviar revisão'}
          body={'O aluno poderá visualizar comentários e notas. Deseja continuar?'}
          isOpen={finishReviewIsOpen}
          onClose={finishReviewOnClose}
          submitLabel={'Enviar'}
          isLoading={actionLoading}
          onSubmit={sendReview}
        />
        <Modal
          title={'Finalizar revisão'}
          body={'Nenhuma alteração poderá ser feita após finalizar a correção. Deseja continuar?'}
          isOpen={closeReviewIsOpen}
          onClose={closeReviewOnClose}
          submitLabel={'Finalizar correção'}
          isLoading={actionLoading}
          onSubmit={closeReview}
        />
      </Portal>
    </>
  )
}
