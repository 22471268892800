import { AreaHeader } from '@/components/AreaHeader'
import { PromptModalBody } from '@/components/PromptModalBody'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import {
  useGetApiV1AssistantListAssistantPrompts,
  usePutApiV1AssistantAssistantAssistantIdDefault,
} from '@/services/assistant.ts'
import type { AssistantPromptViewModel } from '@/services/types'
import { Box, Radio, Text, useDisclosure } from '@chakra-ui/react'
import type { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SharedTablePagination from '../../components/SharedTablePagination'
import { Modal } from '../../components/modal'
import Page from '../Page'

function PromptsPage(_props) {
  const [prompts, setPrompts] = useState<AssistantPromptViewModel[]>([])
  const [promptsData, setPromptsData] = useState<AssistantPromptViewModel[]>([])
  const [filterText, setFilterText] = useState('')
  const [selectedPrompt, setSelectedPrompt] = useState<AssistantPromptViewModel | null>(null)

  const { isOpen: detailsIsOpen, onClose: detailsOnClose, onOpen: detailsOnOpen } = useDisclosure()

  const { mutateAsync: updateDefaultPrompt, isPending: isUpdateDefaultPending } =
    usePutApiV1AssistantAssistantAssistantIdDefault<AxiosError>({
      mutation: {
        onSuccess: () => {
          fetchPrompts()
          toast.success('Default prompt alterado com sucesso!')
        },
      },
    })

  const {
    data: allPrompts,
    isPending: isPendingGetAllPrompts,
    refetch: getAllPrompts,
  } = useGetApiV1AssistantListAssistantPrompts()

  useEffect(() => {
    setPrompts(allPrompts?.data?.message || [])
  }, [allPrompts])

  useEffect(() => {
    if (promptsData) {
      setPrompts(
        promptsData.filter((item) => {
          return (
            item?.title?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.content?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.systemContent?.toLowerCase().includes(filterText.toLowerCase())
          )
        }),
      )
    }
  }, [filterText, promptsData])

  function handleDetailsOnClose() {
    detailsOnClose()
    setSelectedPrompt(null)
  }

  function handleDetailsOnOpen(prompt) {
    setSelectedPrompt(prompt)
    detailsOnOpen()
  }

  async function fetchPrompts() {
    try {
      const result = await getAllPrompts()
      setPromptsData(result.data?.data.message || [])
    } catch (error) {
      console.error(error)
    }
  }

  async function changeDefaultPrompt(promptId: string) {
    await updateDefaultPrompt({ assistantId: promptId })
  }

  return (
    <Page authPage={true} allowedUserType={'owner'}>
      <LayoutMenu isLoading={isPendingGetAllPrompts || isUpdateDefaultPending}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Prompts"
            button={{
              isVisible: true,
              onClick: detailsOnOpen,
              label: 'Adicionar prompt',
            }}
          />
          <SearchComponent filterText={filterText} handleSearchInputChange={(e) => setFilterText(e.target.value)} />
          <SharedTablePagination
            data={prompts}
            itemsPerPage={5}
            columns={[
              {
                title: 'Default?',
                size: 100,
                render: (itemData: AssistantPromptViewModel) => (
                  <Box className={'table-cell-container'}>
                    <Radio
                      isChecked={itemData.isDefault}
                      variant={'outline'}
                      size="lg"
                      color="brand.200"
                      border="2px"
                      mt={5}
                      _checked={{
                        borderWidth: '6px',
                      }}
                      onChange={() => changeDefaultPrompt(itemData.id ?? '')}
                    />
                  </Box>
                ),
              },
              {
                title: 'Titulo',
                render: (itemData: AssistantPromptViewModel) => <Box className={'table-cell-container'}>{itemData.title}</Box>,
              },
              {
                title: 'Prompt',
                render: (itemData: AssistantPromptViewModel) => (
                  <Box className={'table-cell-container'}>
                    <Text noOfLines={3}>{itemData.content}</Text>
                  </Box>
                ),
              },
              {
                title: 'System Content',
                render: (itemData: AssistantPromptViewModel) => (
                  <Box className={'table-cell-container'}>
                    <Text noOfLines={3}>{itemData.systemContent}</Text>
                  </Box>
                ),
              },
              {
                title: 'Detalhes',
                size: 140,
                render: (itemData: AssistantPromptViewModel) => (
                  <Box className={'table-cell-container'} onClick={() => handleDetailsOnOpen(itemData)}>
                    <div className="atividade-txt-4-area-de-turmas">
                      <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
                    </div>
                  </Box>
                ),
              },
            ]}
          />
        </LayoutMenu.Content>
        <Modal
          title={selectedPrompt ? selectedPrompt.title : 'Novo Prompt'}
          isOpen={detailsIsOpen}
          onClose={handleDetailsOnClose}
          body={<PromptModalBody prompt={selectedPrompt} onClose={handleDetailsOnClose} fetchPrompts={fetchPrompts} />}
          size="3xl"
        />
      </LayoutMenu>
    </Page>
  )
}

export default PromptsPage
