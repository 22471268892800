import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllAlertsApi = async () => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/alert/list-all`)

  return response.data.message
}

export const readAlertApi = async (alertId) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/alert/${alertId}/read`)

  return response.data.message
}

export const listAllUnreadAlertsApi = async () => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/alert/list-unread`)

  return response.data.message
}
