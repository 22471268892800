/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface SchoolAddressViewModel {
  /** @nullable */
  city?: string | null;
  /** @nullable */
  complement?: string | null;
  /** @nullable */
  country?: string | null;
  /** @nullable */
  number?: string | null;
  /** @nullable */
  state?: string | null;
  /** @nullable */
  street?: string | null;
  /** @nullable */
  zipCode?: string | null;
}
