import { Flex, HStack, Text } from '@chakra-ui/react'
import { PaginationButton } from '../PaginationButton'

export const Pagination = ({ page, limitReached, onNext, onPrevious }) => {
  return (
    <HStack justifyContent="end" spacing={7}>
      <Text>Exibindo página</Text>
      <Flex>
        <PaginationButton direction="previous" onClick={onPrevious} isDisabled={page <= 1} />
        <Text marginX={5}>{page}</Text>
        <PaginationButton direction="next" onClick={onNext} isDisabled={limitReached} />
      </Flex>
    </HStack>
  )
}
