import './CriarMonitor.css'
import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { STEP_MONITOR } from '../../store/useCreateMonitorStore'
import Page from '../Page'
import SuccessfulCreationPage from '../SuccessfulCreationPage'
import CreateMonitorStep1 from './CreateMonitorStep1'

const CriarMonitor = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()

  const handleNextStep = () => {
    return new Promise((resolve) => {
      if (currentStep === STEP_MONITOR.ABOUT) {
        setCurrentStep(currentStep + 1)
        resolve()
      } else {
        navigate('/area-de-monitores')
        resolve()
      }
    })
  }

  const handlePreviousStep = () => {
    setCurrentStep(STEP_MONITOR.ABOUT)
    navigate('/area-de-monitores')
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_MONITOR.ABOUT:
        return <CreateMonitorStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case STEP_MONITOR.CREATED:
        return (
          <SuccessfulCreationPage
            handleRedirectReturn={handleNextStep}
            listText={'Voltar'}
            headbarLink={'/area-de-monitores'}
            entityCreated={'Monitor novo'}
          />
        )
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CriarMonitor
