import { AreaHeader } from '@/components/AreaHeader'
import LayoutMenu from '@/layouts/LayoutMenu'
import { Button, HStack, Input, Text } from '@chakra-ui/react'
import { format, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { AiOutlineClear } from 'react-icons/ai'
import { clearLogsApi, getLogsApi } from '../../apis/logsApi/logsApi'
import Page from '../Page'
import { LogSearch } from './search'

function Monitoring(_props) {
  const [logs, setLogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(subDays(new Date(), 7))
  const [endDate, setEndDate] = useState(new Date())
  const [selectedItems, setSelectedItems] = useState([])

  async function fetchLogs() {
    try {
      setIsLoading(true)
      const result = await getLogsApi(startDate, endDate)

      setLogs(result)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLogs()
  }, [startDate, endDate])

  function setRangeToToday() {
    setStartDate(new Date())
    setEndDate(new Date())
  }

  function setRangeToTheLast7Days() {
    setStartDate(subDays(new Date(), 7))
    setEndDate(new Date())
  }

  async function clearSelectedItems() {
    const ids = selectedItems.map((item) => item.id)
    await clearLogsApi(ids)
    await fetchLogs()
  }

  return (
    <Page authPage={true} allowedUserType={'owner'}>
      <LayoutMenu isLoading={isLoading}>
        <LayoutMenu.Content>
          <AreaHeader title="Monitoramento do Sistema" />
          <HStack width={'100%'} flexDir={{ base: 'column', md: 'row' }}>
            {selectedItems.length && (
              <Button variant="primary" leftIcon={<AiOutlineClear />} onClick={clearSelectedItems}>
                Limpar {selectedItems.length} ite{selectedItems.length > 1 ? 'ns' : 'm'}
              </Button>
            )}
            {!selectedItems.length && logs.length && <Text>Total de {logs.length} registros</Text>}
          </HStack>
          <HStack flexDir={{ base: 'column', md: 'row' }} width="100%" height={'100%'} justifyContent="space-between">
            <HStack width={'100%'} flexDir={{ base: 'column', md: 'row' }}>
              <Button width="300px" variant="outline" backgroundColor="white" onClick={setRangeToTheLast7Days}>
                Últimos 7 dias
              </Button>
              <Button paddingX={5} marginX={5} variant="outline" backgroundColor="white" onClick={setRangeToToday}>
                Hoje
              </Button>
              <Input
                type="date"
                backgroundColor="white"
                borderColor="blackAlpha.300"
                value={format(startDate, 'yyyy-MM-dd')}
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />
              <Input
                type="date"
                backgroundColor="white"
                borderColor="blackAlpha.300"
                value={format(endDate, 'yyyy-MM-dd')}
                onChange={(e) => setEndDate(new Date(e.target.value))}
              />
            </HStack>
          </HStack>
          <LogSearch hits={logs} isLoading={isLoading} setSelectedItems={setSelectedItems} />
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default Monitoring
