/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { ActivityType } from './activityType';
import type { EssayViewModel } from './essayViewModel';
import type { KeyValueViewModel } from './keyValueViewModel';

export interface ActivityViewModel {
  activityType?: ActivityType;
  createdAt?: string;
  deadline?: string;
  /** @nullable */
  description?: string | null;
  /** @nullable */
  essays?: EssayViewModel[] | null;
  /** @nullable */
  groups?: KeyValueViewModel[] | null;
  id?: string;
  institution?: KeyValueViewModel;
  school?: KeyValueViewModel;
  /** @nullable */
  subject?: string | null;
  teacher?: KeyValueViewModel;
  theme?: KeyValueViewModel;
  validFrom?: string;
}
