import './Menu.css'
import FontsUtils from '@/theme/foundations/FontsUtils'
import { Button, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { logoff } from '../../apis/LoginApi'

type LogoffMenuProps = {
  isMinimized: boolean
}

function LogoffMenu({ isMinimized }: LogoffMenuProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  return (
    <Button
      hidden={isMobile && isMinimized}
      variant="ghost"
      borderRadius="none"
      width="100%"
      py={12}
      _hover={{ bg: 'brand.300' }}
      onClick={logoff}>
      <VStack>
        <Image className="menu-item-picture" src={'/img/logout-2851807@2x.png'} alt="logout-icon" />
        <Text
          fontSize="sm"
          color={FontsUtils.fontGray.color}
          fontFamily={FontsUtils.fontPrimary.fontFamily}
          hidden={isMinimized}>
          Sair
        </Text>
      </VStack>
    </Button>
  )
}

export default LogoffMenu
