// @ts-nocheck
import { type ChangeEvent, useMemo, useState } from 'react'
import '../GroupsArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1Student } from '@/services/student'
import type { GroupStudentViewModel } from '@/services/types'
import { useIsHistoryEmpty } from '@/utils/useNavigationHooks'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import ActiveStatusCard from '../../../components/ActiveStatusCard'
import SharedTablePagination from '../../../components/SharedTablePagination'
import useLoggedUserStore from '../../../store/useLoggedUserStore'
import { shiftMapping } from '../../../utils/shiftENUM'
import Page from '../../Page'

function StudentGroupsArea() {
  const { loggedUser } = useLoggedUserStore()
  const [filterText, setFilterText] = useState('')
  const getApiV1Student = useGetApiV1Student(loggedUser.schoolId)

  const groupsData = useMemo(() => {
    if (!getApiV1Student.isSuccess) return []
    return getApiV1Student.data?.data?.message?.schools?.find((school) => school.id === loggedUser.schoolId)?.groups || []
  }, [getApiV1Student.isSuccess, loggedUser.schools, loggedUser.schoolId])

  const groups = groupsData?.filter((item) => {
    const nameMatch = item.name?.toLowerCase().includes(filterText.toLowerCase())
    const shiftMatch = shiftMapping[item.shift].toLowerCase().includes(filterText.toLowerCase())
    return nameMatch || shiftMatch
  })

  useIsHistoryEmpty()

  function groupHandler(turma?: string) {
    return (
      <div className="atividade-txt-area-de-turmas">
        <div className="frame-2580-area-de-turmas">
          <div className="x1-ano-area-de-turmas valign-text-middle-area-de-turmas publicsans-normal-black-12px">{turma}</div>
        </div>
      </div>
    )
  }

  function shiftHandler(turno?: number) {
    return (
      <div className="atividade-txt-2-area-de-turmas">
        <div className="frame-2580-2-area-de-turmas">
          <div className="noite-area-de-turmas valign-text-middle-area-de-turmas publicsans-bold-absolute-zero-12px">
            {shiftMapping[turno]}
          </div>
        </div>
      </div>
    )
  }

  function detailsHandler(itemData?: string) {
    return (
      <Link to={`/detalhamento-turma/${itemData}`}>
        <div className="atividade-txt-4-area-de-turmas">
          <img className="ic-arrowright-area-de-turmas" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </div>
      </Link>
    )
  }

  function handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    const inputText = e.target.value
    setFilterText(inputText)
  }

  return (
    <Page authPage={true} allowedUserType={'student'}>
      <LayoutMenu isLoading={getApiV1Student?.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Turmas"
            button={{
              isVisible: loggedUser.user_role === 'independentteacher',
              navigateTo: '/criar-turma',
              label: 'Criar turma',
            }}
          />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
          <Box className="status-area-de-turmas">
            <ActiveStatusCard imagePath="/img/folder-2851794@2x.png" title="Turmas ativas" amount={groupsData?.length || 0} />
          </Box>
          {groups?.length === 0 ? (
            <Flex alignSelf={'stretch'} backgroundColor={'#f3f5ff'} height={'64px'} marginRight={'32px'} borderRadius={'16px'}>
              <Text className={'publicsans-normal-gray-14px'} width={'100%'} textAlign={'center'} alignSelf={'center'}>
                Nenhuma turma foi associado ao seu perfil ainda.
              </Text>
            </Flex>
          ) : (
            <SharedTablePagination
              data={groups}
              itemsPerPage={5}
              columns={[
                {
                  title: 'Turma',
                  render: (itemData: GroupStudentViewModel) => <div>{groupHandler(itemData.name)}</div>,
                },
                {
                  title: 'Turno',
                  render: (itemData: GroupStudentViewModel) => <div>{shiftHandler(itemData.shift)}</div>,
                },
                {
                  title: 'Detalhes',
                  render: (itemData: GroupStudentViewModel) => <div>{detailsHandler(itemData.id)}</div>,
                },
              ]}
            />
          )}
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default StudentGroupsArea
