import { create } from 'zustand'

interface NavigationState {
  returnTo: string | null
  setReturnTo: (path: string) => void
  resetReturnTo: () => void
}

const useNavigationStore = create<NavigationState>((set) => ({
  returnTo: null,
  setReturnTo: (path) => set({ returnTo: path }),
  resetReturnTo: () => set({ returnTo: null }),
}))

export default useNavigationStore
