import './createTheme.css'
import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import useCreateThemeStore from '../../store/useCreateThemeStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'
import CreateThemeStep1 from './CreateThemeStep1'
import CreateThemeStep2 from './CreateThemeStep2'

const CreateTheme = () => {
  const [currentStep, setCurrentStep] = useState(1)

  const { setThemeData } = useCreateThemeStore()
  const { loggedUser } = useLoggedUserStore()

  useEffect(() => {
    if (!loggedUser.schoolId) return
    setThemeData({ schoolId: loggedUser.schoolId })
  }, [loggedUser.schoolId])

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CreateThemeStep1 handlingNextStep={handleNextStep} />
      case 2:
        return <CreateThemeStep2 />
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CreateTheme
