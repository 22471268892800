import { useEffect, useRef, useState } from 'react'
import Headbar from '../../components/Headbar'
import './ThemeDetails.css'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Input,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { listSupportMaterialApi } from '../../apis/themeApi/supportMaterialApi'
import { deleteTheme, getActivityInformation, getThemeApi } from '../../apis/themeApi/themeApi'
import GetModalEditTema from '../../components/GetModalEditTheme'
import NoContentContainer from '../../components/NoContentContainer'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import SupportMaterialList from '../../components/SupportMaterialList'
import { usePostApiV1ThemeSupportMaterial } from '../../services/theme'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { RoleTypes } from '../../utils/RoleTypes'
import { activityStatus } from '../../utils/activityStatus'
import { formatDate } from '../../utils/formatDate'
import { handleFileChange } from '../../utils/supportMaterialUpload'
import Page from '../Page'

function ThemeDetails() {
  const inputFileRef = useRef(null)

  const { temaID } = useParams()

  const [temasData, setTemasData] = useState([])
  const [atividadesData, setAtividadesData] = useState([])
  const [atividades, setAtividades] = useState([])
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('sobre')
  const [filterTextActivity, setFilterTextActivity] = useState('')
  const popoverDisclosure = useDisclosure()
  const modalDisclosure = useDisclosure()
  const navigate = useNavigate()

  const { loggedUser } = useLoggedUserStore()

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([getThemeApi(temaID), getActivityInformation(temaID)]).then(([themeResult, activityResult]) => {
        new Promise((resolve) => {
          const result = listSupportMaterialApi(temaID)

          resolve(result)
        }).then((supportMaterialResult) => {
          resolve({ themeResult, activityResult, supportMaterialResult })
        })
      })
    })
  }

  useAddToHistory()

  useEffect(() => {
    fetchInitialData().then(({ themeResult, activityResult, supportMaterialResult }) => {
      setTemasData(themeResult)
      setAtividadesData(activityResult)
      setSupportMaterialList(supportMaterialResult)
      setLoading(false)
    })
  }, [])

  function getStatusValue(item) {
    const dataAtual = new Date()
    const dataValidFrom = new Date(item.validFrom)
    const dataDeadline = new Date(item.deadline)

    if (dataAtual < dataValidFrom) {
      return 0
    }
    if (dataAtual >= dataValidFrom && dataAtual <= dataDeadline) {
      return 10
    }
    if (dataAtual > dataDeadline) {
      return 20
    }

    return 0 // Considerando 0 como um "valor padrão"
  }

  useEffect(() => {
    setAtividades(
      atividadesData.filter((item) => {
        const activityMatch = item.subject.toLowerCase().includes(filterTextActivity.toLowerCase())

        const statusValue = getStatusValue(item)
        const statusMatch = activityStatus[statusValue].toLowerCase().includes(filterTextActivity.toLowerCase())

        return activityMatch || statusMatch
      }),
    )
  }, [filterTextActivity, atividadesData])

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function geradorDeStatus(atividades) {
    let backgroundColor
    let status = 0
    const dataAtual = new Date()
    const dataValidFrom = new Date(atividades.validFrom)
    const dataDeadline = new Date(atividades.deadline)

    if (dataAtual < dataValidFrom) {
      backgroundColor = 'in-waiting-activity-status'
      status = 0
    } else if (dataAtual >= dataValidFrom && dataAtual <= dataDeadline) {
      backgroundColor = 'in-progress-activity-status'
      status = 10
    } else if (dataAtual > dataDeadline) {
      backgroundColor = 'finished-activity-status'
      status = 20
    }

    return (
      <div className={'atividade-txt-detalhamento-turma publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{activityStatus[status]}</div>
      </div>
    )
  }

  function geradorTema(tema) {
    return <AtividadeTxt21 tema={tema} />
  }

  function geradorSetas(activityId) {
    return <AtividadeTxt62 activityId={activityId} />
  }

  function geradorStatusRedacoes(atividadesData) {
    const countSubmitted = atividadesData.amountEssaysSubmitted
    const countReviewed = atividadesData.amountEssaysReviewed
    const countClosed = atividadesData.amountEssaysClosed

    const submittedText = countSubmitted === 1 ? `${countSubmitted} submetida` : `${countSubmitted} submetidas`
    const reviewedText = countReviewed === 1 ? `${countReviewed} revisada` : `${countReviewed} revisadas`
    const closedText = countClosed === 1 ? `${countClosed} concluída` : `${countClosed} concluídas`

    return (
      <div className={'table-cell-container'}>
        <div className={'essay-status in-grading-essay-status publicsans-normal-nevada-14px'}>{submittedText}</div>
        <div className={'essay-status in-review-essay-status publicsans-normal-nevada-14px'}>{reviewedText}</div>
        <div className={'essay-status finished-essay-status publicsans-normal-nevada-14px'}>{closedText}</div>
      </div>
    )
  }

  const { mutate: uploadSupportMaterial, isPending: isUploadingSupportMaterial } = usePostApiV1ThemeSupportMaterial()

  function handleUploadSupportMaterial(payloads) {
    const uploadPromises = payloads.map((payload) => {
      return new Promise((resolve, reject) => {
        uploadSupportMaterial(
          { data: payload },
          {
            onSuccess: (result) => {
              resolve(result)
              refreshSupportMaterialList()
            },
            onError: () => {
              toast.error('Erro ao enviar o material de suporte')
              reject()
            },
          },
        )
      })
    })

    return Promise.all(uploadPromises)
      .then(() => {
        toast.success('Material de apoio carregado com sucesso!')
      })
      .catch((error) => {
        console.error('Erro ao carregar material de apoio:', error)
      })
  }

  function refreshSupportMaterialList() {
    new Promise((resolve) => {
      const result = listSupportMaterialApi(temaID)

      resolve(result)
    }).then((supportMaterialResult) => {
      setSupportMaterialList(supportMaterialResult)
    })
  }

  function isStudent() {
    return loggedUser.user_role === RoleTypes.STUDENT
  }

  function isMonitor() {
    return loggedUser.user_role === RoleTypes.MONITOR
  }

  function AtividadeTxt62({ activityId }) {
    return (
      <Box className="atividade-txt-6-detalhamento-turma">
        <Box onClick={() => navigate(`/detalhamento-atividade/${activityId}`)}>
          <Image className="ic-arrowright-detalhamento-turma" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </Box>
      </Box>
    )
  }

  return (
    <div className="detalhamento-temas screen-detalhamento-tema">
      <VStack width={'100%'} alignSelf={'start'} gap={0}>
        <Page authPage={true} allowedUserType={'student teacher monitor independentteacher'} />
        <Headbar returnTo={'/area-de-temas'} />
        <VStack gap={5} width={'85%'} className={'content-max-size'}>
          <div className={'detalhamento-tema-body'}>
            <Box className="tema-detalhamento-tema">
              <Image hideBelow={'md'} className={'theme-image-style'} src={temasData?.base64Image} />
              <VStack width={'100%'} justifyContent={'space-between'}>
                <HStack width={'100%'} alignSelf={'flex-start'}>
                  <VStack width={'100%'} alignItems={'flex-start'} flex={1}>
                    <Text className="publicsans-bold-nevada-14px">Tema</Text>
                    <Text fontSize={{ md: '24px', base: '18px' }} className="publicsans-normal-absolute-zero-24px">
                      {loading ? '' : temasData.subject}
                    </Text>
                  </VStack>
                  <HStack
                    w={'100%'}
                    hidden={isStudent() || isMonitor()}
                    justifyContent={'flex-end'}
                    flex={0.5}
                    flexDir={{ md: 'row', base: 'column' }}>
                    <Text className="publicsans-bold-nevada-14px">Editar</Text>
                    <Image
                      className="mode-edit-detalhamento-tema"
                      src="/img/mode-edit@2x.png"
                      alt="Mode edit"
                      cursor={'pointer'}
                      onClick={modalDisclosure.onOpen}
                    />
                  </HStack>
                  {modalDisclosure.isOpen && (
                    <GetModalEditTema
                      loading={loading}
                      setLoading={setLoading}
                      modalDisclosure={modalDisclosure}
                      temasData={temasData}
                      setTemasData={setTemasData}
                    />
                  )}
                </HStack>
                <HStack
                  width={'100%'}
                  alignItems={{ base: 'flex-start', md: 'flex-end' }}
                  flexDir={{ md: 'row', base: 'column' }}>
                  <Entrega criao="CRIAÇÃO" dataCriacao={temasData.createdAt} />

                  <HStack hidden={isStudent() || isMonitor()}>
                    <div className={'publicsans-bold-nevada-14px'}> Apagar tema?</div>
                    <Popover
                      isOpen={popoverDisclosure.isOpen}
                      onOpen={popoverDisclosure.onOpen}
                      onClose={popoverDisclosure.onClose}
                      placement="bottom-end"
                      closeOnBlur={false}>
                      <PopoverTrigger>
                        <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'} />
                      </PopoverTrigger>
                      <PopoverContent maxWidth={'300px'} width={['290px', '448px']} borderRadius={'10px'}>
                        <PopoverHeader>
                          <div className={'text-popover'}>Confirme Remoção do tema</div>
                        </PopoverHeader>
                        <PopoverFooter alignSelf={'center'} marginTop={'40px'} width={'100%'}>
                          <VStack width={'100%'}>
                            <Button
                              colorScheme={'red'}
                              width={'100%'}
                              height={'48px'}
                              isLoading={loading}
                              onClick={async () => {
                                try {
                                  setLoading(true)
                                  await deleteTheme(temaID)
                                } finally {
                                  setLoading(false)
                                  navigate('/area-de-temas')
                                }
                              }}>
                              Remover
                            </Button>
                            <Button
                              backgroundColor={'#f9faff'}
                              borderColor={'#648BF7'}
                              border={'1px'}
                              borderRadius={'8px'}
                              color={'#062989'}
                              height={'48px'}
                              width={'100%'}
                              onClick={popoverDisclosure.onClose}>
                              Cancelar
                            </Button>
                          </VStack>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </HStack>
              </VStack>
            </Box>

            <div className="frame-2595-detalhamento-tema">
              <div className="switch-detalhamento-tema">
                <div
                  className={`frame-2459-detalhamento-tema ${selectedTab === 'sobre' ? 'tab-border' : ''}`}
                  onClick={() => changeSelectedTab('sobre')}
                  style={{ cursor: 'pointer' }}>
                  <div
                    className={`publicsans-bold-nevada-16px ${
                      selectedTab === 'sobre' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                    }`}>
                    Sobre
                  </div>
                </div>
                <div
                  className={`frame-2469-detalhamento-tema ${selectedTab === 'atividades vinculadas' ? 'tab-border' : ''}`}
                  onClick={() => changeSelectedTab('atividades vinculadas')}
                  style={{ cursor: 'pointer' }}>
                  <div
                    className={`publicsans-bold-gray-16px ${
                      selectedTab === 'atividades vinculadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                    }`}>
                    Atividades vinculadas
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === 'sobre' && (
              <div className={'detalhes-tema'}>
                <div className={'descricao-detalhamento-tema'}>
                  <div className="publicsans-normal-resolution-blue-24px">{'Descrição do tema'}</div>
                </div>
                <div className="frame-2604-detalhamento-tema">
                  <p className="lorem-ipsum-dolor-si-detalhamento-tema publicsans-normal-white-16px">
                    <span>
                      <span className="publicsans-normal-nevada-16px">{temasData.description} </span>
                    </span>
                  </p>
                </div>
                <VStack width={'100%'} gap={5}>
                  <div className={'anexo-title-detalhamento-tema frame-2606-detalhamento-tema'}>
                    <div className="publicsans-normal-resolution-blue-24px">{'Material de apoio'}</div>
                  </div>
                  {supportMaterialList.length === 0 ? (
                    <Container
                      backgroundColor={'#f3f5ff'}
                      width={'100%'}
                      borderRadius={'12px'}
                      minHeight={'100px'}
                      maxWidth={'100%'}
                      textAlign={'center'}>
                      <Text marginTop={'35px'} className={'publicsans-normal-nevada-16px'}>
                        Não há material de apoio para esse tema.
                      </Text>
                    </Container>
                  ) : (
                    <SupportMaterialList
                      supportMaterialList={supportMaterialList}
                      deleteType={'detalhamentoTema'}
                      refreshSupportMaterialList={refreshSupportMaterialList}
                    />
                  )}
                  {isStudent() ? (
                    ''
                  ) : (
                    <Box className="upload-material-detalhamento-tema">
                      {isUploadingSupportMaterial ? (
                        <Spinner thickness="4px" speed="0.65s" color="brand.200" size="xl" />
                      ) : (
                        <Box
                          className="frame-2600-detalhamento-tema"
                          onClick={() => inputFileRef.current?.click()}
                          cursor="pointer">
                          <img className="frame-5-detalhamento-tema" src="/img/frame-5-2@2x.png" alt="Frame 5" />
                          <Text mt={3} className="publicsans-bold-white-14px-2">
                            <span className="publicsans-bold-absolute-zero-14px">Clique</span>
                            <span className="publicsans-normal-nevada-14px"> para adicionar um arquivo</span>
                          </Text>
                        </Box>
                      )}
                      <Input
                        type="file"
                        multiple
                        accept=".jpg,.jpeg,.png,.gif,.pdf,.docx,.xlsx,.xls,.csv"
                        disabled={isUploadingSupportMaterial}
                        onChange={async (e) => {
                          try {
                            const results = await handleFileChange(e.target.files, temaID)
                            if (results.length > 0) {
                              await handleUploadSupportMaterial(results)
                              refreshSupportMaterialList()
                            }
                          } catch (error) {
                            toast.error('Erro ao processar arquivo(s)')
                            console.error(error)
                          }
                        }}
                        ref={inputFileRef}
                        display="none"
                      />
                    </Box>
                  )}
                </VStack>
              </div>
            )}
            {selectedTab === 'atividades vinculadas' && (
              <VStack gap={5} mb={5} className={'detalhes-tema'}>
                <HStack width={'100%'}>
                  <div className="search-filter-detalhamento-tema">
                    <SearchComponent
                      filterText={filterTextActivity}
                      handleSearchInputChange={(e) => setFilterTextActivity(e.target.value)}
                    />
                  </div>
                </HStack>
                {atividades.length > 0 ? (
                  <SharedTablePagination
                    data={atividades}
                    itemsPerPage={5}
                    columns={[
                      {
                        title: 'Atividade',
                        render: (atividadesData) => <div>{geradorTema(atividadesData.subject)}</div>,
                      },
                      {
                        title: 'Status da Atividade',
                        render: (atividadesData) => <div>{geradorDeStatus(atividadesData)}</div>,
                      },
                      {
                        title: 'Redações Entregues',
                        render: (atividadesData) => <div>{geradorStatusRedacoes(atividadesData)}</div>,
                      },
                      {
                        title: 'Detalhes',
                        render: (atividadesData) => <div>{geradorSetas(atividadesData.id)}</div>,
                      },
                    ]}
                  />
                ) : (
                  <NoContentContainer>Não há atividades associadas a esse tema.</NoContentContainer>
                )}
              </VStack>
            )}
          </div>
        </VStack>
      </VStack>
    </div>
  )
}

export default ThemeDetails

function Entrega(props) {
  const { criao, className, dataCriacao } = props

  if (!dataCriacao) return

  return (
    <VStack alignItems={'start'} className={`entrega-1-detalhamento-tema ${className || ''}`}>
      <Text className="publicsans-bold-nevada-10px">{criao}</Text>
      <HStack className="data-detalhamento-tema">
        <img className="x-28517-detalhamento-tema" src={'/img/calendar-2851774-5@2x.png'} alt="calendar-2851774" />
        <div className="publicsans-normal-nevada-14px">{formatDate(dataCriacao)}</div>
      </HStack>
    </VStack>
  )
}

function AtividadeTxt21(props) {
  const { tema } = props
  return (
    <div className="atividade-txt-4-detalhamento-turma">
      <div className="content-4-detalhamento-turma">
        <p className="gerao-do-sec-21-a-detalhamento-turma valign-text-middle-detalhamento-turma publicsans-normal-nevada-14px">
          {tema}
        </p>
      </div>
    </div>
  )
}
