import { MultiSelect } from '@/theme/foundations/multiSelect.ts'
import { extendTheme } from '@chakra-ui/react'
import { Button } from './components/Button'
import { Checkbox } from './components/Checkbox'
import { Heading } from './components/Heading'
import { Popover } from './components/Popover'
import { Select } from './components/Select'
import { colors } from './foundations/colors'
import { shadows } from './foundations/shadows'

export const theme = extendTheme({
  components: {
    MultiSelect,
    Select,
    Heading,
    Button,
    Checkbox,
    Popover,
  },
  colors,
  shadows,
})
