import Title2 from '../Title2'
import './CabecalhoTabela.css'
import { Box, Button, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

function CabecalhoTabela(props) {
  const { text, textLink, link } = props
  const navigate = useNavigate()

  return (
    <Box className="title-cta-5">
      <Title2>{text}</Title2>

      <Box justifyContent={'end'} className="cta-atividades-1">
        <Button
          onClick={() => {
            navigate(link)
          }}
          variant={'ghost'}
          cursor="pointer"
          display="flex"
          alignItems="center">
          <Box className="publicsans-normal-azure-radiance-14px">{textLink}</Box>
          <Image className="ic-arrowright-9" src={'/img/ic-arrowright-61@2x.png'} alt="ic-arrowright" />
        </Button>
      </Box>
    </Box>
  )
}

export default CabecalhoTabela
