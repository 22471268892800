/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface NewEssayViewModel {
  activityId?: string;
  /** @nullable */
  draft?: string | null;
  groupId?: string;
  isText?: boolean;
  teacherId?: string;
}
