import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllGroups = async (schoolIDParam) => {
  const schoolID = schoolIDParam || localStorage.getItem('schoolId')
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolID}/group`)

  return response.data.message
}

export const getGroupInformation = async (turmaID, schoolIDParam) => {
  const schoolID = schoolIDParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolID}/group/${turmaID}`)

  return response.data.message
}

export const getEssaysInformation = async (turmaID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/essay/group/${turmaID}`)

  return response.data.message
}

export const getActivityInformation = async (turmaID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/activity/group/${turmaID}`)

  return response.data.message
}

export const getStudentInformation = async (turmaID, schoolIDParam) => {
  const schoolID = schoolIDParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/student/school/${schoolID}/group/${turmaID}`)

  return response.data.message
}

export const putEditGroup = async (turmasData, schoolIDParam) => {
  const schoolID = schoolIDParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school/${schoolID}/group`, turmasData)

  return response.data.message
}

export const deleteGroup = async (turmasId, schoolIDParam) => {
  const schoolID = schoolIDParam || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/school/${schoolID}/group/${turmasId}`)

  return response.data.message
}
