import type { NewSupportMaterialViewModel } from '@/services/types'
import { Box, Input, Text, VStack } from '@chakra-ui/react'
import type React from 'react'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SupportMaterialList from '../../../components/SupportMaterialList'
import { handleFileChange } from '../../../utils/supportMaterialUpload'

type ThemeMaterialUpload = {
  onChange: (files: NewSupportMaterialViewModel[]) => void
}

export const ThemeMaterialUpload = ({ onChange }: ThemeMaterialUpload) => {
  const [files, setFiles] = useState<NewSupportMaterialViewModel[]>([])
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleRemoveItem = (title: string) => {
    const updatedList = files.filter((item) => item.title !== title)
    setFiles(updatedList)
  }

  useEffect(() => {
    onChange(files)
  }, [files, onChange])

  return (
    <VStack>
      <SupportMaterialList
        supportMaterialList={files}
        deleteType={'criarTema'}
        isDetail={false}
        handleRemoveItem={handleRemoveItem}
      />
      <Box className="upload-material-detalhamento-tema">
        <>
          <Box onClick={() => inputRef.current?.click()} className="frame-2600-detalhamento-tema">
            <img className="frame-5-detalhamento-tema" src="/img/frame-5-2@2x.png" alt="Frame 5" />
            <Text mt={3} className="publicsans-bold-white-14px-2">
              <span className="publicsans-bold-absolute-zero-14px">Clique</span>
              <span className="publicsans-normal-nevada-14px"> para adicionar materiais de apoio</span>
              <br />
              <Text textAlign={'center'} className={'publicsans-normal-nevada-12px'}>
                Formatos permitidos .png, .jpg, .jpeg, .gif, .pdf, .docx, .csv, .xlsx
              </Text>
            </Text>
          </Box>
          <Input
            type="file"
            ref={inputRef}
            multiple
            accept=".jpg,.jpeg,.png,.gif,.pdf,.docx,.xlsx,.xls,.csv"
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              try {
                if (!e.target.files) return

                const fileArray: File[] = Array.from(e.target.files)

                const results: NewSupportMaterialViewModel[] = await handleFileChange(fileArray, '')
                setFiles([...files, ...results])
              } catch (_error) {
                toast.error('Erro ao processar arquivo(s)')
              }
            }}
            display="none"
          />
        </>
      </Box>
    </VStack>
  )
}
