import './App.css'
import './src/utils/yupConfig'
import './src/apis/ApiMiddleware'
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import AccountConfirmation from './src/pages/AccountConfirmation'
import ActivityArea from './src/pages/ActivityArea'
import ActivityDetails from './src/pages/ActivityDetails'
import CreateActivity from './src/pages/CreateActivity'
import CreateGroup from './src/pages/CreateGroup'
import CreateInstitution from './src/pages/CreateInstitution'
import CreateMonitor from './src/pages/CreateMonitor'
import CreateSchool from './src/pages/CreateSchool'
import CreateTheme from './src/pages/CreateTheme'
import EssayArea from './src/pages/EssayArea'
import EssayCreation from './src/pages/EssayCreation'
import EssayGrading from './src/pages/EssayGrading'
import DetalhamentoTurma from './src/pages/GroupDetails'
import GroupsArea from './src/pages/GroupsArea'
import InitialPageAdm from './src/pages/InitialPageAdm'
import InitialPageIndependentTeacher from './src/pages/InitialPageIndependentTeacher'
import InitialPageOwner from './src/pages/InitialPageOwner'
import InitialPageStudent from './src/pages/InitialPageStudent'
import InitialPageTeacher from './src/pages/InitialPageTeacher'
import AreaDeInstituicoes from './src/pages/InstitutionArea'
import InternalError from './src/pages/InternalError'
import Index from './src/pages/LandingPage'
import LoginPage from './src/pages/Login'
import MonetizationPlans from './src/pages/MonetizationPlans'
import MonitorArea from './src/pages/MonitorArea'
import MonitorDetails from './src/pages/MonitorDetails'
import Monitoring from './src/pages/Monitoring'
import NotFound from './src/pages/NotFound'
import Profile from './src/pages/Profile'
import PromptsPage from './src/pages/Prompts'
import ResetPassword from './src/pages/ResetPassword'
import ResetPasswordConfirmation from './src/pages/ResetPasswordConfirmation'
import SchoolArea from './src/pages/SchoolArea'
import SchoolDetails from './src/pages/SchoolDetails'
import SignUpPage from './src/pages/SignUpPage'
import StudentArea from './src/pages/StudentArea'
import StudentDetails from './src/pages/StudentDetails'
import TeacherArea from './src/pages/TeacherArea'
import TeacherDetails from './src/pages/TeacherDetails'
import ThemeArea from './src/pages/ThemeArea'
import ThemeDetails from './src/pages/ThemeDetails'
import { theme } from './src/theme'

function App() {
  const toast = useToast()

  function callToast(toastBody) {
    toast(toastBody)
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path={'/cadastro'} element={<SignUpPage />} />
          <Route path="/confirm-email" element={<AccountConfirmation />} />

          <Route path="/usuarios" element={<InitialPageOwner />} />
          <Route path="/planos" element={<MonetizationPlans />} />
          <Route path="/prompts" element={<PromptsPage />} />
          <Route path="/monitoramento" element={<Monitoring />} />

          <Route path="/inicio-professor" element={<InitialPageTeacher />} />
          <Route path="/inicio-aluno" element={<InitialPageStudent />} />
          <Route path="/inicio-adm" element={<InitialPageAdm />} />
          <Route path={'/inicio-professor-independente'} element={<InitialPageIndependentTeacher />} />

          <Route path="/area-de-atividades" element={<ActivityArea />} />
          <Route path="/detalhamento-atividade/:atividadeID" element={<ActivityDetails />} />
          <Route path="/criar-atividade" element={<CreateActivity />} />

          <Route path="/area-de-turmas" element={<GroupsArea />} />
          <Route path="/detalhamento-turma/:turmaID" element={<DetalhamentoTurma />} />
          <Route path="/criar-turma" element={<CreateGroup />} />

          <Route path="/detalhamento-aluno/:alunoID" element={<StudentDetails />} />
          <Route path="/redacao" element={<EssayCreation />} />
          <Route path="/correcao-redacao/:id" element={<EssayGrading />} />

          <Route path="/area-de-temas" element={<ThemeArea />} />
          <Route path="/detalhamento-tema/:temaID" element={<ThemeDetails />} />
          <Route path="/criar-tema" element={<CreateTheme />} />

          <Route path="/recuperar-senha" element={<ResetPassword />} />
          <Route path="/recuperar-senha-confirmacao/:tokenConfirmacao" element={<ResetPasswordConfirmation />} />

          <Route path={'/area-de-instituicoes'} element={<AreaDeInstituicoes />} />
          <Route path={'/criar-instituicao'} element={<CreateInstitution />} />

          <Route path={'/area-de-monitores'} element={<MonitorArea />} />
          <Route path={'/criar-monitor'} element={<CreateMonitor />} />
          <Route path={'/detalhamento-monitor/:monitorID'} element={<MonitorDetails callToast={callToast} />} />

          <Route path={'/area-de-escolas'} element={<SchoolArea />} />
          <Route path={'/detalhamento-escola/:schoolId'} element={<SchoolDetails />} />
          <Route path={'/criar-escola'} element={<CreateSchool />} />

          <Route path={'/area-de-redacoes'} element={<EssayArea />} />

          <Route path={'/area-de-alunos'} element={<StudentArea />} />

          <Route path="/internal-error" element={<InternalError />} />

          <Route path={'/detalhamento-professor/:teacherId'} element={<TeacherDetails />} />
          <Route path={'/area-de-professores'} element={<TeacherArea />} />

          <Route path="/profile" element={<Profile />} />
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

export default App
