import './ProgressCard.css'
import { Text, VStack } from '@chakra-ui/react'

function ProgressCard(props) {
  const {
    className,
    redacoesSubmetidas,
    redacoesRevisadas,
    redacoesConcluidas,
    redacoesTotal,
    progressPercentageBlue,
    progressPercentageYellow,
    progressPercentageGreen,
  } = props

  return (
    <VStack width="100%" gap={4} align="start" className={className || ''}>
      <Text paddingTop={3} className="publicsans-bold-absolute-zero-16px">
        Andamento
      </Text>
      <div className="atvd">
        <div className="frame-2434-1">
          <div className={'rectangle-10 in-grading-essay-status'} style={{ width: `${progressPercentageBlue}%` }} />
        </div>
        <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
          {`${redacoesSubmetidas}/${redacoesTotal} Redações submetidas`}
        </div>
      </div>
      <div className="atvd">
        <div className="frame-2434-1">
          <div className="rectangle-10 in-review-essay-status" style={{ width: `${progressPercentageYellow}%` }} />
        </div>
        <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
          {`${redacoesRevisadas}/${redacoesTotal} Redações revisadas`}
        </div>
      </div>
      <div className="atvd">
        <div className="frame-2434-1">
          <div className="rectangle-10 finished-essay-status" style={{ width: `${progressPercentageGreen}%` }} />
        </div>
        <div className="x25-atividades valign-text-middle publicsans-normal-nevada-16px">
          {`${redacoesConcluidas}/${redacoesTotal} Redações concluídas`}
        </div>
      </div>
    </VStack>
  )
}

export default ProgressCard
