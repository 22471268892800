/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface UpdateActivityViewModel {
  deadline?: string;
  /**
   * @maxLength 500
   * @nullable
   */
  description?: string | null;
  id: string;
  /**
   * @minLength 5
   * @maxLength 255
   */
  subject: string;
  teacherId?: string;
  validFrom?: string;
}
