import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  variants: {
    primary: () => ({
      backgroundColor: 'brand.200',
      color: '#fff',
      _hover: {
        backgroundColor: 'brand.250',
      },
      _loading: {
        _hover: {
          backgroundColor: '#0a41d6',
        },
      },
    }),
    vertical: () => ({
      flexDirection: 'column',
      padding: '35px 15px',
      borderRadius: '12px',
      fontWeight: '400',
      fontSize: '11px',
      maxWidth: '79px',
    }),
    'vertical-primary': () => ({
      ...Button.variants?.primary,
      ...Button.variants?.vertical,
      color: 'white',
    }),
    'vertical-outline': () => ({
      ...Button.variants?.vertical,
      border: '2px solid #CFD0D1',
      color: '#000',
    }),
    outline: () => ({
      bg: '#F9FAFF',
      borderRadius: '8px',
      borderColor: 'blue',
      border: '1px solid',
      color: 'brand.100',
    }),
  },
  defaultProps: {},
}
