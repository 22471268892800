import { useEffect, useState } from 'react'
import TitleLink from '../TitleLink'
import './EscolaCard.css'
import { Button, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import useNavigationStore from '../../store/useNavigationStore'

function EscolaCard(props) {
  const { itemData } = props
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  const [controleRedacao] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      handleRender()
    }

    fetchData()
  }, [])

  function handleRender() {
    // controleRedacoes()
  }

  return (
    <VStack borderRadius="lg" align="start" gap={0} alignContent="center" overflow="hidden" boxShadow="xl">
      <TitleLink
        titleContent={itemData.name}
        subTitleContent={itemData.address ? `${itemData.address.city} - ${itemData.address.state}` : 'Não informado'}
        subTitleName={'Cidade - UF'}
        image={itemData.thumbnail}
      />
      <div className="escola-card-body-style">
        {controleRedacao}
        <div className="frame-258-1">
          <div className="frame-2584-1">
            <Button
              variant={'primary'}
              padding={'24px'}
              width={'100%'}
              onClick={() => {
                setReturnTo('/area-de-escolas')
                navigate(`/detalhamento-escola/${itemData.id}`)
              }}>
              Ver detalhes
            </Button>
          </div>
        </div>
      </div>
    </VStack>
  )
}

export default EscolaCard
