import { Box, Button, Grid, GridItem, HStack, Heading, Input, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { createMonitorApi } from '../../apis/teacherApi/teacherApi'
import FormStepNotSelected from '../../components/FormStepNotSelected'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { cpfFormatter } from '../../utils/cpfFormatter'
import { emailRegex } from '../../utils/emailRegex'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'

function CreateMonitorStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { loggedUser } = useLoggedUserStore()

  const [monitorValue, setMonitorValue] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    documentNumber: '',
    language: loggedUser.user_language,
  })
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const createMonitor = () => {
    return new Promise((resolve) => {
      const result = createMonitorApi(monitorValue)
      resolve(result)
    })
  }

  const handleSubmit = async () => {
    if (monitorValue.name && monitorValue.email && emailRegex.test(monitorValue.email)) {
      await createMonitor().then((result) => {
        handlingNextStep(result)
      })
    } else {
      setNameError(!monitorValue.name)
      setEmailError(!monitorValue.email)
      setEmailError(!emailRegex.test(monitorValue.email))
    }
  }

  const handleTelefoneInputChange = (event) => {
    const formattedValue = phoneNumberFormatter(event.target.value)
    setMonitorValue({ ...monitorValue, phoneNumber: formattedValue })
  }

  function handleDocumentInputChange(event) {
    const formattedDocument = cpfFormatter(event)
    if (formattedDocument.length <= 14) {
      setMonitorValue({ ...monitorValue, documentNumber: formattedDocument })
    }
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-monitores'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar monitor
        </Heading>
        <VStack w={'100%'} height={'100%'} mt={'12px'} alignItems={'start'}>
          <StepsImage text={'Sobre o monitor'} number={1} />
          <Grid width={'100%'} gap={'32px'} mt={'32px'}>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <div className={`${nameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>{'Nome*'}</div>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                value={monitorValue.name}
                onChange={(e) => {
                  setNameError(false)
                  setMonitorValue({ ...monitorValue, name: e.target.value })
                }}
              />
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <div className={`${emailError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>{'Email*'}</div>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                value={monitorValue.email}
                onChange={(e) => {
                  setEmailError(false)
                  setMonitorValue({ ...monitorValue, email: e.target.value })
                }}
              />
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <div className={'publicsans-bold-nevada-16px'}>{'Telefone'}</div>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                value={monitorValue.phoneNumber}
                onChange={handleTelefoneInputChange}
              />
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <div className={'publicsans-bold-nevada-16px'}>{'CPF'}</div>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                value={monitorValue.documentNumber}
                onChange={handleDocumentInputChange}
              />
            </GridItem>
          </Grid>
          <Box mt={5} className="steps">
            <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'} />
          </Box>
        </VStack>
        <HStack display="flex" justifyContent="end" flexDir={{ md: 'row', base: 'column-reverse' }} w={'100%'} gap={3} my={6}>
          <Button variant={'outline'} height={'48px'} width={'100%'} onClick={handleReturn}>
            Voltar
          </Button>
          <Button variant={'primary'} onClick={handleSubmit} width={'100%'} height={'48px'}>
            Finalizar
          </Button>
        </HStack>
      </VStack>
    </VStack>
  )
}

export default CreateMonitorStep1
