export const variables = [
  {
    description: 'Nome da instituição',
    key: 'Institution',
  },
  {
    description: 'Lista das competências da instituição',
    key: 'Competencies',
  },
  {
    description: 'Nome do tema',
    key: 'Theme',
  },
  {
    description: 'Conteúdo da redação',
    key: 'Essay',
  },
  {
    description: 'Nota máxima da instituição',
    key: 'MaxGrade',
  },
]
