import { useEffect, useState } from 'react'
import './TeacherArea.css'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { getTeacherForAdmApi } from '../../apis/teacherApi/teacherApi'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import NoContentContainer from '../../components/NoContentContainer'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import { shiftMapping } from '../../utils/shiftENUM'
import { truncateName } from '../../utils/truncateName'
import Page from '../Page'

function TeacherArea() {
  const location = useLocation()

  const [teachers, setTeachers] = useState([])
  const [teachersData, setTeachersData] = useState([])
  const [filterText, setFilterText] = useState(location.state?.filterText || '')

  async function handleInitialData() {
    setTeachersData(await getTeacherForAdmApi())
  }

  useEffect(() => {
    handleInitialData()
  }, [])

  useEffect(() => {
    if (teachersData) {
      setTeachers(filterTeacherData(teachersData, filterText))
    }
  }, [filterText, teachersData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function handleText(text) {
    return <Text>{text}</Text>
  }

  function handleSchools(schools) {
    const schoolsToShow = schools.slice(0, 3)
    const hasMoreSchools = schools.length > 3

    return (
      <HStack width={'100%'} gap={3}>
        {schoolsToShow.map((school, key) => (
          <Text className={'group-background'} key={key}>
            {truncateName(school.name, 20)}
          </Text>
        ))}

        {hasMoreSchools && <div className={'group-more'}>...</div>}
      </HStack>
    )
  }

  function handleDetalhes(itemData) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-professor/${itemData.id}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  function filterTeacherData(teachersData, filterText) {
    return teachersData.filter((item) => {
      return (
        item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.phoneNumber?.includes(filterText) ||
        item.email?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.schools.find(
          (school) =>
            school.name.toLowerCase().includes(filterText.toLowerCase()) ||
            school.groups.find(
              (group) =>
                group.name.toLowerCase().includes(filterText.toLowerCase()) ||
                shiftMapping[group.shift].toLowerCase().includes(filterText.toLowerCase()),
            ),
        )
      )
    })
  }

  return (
    <Box className={'screen-container'}>
      <HStack width={'100%'} height={'100%'} gap={0}>
        <Menu />
        <Page authPage={true} allowedUserType={'adm'} />
        <VStack width={'100%'} maxHeight={'100vh'} alignSelf={'start'} overflow={'auto'}>
          <Headbar hideHeaderElements={true} />
          <Box className={'body-no-right'}>
            <Box backgroundColor={'brand.600'} width={'100%'} maxWidth={'100%'} padding={'24px'} borderRadius={'12px'}>
              <Text
                textAlign={{ base: 'center', md: 'start' }}
                fontSize={{ base: '25px', md: '40px' }}
                className={'publicsans-normal-absolute-zero-40px'}>
                Área de Professores
              </Text>
            </Box>
            <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
            {teachers.length === 0 ? (
              <NoContentContainer>Nenhum professor foi associado ao seu perfil ainda.</NoContentContainer>
            ) : (
              <SharedTablePagination
                data={teachers}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Nome',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.name)}</div>,
                  },
                  {
                    title: 'Email',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.email)}</div>,
                  },
                  {
                    title: 'Número de telefone',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.phoneNumber)}</div>,
                  },
                  {
                    title: 'Escolas',
                    render: (itemData) => <div className={'pagination-style'}>{handleSchools(itemData.schools)}</div>,
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <div>{handleDetalhes(itemData)}</div>,
                  },
                ]}
              />
            )}
          </Box>
        </VStack>
      </HStack>
    </Box>
  )
}

export default TeacherArea
