import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllAcitivitiesFromSchool = async (schoolId) => {
  const schoolID = schoolId || localStorage.getItem('schoolId')

  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/activity/school/${schoolID}`)

  return response.data.message
}

export const createActivityApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/activity/`, data)

  return response.data.message
}

export const getActivityByIdApi = async (activityId) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/activity/${activityId}`)
  return response.data.message
}

export const getStudentActivityByIdApi = async (activityId) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/student/activity/${activityId}`)

  return response.data.message
}

export const putActivityApi = async (data) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/activity`, data)

  return response.data.message
}

export const deleteActivityApi = async (themeId) => {
  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/activity/${themeId}`)

  return response.data.message
}

export const getActivityByTeacherIdApi = async (teacherId) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/activity/teacher/${teacherId}`)

  return response.data.message
}

export const getActivitiesByStudentIdApi = async (studentId) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/activity/student/${studentId}`)

  return response.data.message
}
