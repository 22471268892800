import {
  useDeleteApiV1EssayEssayIdCommentsCommentId,
  useGetApiV1EssayEssayIdComments,
  usePostApiV1EssayEssayIdComments,
  usePutApiV1EssayEssayIdUpdateCompetencyComment,
} from '@/services/essay'
import type { CompleteEssayViewModel, EssayCompetencyViewModel, NewCommentViewModel } from '@/services/types'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { CommentDrawer } from '../../components/CommentDrawer'
import { EssayMenu } from './EssayMenu'
import { EssayImageReview, type EssayImageReviewRef, type LineProps } from './EssayReviewArea/EssayImageReview'
import { EssayTextReview, type EssayTextReviewRef, type TextSelection } from './EssayReviewArea/EssayTextReview'

type EssayReviewProps = {
  essay: CompleteEssayViewModel
  isEssayImage: boolean
  competencies: EssayCompetencyViewModel[]
  isAbleToReview: boolean
}

export const EssayReview = (props: EssayReviewProps) => {
  const { essay, isEssayImage, competencies, isAbleToReview } = props
  const essayId = essay.id || '' // Empty id should be impossible
  const isEssayFinalized = essay?.status === 40
  const [markerSize, setMarkerSize] = useState(25)

  // Image
  const essayReviewImageRef = useRef<EssayImageReviewRef>(null)
  const [imageSelectionData, setImageSelectionData] = useState<LineProps | null>(null)

  // Text
  const essayReviewTextRef = useRef<EssayTextReviewRef>(null)
  const [textSelectionData, setTextSelectionData] = useState<TextSelection | null>(null)

  //Comment
  const { isOpen: commentDrawerIsOpen, onOpen: commentDrawerOnOpen, onClose: commentDrawerOnClose } = useDisclosure()
  const [selectedCommentId, setSelectedCommentId] = useState<string | null>()
  const { data: commentsResponse, refetch: refetchComments } = useGetApiV1EssayEssayIdComments(essay.id || '')
  const comments = commentsResponse?.data?.message || []

  const { mutate: putCompetencyAPI, isPending: isUpdatingCompetencyAPI } = usePutApiV1EssayEssayIdUpdateCompetencyComment({
    mutation: { onSuccess: () => refetchComments() },
  })

  const handleCommentMutationSuccess = () => {
    refetchComments()
    essayReviewImageRef?.current?.resetDraftLines()
    onCloseCommentDrawer()
  }

  const { mutate: saveCommentAPI, isPending: isSavingCommentAPI } = usePostApiV1EssayEssayIdComments({
    mutation: { onSuccess: handleCommentMutationSuccess },
  })

  const { mutate: deleteCommentAPI, isPending: isDeletingCommentAPI } = useDeleteApiV1EssayEssayIdCommentsCommentId({
    mutation: { onSuccess: handleCommentMutationSuccess },
  })

  function onCloseCommentDrawer() {
    if (isEssayImage) {
      setImageSelectionData(null)
      essayReviewImageRef?.current?.resetDraftLines()
    } else {
      setTextSelectionData(null)
      essayReviewTextRef.current?.resetDraftLines()
    }

    setSelectedCommentId(null)
    commentDrawerOnClose()
  }

  const updateCompetency = (commentId?: string, competencyId?: string) => {
    putCompetencyAPI({ essayId, data: { commentId, competencyId } })
  }

  const saveComment = (comment: string, competencyId: string, parentId?: string | null) => {
    const objectToBeSent: NewCommentViewModel = parentId
      ? { parentId }
      : { ...(isEssayImage ? imageSelectionData : textSelectionData) }

    const data = { comment, competencyId, ...objectToBeSent }
    saveCommentAPI({ essayId, data })
  }

  const deleteComment = async (commentId: string) => {
    deleteCommentAPI({ essayId, commentId })
  }

  return (
    <Flex position="relative" width="100%" height="100%" bgColor="gray.100">
      <EssayMenu
        essay={essay}
        isImage={isEssayImage}
        isAbleToReview={isAbleToReview}
        marker={{
          size: markerSize,
          setSize: setMarkerSize,
        }}
      />
      <CommentDrawer
        isOpen={commentDrawerIsOpen}
        allComments={comments}
        competencies={competencies}
        selectedCommentId={selectedCommentId}
        onCloseDrawer={onCloseCommentDrawer}
        isViewOnlyMode={isEssayFinalized}
        action={{
          save: saveComment,
          update: updateCompetency,
          delete: deleteComment,
          isDeleting: isDeletingCommentAPI,
          isUpdating: isUpdatingCompetencyAPI,
          isSaving: isSavingCommentAPI,
        }}
      />
      {isEssayImage ? (
        <EssayImageReview
          ref={essayReviewImageRef}
          lineHeight={markerSize}
          imageUrl={essay.attachment || ''}
          lines={comments}
          isViewOnlyMode={isEssayFinalized}
          onAddNewLine={(newLine) => {
            setSelectedCommentId('')
            setImageSelectionData(newLine)
            commentDrawerOnOpen()
          }}
          onClickLine={(id) => {
            setSelectedCommentId(id)
            commentDrawerOnOpen()
          }}
        />
      ) : (
        <EssayTextReview
          ref={essayReviewTextRef}
          essay={essay}
          lines={comments}
          isViewOnlyMode={isEssayFinalized}
          onAddNewLine={(newSelection) => {
            setSelectedCommentId('')
            setTextSelectionData(newSelection)
            commentDrawerOnOpen()
          }}
          onClickLine={(id) => {
            setSelectedCommentId(id)
            commentDrawerOnOpen()
          }}
        />
      )}
    </Flex>
  )
}
