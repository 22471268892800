import { Box, Text } from '@chakra-ui/react'

function ActiveStatusCard(props) {
  const { imagePath, title, amount } = props

  return (
    <Box className="active-status-card-style">
      <img className="folder-2851794-area-de-turmas" src={imagePath} alt="folder-icon" />
      <Text className="publicsans-bold-nevada-14px">{title}</Text>
      <div className="number-1-area-de-turmas publicsans-extra-bold-absolute-zero-32px">{amount}</div>
    </Box>
  )
}

export default ActiveStatusCard
