import {
  Button,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { getStudentInformation } from '../../apis/groupApi/GroupApi'
import { NovoAlunoForm } from './NovoAlunoForm'
import { NovoAlunoUpload } from './NovoAlunoUpload'

export default function ConvidarAlunoPopover(props) {
  const { turmaID, schoolId, setStudentData } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const popoverRef = useRef<HTMLDivElement>(null)

  async function fetchStudents() {
    await Promise.all([getStudentInformation(turmaID, schoolId)]).then(([studentsInformation]) => {
      setStudentData(studentsInformation)
    })
  }

  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  })

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end" closeOnBlur={true}>
      <PopoverTrigger>
        <Button
          leftIcon={<Image src="/img/ic-add-1.svg" className="ic-add-detalhamento-tema" />}
          backgroundColor={'#0A41D6'}
          color={'white'}
          height={'48px'}
          onClick={onOpen}>
          <Text hideBelow={'md'}> Adicionar </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent ref={popoverRef} maxWidth={'448px'} width={['270px', 'fit-content']} borderRadius={'10px'}>
        <PopoverArrow boxShadow="-1px -1px 1px 0 black !important" />
        <PopoverCloseButton zIndex={2} />
        <PopoverBody>
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList width={'80%'}>
              <Tab> Novo Aluno </Tab>
              <Tab> Convidar lista de alunos </Tab>
            </TabList>
            <TabPanels>
              <NovoAlunoForm turmaID={turmaID} schoolId={schoolId} fetchStudents={fetchStudents} />
              <NovoAlunoUpload turmaID={turmaID} schoolId={schoolId} fetchStudents={fetchStudents} />
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
