import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import './CreateGroup.css'
import useCreateGroupStore from '../../store/useCreateGroupStore'
import useNavigationStore from '../../store/useNavigationStore'
import useStatusStore from '../../store/useStatusStore'
import Page from '../Page'
import SuccessfulCreationPage from '../SuccessfulCreationPage'
import CreateGroupStep1 from './CreateGroupStep1'

const CreateGroup = () => {
  const navigate = useNavigate()
  const { currentStep, groupId, setCurrentStep, cleanCriarTurmaData } = useCreateGroupStore()
  const { setIsSuccess } = useStatusStore()
  const { setReturnTo } = useNavigationStore()

  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1)
      setIsSuccess(true)
      setReturnTo('/area-de-turmas')
    } else {
      navigate(`/detalhamento-turma/${groupId}`)
      cleanCriarTurmaData()
    }
  }

  const handlePreviousStep = () => {
    navigate('/area-de-turmas')
    setCurrentStep(1)
    cleanCriarTurmaData()
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <CreateGroupStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case 2:
        return (
          <SuccessfulCreationPage
            handleRedirectReturn={handlePreviousStep}
            handleRedirectDetails={handleNextStep}
            listText={'Voltar'}
            headbarLink={'/area-de-turmas'}
            detailsText={'Ver turma'}
            entityCreated={'Turma nova'}
          />
        )
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CreateGroup
