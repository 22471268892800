import submitConfirmImage from '@/assets/submit-confirm.png'
import { Button, Heading, Img, VStack } from '@chakra-ui/react'
import Headbar from '../../components/Headbar'

function SuccessfulCreationPage({
  handleRedirectReturn,
  handleRedirectDetails,
  entityCreated,
  detailsText,
  listText,
  headbarLink,
}) {
  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={headbarLink} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Img width="300px" height="auto" src={submitConfirmImage} />
        <Heading size="xl" fontWeight="normal" color="brand.200">
          Você criou uma
        </Heading>
        <Heading size="xl" color="brand.200">
          {entityCreated}
        </Heading>
        {detailsText ? (
          <Button size="lg" minW="300px" onClick={handleRedirectDetails} variant="primary" mt={12}>
            {detailsText}
          </Button>
        ) : (
          ''
        )}
        <Button size="lg" minW="300px" onClick={handleRedirectReturn} variant="outline" mt={5}>
          {listText}
        </Button>
      </VStack>
    </VStack>
  )
}

export default SuccessfulCreationPage
