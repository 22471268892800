import './InternalError.css'
import errorImage from '../../assets/internal-error.png'

const InternalError = () => {
  return (
    <div className="internal-error">
      <img src={errorImage} alt="Erro Interno" className="error-image" />
      <p className="error-text publicsans-normal-absolute-zero-24px">
        Estamos com instabilidade no sistema, por favor tente novamente mais tarde.
      </p>
    </div>
  )
}

export default InternalError
