import DateWithCalendarIcon from '@/components/DateWithCalendarIcon'
import ProgressCard from '@/components/ProgressCard'
import useLoggedUserStore from '@/store/useLoggedUserStore'
import useNavigationStore from '@/store/useNavigationStore'
import { Box, Button, Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function ActivityCard(props) {
  const { itemData } = props

  const { loggedUser } = useLoggedUserStore()

  const currentDate = new Date()
  const deadLineDate = new Date(itemData.deadline)
  const startDate = new Date(itemData.validFrom)
  const startDateText = currentDate >= startDate ? 'Iniciou em:' : 'Inicia em:'
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  const [controleRedacao, setcontroleRedacao] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      handleRender()
    }

    fetchData()
  }, [])

  function handleRender() {
    handleEstatisticaRedacoes()
  }

  function calculoProgressBar(redacoes, redacoesTotais) {
    if (redacoes === 0 && redacoesTotais === 0) {
      return 0
    }
    const result = (redacoes / redacoesTotais) * 100
    return result
  }

  function handleEstatisticaRedacoes() {
    const amountEssaysSubmittedLength = itemData.amountEssaysSubmitted
    const amountEssaysReviewedLength = itemData.amountEssaysReviewed
    const amountEssaysClosedLength = itemData.amountEssaysClosed
    const lengthTotal = amountEssaysSubmittedLength + amountEssaysReviewedLength + amountEssaysClosedLength

    const progressBarBlue = calculoProgressBar(amountEssaysSubmittedLength, lengthTotal)
    const progressBarYellow = calculoProgressBar(amountEssaysReviewedLength, lengthTotal)
    const progressBarGreen = calculoProgressBar(amountEssaysClosedLength, lengthTotal)

    const result = (
      <ProgressCard
        progressPercentageBlue={progressBarBlue}
        progressPercentageYellow={progressBarYellow}
        progressPercentageGreen={progressBarGreen}
        redacoesSubmetidas={amountEssaysSubmittedLength}
        redacoesRevisadas={amountEssaysReviewedLength}
        redacoesConcluidas={amountEssaysClosedLength}
        redacoesTotal={lengthTotal}
      />
    )

    setcontroleRedacao(result)
  }

  return (
    <VStack borderRadius="lg" align="start" alignContent="center" overflow="hidden" boxShadow="xl">
      <Box p={6}>
        <Text className="publicsans-bold-absolute-zero-16px" noOfLines={1}>
          {itemData.subject}
        </Text>
        <Box mt={4}>
          <Text className="publicsans-bold-nevada-10px">TEMA</Text>
          <Text className="publicsans-normal-nevada-14px" noOfLines={1}>
            {itemData.theme.name}
          </Text>
        </Box>
      </Box>
      <VStack p={6} gap={6} bgColor="white" height="100%" width="100%">
        {loggedUser.user_role !== 'student' && (
          <>
            {controleRedacao}
            <Divider w={'85%'} color={'brand.400'} />
          </>
        )}
        <HStack
          width="100%"
          justifyContent={'space-between'}
          gap={{ md: 10, base: 5 }}
          flexDir={{ md: 'row', base: 'column' }}
          alignItems={'start'}>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-16px">{startDateText}</Text>
            <DateWithCalendarIcon imagePath={'/img/calendar-icon.svg'} date={startDate} />
          </VStack>
          <VStack gap={1} align="start" flexGrow={1}>
            <Text className="publicsans-normal-nevada-16px">Entrega final:</Text>
            <DateWithCalendarIcon imagePath={'/img/calendar-icon.svg'} date={deadLineDate} shouldHighlight={true} />
          </VStack>
        </HStack>
        <Spacer />
        <Button
          variant={'primary'}
          padding={'24px'}
          width={'100%'}
          onClick={() => {
            setReturnTo('/area-de-atividades')
            navigate(`/detalhamento-atividade/${itemData.id}`)
          }}>
          Ver detalhes
        </Button>
      </VStack>
    </VStack>
  )
}

export default ActivityCard
