import './SchoolArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1School } from '@/services/school'
import { useState } from 'react'
import EscolaCard from '../../components/EscolaCard'
import NoContentContainer from '../../components/NoContentContainer'
import SharedCardPagination from '../../components/SharedCardPagination'
import Page from '../Page'

function SchoolArea() {
  const itemsPerPage = window.innerWidth > 2390 ? 9 : 10
  const [filterText, setFilterText] = useState('')
  const getApiV1School = useGetApiV1School()
  const schoolsData = getApiV1School.data?.data?.message
  const schools =
    schoolsData?.length > 0 ? schoolsData.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase())) : []

  function handleSearchInputChange(e) {
    const inputText = e.target.value
    setFilterText(inputText)
  }

  return (
    <Page authPage={true} allowedUserType={'adm'}>
      <LayoutMenu isLoading={false}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Escolas"
            button={{
              isVisible: true,
              navigateTo: '/criar-escola',
              label: 'Criar escola',
            }}
          />
          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
          <div className="school-cards-container">
            {schools.length === 0 ? (
              <NoContentContainer>Nenhuma escola foi associado ao seu perfil ainda.</NoContentContainer>
            ) : (
              <SharedCardPagination data={schools} RenderComponent={EscolaCard} itemsPerPage={itemsPerPage} />
            )}
          </div>
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default SchoolArea
