import useNavigationStoreHistory from '@/store/useNavigationStoreHistory'
import { useEffect } from 'react'

export const useAddToHistory = () => {
  const { addToHistory } = useNavigationStoreHistory()

  useEffect(() => {
    addToHistory(location.pathname)
  }, [addToHistory])
}

export const useIsHistoryEmpty = () => {
  const { addToHistory, resetHistory, isHistoryEmpty } = useNavigationStoreHistory()

  useEffect(() => {
    if (!isHistoryEmpty()) {
      resetHistory()
    }
    addToHistory(location.pathname)
  }, [location.pathname, addToHistory, resetHistory, isHistoryEmpty])
}
