import './GroupDetails.css'
import { VStack } from '@chakra-ui/react'
import Headbar from '../../components/Headbar'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import GroupDetailsStudent from './GroupDetailsStudent'
import GroupDetailsTeacher from './GroupDetailsTeacher'

function DetalhamentoTurma() {
  const { loggedUser } = useLoggedUserStore()

  function isStudent() {
    return loggedUser.user_role === 'student'
  }

  const returnUrl = loggedUser.user_role === 'adm' ? `/detalhamento-escola/${loggedUser.schoolId}` : '/area-de-turmas'

  return (
    <div className="details-container">
      <VStack width={'100%'} height={'100%'} alignSelf={'start'} gap={0}>
        <Headbar returnTo={returnUrl} />
        {isStudent() ? <GroupDetailsStudent /> : <GroupDetailsTeacher returnUrl={returnUrl} />}
      </VStack>
    </div>
  )
}

export default DetalhamentoTurma
