import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export async function getAllPlansApi() {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/payment/list-all-payment-plans`)

  return response.data.message
}

export async function createNewPlanApi(data) {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/payment/payment-plan`, data)

  return response.data.message
}

export async function updatePlanApi(data) {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/payment/payment-plan`, data)

  return response.data.message
}

export async function getUserPlanApi(userId) {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/payment/user/${userId}/payment-plan`)

  return response.data.message
}
