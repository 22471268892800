import {
  Box,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Text,
  keyframes,
  useDisclosure,
} from '@chakra-ui/react'
import Calendar from '../../components/Calendar'
import useLoggedUserStore from '../../store/useLoggedUserStore'

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

function CalendarComponent({ activities }) {
  const { loggedUser } = useLoggedUserStore()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const animation = `${slideInFromRight} 1s ease-out`

  if (loggedUser.user_role === 'owner') {
    return null
  }

  const handleOnOpenNotifications = () => {
    onOpen()
  }

  return (
    <>
      <IconButton
        aria-label="calendar-icon"
        isRound={true}
        color="brand.200"
        variant="ghost"
        hideFrom={'md'}
        pos={'relative'}
        animation={animation}
        backgroundColor={'white'}
        zIndex={1}
        position="absolute"
        right={-4}
        top={-4}
        px={2}
        py={6}
        boxShadow="lg"
        onClick={handleOnOpenNotifications}>
        <Box pos="relative">
          <Image src={'/img/calendar-icon.svg'} alt="Vector" />
        </Box>
      </IconButton>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent minW="300px" overflowY={'auto'}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text color="gray.400" fontSize="sm">
              Calendário de Atividades
            </Text>
          </DrawerHeader>
          <Divider borderColor="gray.300" />
          <Calendar atividades={activities} />
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CalendarComponent
