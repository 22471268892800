import { useEffect, useState } from 'react'
import './MonitorDetails.css'
import { Box, HStack, Image, Text, VStack, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getActivityByTeacherIdApi } from '../../apis/activitityApi/activityApi'
import { getTeacherByIdApi } from '../../apis/teacherApi/teacherApi'
import Headbar from '../../components/Headbar'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import Page from '../Page'
import { ModalEditMonitor } from './ModalEditMonitor'
import { PopoverRemoveMonitor } from './PopoverRemoveMonitor'

function MonitorDetails() {
  const navigate = useNavigate()
  const { monitorID } = useParams()
  const [monitorData, setMonitorData] = useState({})
  const [activitiesData, setActivitiesData] = useState([])
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('atividades')
  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getTeacherByIdApi(monitorID), getActivityByTeacherIdApi(monitorID)]).then(
        ([monitorResult, activitiesResult]) => {
          resolve({ monitorResult, activitiesResult })
        },
      )
    })
  }

  useEffect(() => {
    fetchOptions().then(({ monitorResult, activitiesResult }) => {
      setMonitorData(monitorResult)
      setActivitiesData(activitiesResult)
      setLoading(false)
    })
  }, [])

  function fetchMonitor() {
    Promise.all([getTeacherByIdApi(monitorID)]).then(([monitorResult]) => {
      setMonitorData(monitorResult)
    })
  }

  useEffect(() => {
    if (monitorData) {
      setActivities(
        activitiesData
          ? activitiesData.filter((item) => {
              return (
                item.subject.toLowerCase().includes(filterText.toLowerCase()) ||
                item.theme.name.toLowerCase().includes(filterText.toLowerCase())
              )
            })
          : [],
      )
    }
  }, [filterText, activitiesData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function HandleDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-atividade/${activityId}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function HandleAtividadeColumn({ atividadeName }) {
    return <div className={'table-cell-container publicsans-normal-nevada-14px'}>{atividadeName}</div>
  }

  function HandleTemaColumn({ temaName }) {
    return <div className={'table-cell-container publicsans-normal-nevada-14px'}>{temaName}</div>
  }

  function HandleEntregasColumn({ item }) {
    if (!item) {
      return <div className={'table-cell-container'} />
    }
    const countSubmitted = item.amountEssaysSubmitted
    const countReviewed = item.amountEssaysReviewed
    const countClosed = item.amountEssaysClosed

    const totalCount = countSubmitted + countReviewed + countClosed

    return (
      <div className={'table-cell-container '}>
        <div className={'blue-background-cell-column publicsans-bold-absolute-zero-12px'}>{totalCount} Redações entregues</div>
      </div>
    )
  }

  function HandleTurmasVinculadas() {
    return loading
      ? ''
      : monitorData?.schools[0].groups?.map((item, key) => {
          return (
            <WrapItem key={key}>
              <Text className={'group-background'} whiteSpace={'nowrap'}>
                <p
                  className="theme-name-detalhamento-monitor publicsans-normal-nevada-14px"
                  onClick={() => navigate(`/detalhamento-turma/${item.id}`)}>
                  {item.name}
                </p>
              </Text>
            </WrapItem>
          )
        })
  }

  return (
    <div className={'details-container'}>
      <VStack width={'100%'} alignSelf={'start'} gap={0}>
        <Page authPage={true} allowedUserType={'teacher independentteacher'} />
        <Headbar returnTo={'/area-de-monitores'} />
        <HStack
          width={'90%'}
          flexDir={{ md: 'row', base: 'column' }}
          gap={'16px'}
          justifyContent={'space-between'}
          className={'content-max-size'}>
          <VStack gap={5} pb={5} className={'detalhamento-monitor-body'}>
            <VStack className="atividade-detalhamento-monitor">
              <HStack className="frame-2594-detalhamento-monitor" flex={1}>
                <VStack width={'100%'} alignItems={'flex-start'} flex={1}>
                  <Text className={'publicsans-bold-nevada-14px'}>MONITOR</Text>
                  <Text fontSize={{ md: '24px', base: '18px' }} className="publicsans-normal-absolute-zero-24px">
                    {loading ? '' : monitorData.name}
                  </Text>
                </VStack>
                <HStack w={'100%'} justifyContent={'flex-end'} flex={0.5} flexDir={{ md: 'row', base: 'column' }}>
                  <Text alignSelf={'center'} className="publicsans-bold-nevada-14px">
                    Editar
                  </Text>
                  <Image
                    className="mode-edit-detalhamento-monitor"
                    src="/img/mode-edit@2x.png"
                    alt="Mode edit"
                    onClick={modalDisclosure.onOpen}
                  />
                  {!loading && monitorData?.schools[0] && (
                    <ModalEditMonitor
                      isOpen={modalDisclosure.isOpen}
                      fetchMonitor={fetchMonitor}
                      monitorData={monitorData}
                      onClose={modalDisclosure.onClose}
                    />
                  )}
                </HStack>
              </HStack>

              <HStack
                alignItems={'start'}
                justifyContent={'space-between'}
                width={'100%'}
                flexDir={{ md: 'row', base: 'column' }}>
                <Wrap alignItems={'start'} flexDir={{ md: 'row', base: 'column' }}>
                  <Text alignSelf={'center'} className="publicsans-bold-nevada-10px">
                    TURMAS VINCULADAS
                  </Text>
                  <HandleTurmasVinculadas />
                </Wrap>
                <HStack>
                  <div className={'publicsans-bold-nevada-14px'}> Apagar Monitor?</div>
                  <div>
                    {!loading && (
                      <PopoverRemoveMonitor
                        isOpen={popoverDisclosure.isOpen}
                        onOpen={popoverDisclosure.onOpen}
                        onClose={popoverDisclosure.onClose}
                        monitorID={monitorID}
                      />
                    )}
                  </div>
                </HStack>
              </HStack>
            </VStack>
            <Box width={'100%'} className="frame-2595-detalhamento-monitor">
              <div className="switch-detalhamento-monitor">
                <Box
                  className={`frame-2469-detalhamento-monitor ${selectedTab === 'atividades' ? 'tab-border' : ''}`}
                  onClick={() => changeSelectedTab('atividades')}>
                  <Text
                    className={`${selectedTab === 'atividades' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                    Atividades Vinculadas
                  </Text>
                </Box>
              </div>
            </Box>
            {selectedTab === 'atividades' && (
              <Box zIndex={'auto'} gap={5} width={'100%'}>
                <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

                {activities.length ? (
                  <SharedTablePagination
                    data={activities}
                    itemsPerPage={5}
                    columns={[
                      {
                        title: 'Atividade',
                        render: (itemData) => <HandleAtividadeColumn atividadeName={itemData.subject} />,
                      },
                      {
                        title: 'Tema',
                        render: (itemData) => <HandleTemaColumn temaName={itemData.theme.name} />,
                      },
                      {
                        title: 'Entregas',
                        render: (itemData) => <HandleEntregasColumn item={itemData} />,
                      },
                      {
                        title: 'Detalhes',
                        render: (itemData) => <HandleDetails activityId={itemData.id} />,
                      },
                    ]}
                  />
                ) : (
                  <Box
                    backgroundColor={'#f3f5ff'}
                    width={'100%'}
                    borderRadius={'12px'}
                    minHeight={'100px'}
                    maxWidth={'100%'}
                    px={2}
                    mt={2}
                    alignContent={'center'}
                    textAlign={'center'}>
                    <Text className={'publicsans-normal-nevada-16px'}>
                      Ainda não foram criadas atividades para este monitor
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          </VStack>
          <Box mb={5} className="frame-2591-detalhamento-monitor">
            <h1 className="title-1-detalhamento-monitor publicsans-normal-resolution-blue-24px">Contato</h1>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>Email</div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/message-icon.svg'} />
                  <div className={'publicsans-normal-nevada-14px'}> {monitorData.email} </div>
                </HStack>
              </div>
            </div>
            <div>
              <div className={'publicsans-normal-nevada-16px'}>Telefone</div>
              <div>
                <HStack width={'100%'} marginTop={'8px'}>
                  <Image src={'/img/phone-icon.svg'} />
                  <div className={'publicsans-normal-nevada-14px'}>
                    {' '}
                    {monitorData.phoneNumber ? monitorData.phoneNumber : 'Não informado'}{' '}
                  </div>
                </HStack>
              </div>
            </div>
          </Box>
        </HStack>
      </VStack>
    </div>
  )
}

export default MonitorDetails
