import Calendar from '@/components/Calendar'
import { Box } from '@chakra-ui/react'

type ActivityAreaSidebarProps = {
  activities: object[]
  isInProgress: (activity: object) => boolean
}
export const ActivityAreaSidebar = (props: ActivityAreaSidebarProps) => {
  const { activities, isInProgress } = props

  function returnAmountOfInProgressActivities() {
    return activities.filter((item) => isInProgress(item)).length
  }

  return (
    <Box hideBelow={'md'} className="frame-2591">
      <div className="frame-2590">
        <div className="address valign-text-middle publicsans-normal-absolute-zero-20px">
          {`${returnAmountOfInProgressActivities()} Atividade(s) em andamento`}
        </div>
      </div>
      <img className="line-99" src={'/img/line-99@2x.png'} alt="Line 99" />

      <Calendar atividades={activities} />
    </Box>
  )
}
