import { create } from 'zustand'

export enum STEP_INSTITUTION {
  ABOUT = 1,
  CREATED = 2,
}

const useCreateInstitutionStore = create((set) => ({
  currentStep: STEP_INSTITUTION.ABOUT,
  createInstitutionData: {
    name: '',
    institutionGradeType: '0',
    maxGrade: '',
    schoolId: '',
  },
  setCurrentStep: (step) => set({ currentStep: step }),
  setCreateInstitutionData: (data) =>
    set((state) => ({
      createInstitutionData: { ...state.createInstitutionData, ...data },
    })),
  cleanCreateInstitutionData: () => set({ createInstitutionData: {}, currentStep: STEP_INSTITUTION.ABOUT }),
}))

export default useCreateInstitutionStore
