/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface NewIndependentTeacherViewModel {
  /** @nullable */
  documentNumber?: string | null;
  /** @minLength 1 */
  email: string;
  /** @nullable */
  firstGroupName?: string | null;
  groupShift?: number;
  institution?: number;
  /** @nullable */
  language?: string | null;
  /** @minLength 5 */
  name: string;
  /** @nullable */
  phoneNumber?: string | null;
}
