import { useState } from 'react'
import './ResetPasswordConfirmation.css'
import { changePasswordApi, logoff } from '../../apis/LoginApi'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'

const minPasswordLength = 8

function ResetPasswordConfirmation() {
  const [newPasswordError, setPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email, password, confirmPassword, newPassword } = document.forms[0]
    if (newPassword && newPassword === confirmPassword) {
      const response = await changePasswordApi({
        email,
        password,
        newPassword,
        confirmPassword,
      })
      switch (response.status) {
        case 200:
        case 201:
        case 204:
          logoff()
          break
        default:
          // Coloca textinho fofinho na tela
          break
      }
    } else {
      const isPasswordError = password.length <= minPasswordLength || password !== confirmPassword
      setPasswordError(isPasswordError)
      setConfirmPasswordError(confirmPassword.length < minPasswordLength)
    }
  }

  const handlePasswordChange = () => {
    setPasswordError(false)
  }

  const handleConfirmPasswordChange = () => {
    setConfirmPasswordError(false)
  }

  return (
    <div className="container-center-horizontal-recuperar-senha-confirmacao">
      <div className="cadastro-step01-recuperar-senha-confirmacao screen">
        <div className="overlap-group3-recuperar-senha-confirmacao">
          <HeaderCadastro />
        </div>
        <div className={'login-columns'}>
          <IlustracaoLogin />

          <form className={'login-body'} onSubmit={handleSubmit}>
            <div className="title-recuperar-senha-confirmacao">
              <h1 className="title-1-recuperar-senha-confirmacao valign-text-bottom">Atualizar senha</h1>
              <p className="entre-e-comece-a-agi-recuperar-senha-confirmacao publicsans-normal-nevada-24px">
                Insira uma nova senha com pelo menos 8 caracteres.
              </p>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className={'e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px'}>E-Mail</div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-login">
                    <div className="email-inner-login">
                      <input
                        className="text-login publicsans-normal-black-14px"
                        placeholder={'nome@dominio.com'}
                        type={'text'}
                        name="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className={'e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px'}>Senha atual</div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao" src={'/img/ic-senha.svg'} alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        placeholder={'Senha atual'}
                        type={'text'}
                        name="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div
                  className={`${confirmPasswordError || newPasswordError ? 'error-recuperacao-senha-confirmacao' : ''} e-mail-recuperar-senha-confirmacao publicsans-bold-nevada-16px`}>
                  Nova senha
                </div>
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao" src={'/img/ic-senha.svg'} alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        type={'text'}
                        name="newPassword"
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-2621-recuperar-senha-confirmacao">
              <div className="frame-26-recuperar-senha-confirmacao">
                <div className="text-imput-recuperar-senha-confirmacao">
                  <div className="email-box-recuperar-senha-confirmacao">
                    <div className="email-inner-recuperar-senha-confirmacao">
                      <img className="ic-email-recuperar-senha-confirmacao" src={'/img/ic-senha.svg'} alt="ic-email" />
                      <input
                        className={'text-recuperar-senha-confirmacao publicsans-normal-shady-lady-14px'}
                        placeholder={'Insira sua senha novamente'}
                        type={'text'}
                        name="confirmPassword"
                        onChange={handleConfirmPasswordChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons-container-recuperar-senha-confirmacao">
              <div className="mainbutton-1-recuperar-senha-confirmacao">
                <div className="content-1-recuperar-senha-confirmacao">
                  <input className="main-button-1-login publicsans-bold-white-14px" type={'submit'} value={'Salvar'} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordConfirmation
