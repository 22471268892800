import { AudioPlayer } from '@/components/AudioPlayer'
import { VoiceRecorder } from '@/components/VoiceRecorder'
import {
  useDeleteApiV1EssayEssayIdCommentsCommentId,
  useGetApiV1EssayEssayIdComments,
  usePostApiV1EssayEssayIdComments,
} from '@/services/essay'
import { EssayCommentType, type NewCommentViewModel } from '@/services/types'
import { blobToBase64 } from '@/utils/BlobToBase64'
import { Button, Center, HStack, Heading, Textarea, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { toast } from 'react-toastify'

type GeneralCommentProps = {
  isAbleToReview: boolean
  essayId: string
}

export const GeneralComment = (props: GeneralCommentProps) => {
  const { isAbleToReview, essayId } = props

  // Get
  const getCommentsAPI = useGetApiV1EssayEssayIdComments(essayId)
  const comments = getCommentsAPI.data?.data.message
  const generalComment = comments?.find((comment) => comment.competencyId === null)

  // Delete
  const handleDeleteSuccess = () => {
    resetGeneralComment()
    getCommentsAPI.refetch()
    toast.success('Comentário removido com sucesso!')
  }
  const deleteCommentsAPI = useDeleteApiV1EssayEssayIdCommentsCommentId({ mutation: { onSuccess: handleDeleteSuccess } })
  const [generalCommentText, setGeneralCommentText] = useState('')

  // Post
  const handlePostSuccess = () => {
    toast.success('Comentário geral salvo com sucesso!')
    getCommentsAPI.refetch()
  }
  const postCommentsAPI = usePostApiV1EssayEssayIdComments({ mutation: { onSuccess: handlePostSuccess } })

  // Handle audio
  const [audioUrl, setAudioUrl] = useState<string | null>()
  const [audioBase64, setAudioBase64] = useState<string | null>()
  const [showAudioPlayer, setShowAudioPlayer] = useState(false)
  const [audioIsRecording, setAudioIsRecording] = useState(false)

  const resetGeneralComment = () => {
    setAudioUrl(null)
    setAudioBase64(null)
    setShowAudioPlayer(false)
    setGeneralCommentText('')
  }

  const isAudioTooBig = (blob: Blob) => {
    const sizeInBytes = blob.size
    const sizeInMB = sizeInBytes / 1024 / 1024
    return sizeInMB > 1
  }

  const onRecordingComplete = (blob: Blob) => {
    const url = URL.createObjectURL(blob)
    if (isAudioTooBig(blob)) return toast.error('Áudio deve ser menor que 1mb')

    blobToBase64(blob)
      .then((result) => {
        const base64Data = result.split(',', 2)[1]
        setAudioBase64(base64Data)
      })
      .catch(() => {
        toast.error('Erro ao converter áudio blob para string')
      })

    setAudioUrl(url)
    setShowAudioPlayer(true)
    setAudioIsRecording(false)
  }

  async function saveGeneralComment(comment: string, commentType: EssayCommentType) {
    const data: NewCommentViewModel = {
      comment, // can be an audio (base64 string) or a text (string)
      commentType,
    }

    postCommentsAPI.mutate({ essayId, data })
  }

  const handleDeleteComment = () => {
    deleteCommentsAPI.mutate({ essayId, commentId: generalComment?.id || '' })
  }

  const submitComment = () => {
    if (!audioBase64 && !generalCommentText) return toast.warn('Adicione um comentário ou áudio')
    if (audioBase64) return saveGeneralComment(audioBase64, EssayCommentType.NUMBER_1)
    saveGeneralComment(generalCommentText, EssayCommentType.NUMBER_0)
  }

  const hasComment = !!generalComment?.comment
  const isAudio = generalComment?.commentType === EssayCommentType.NUMBER_1
  const isText = generalComment?.commentType === EssayCommentType.NUMBER_0
  const hasAudioRecordPreview = showAudioPlayer && audioUrl

  if (getCommentsAPI.isLoading) return null

  return (
    <VStack width={'100%'}>
      <VStack spacing={6} width={'100%'} maxW="800px" alignItems={'stretch'}>
        <Heading size={'md'} w={'100%'}>
          Comentários gerais
        </Heading>

        {/* Visualize added comment */}
        {hasComment && isAudio && <AudioPlayer src={generalComment.comment || ''} />}
        {hasComment && isText && (
          <Textarea
            backgroundColor="white"
            border="none"
            resize="none"
            minH="190px"
            placeholder="Escreva seu comentário..."
            value={generalComment.comment || ''}
            readOnly={true}
          />
        )}
        {!hasComment && !isAbleToReview && (
          <Center backgroundColor="gray.100" color="gray.400" borderRadius="xl" width="100%" minH="100px">
            Nenhum comentário adicionado
          </Center>
        )}

        {/* Add comment */}
        {!hasComment && hasAudioRecordPreview && isAbleToReview && (
          <AudioPlayer src={audioUrl} deleteAudio={resetGeneralComment} />
        )}

        {!hasComment && !hasAudioRecordPreview && isAbleToReview && (
          <>
            <VoiceRecorder onStartRecording={() => setAudioIsRecording(true)} onRecordingComplete={onRecordingComplete} />
            {!audioIsRecording && (
              <Textarea
                backgroundColor="white"
                borderColor="brand.300"
                placeholder="Escreva seu comentário..."
                value={generalCommentText}
                disabled={!isAbleToReview}
                onChange={(e) => {
                  setGeneralCommentText(e.target.value)
                }}
              />
            )}
          </>
        )}

        <HStack w={'100%'} justifyContent={'center'} height={'100%'}>
          {!generalComment && isAbleToReview && !audioIsRecording && (
            <Button
              variant={'primary'}
              isLoading={postCommentsAPI.isPending}
              maxWidth={'500px'}
              w={'100%'}
              height={'40px'}
              mb={5}
              onClick={submitComment}>
              Enviar Comentário
            </Button>
          )}

          {generalComment && isAbleToReview && (
            <Button
              maxWidth={'500px'}
              w={'100%'}
              isLoading={deleteCommentsAPI.isPending}
              colorScheme={'red'}
              onClick={handleDeleteComment}>
              Excluir Comentário
            </Button>
          )}
        </HStack>
      </VStack>
    </VStack>
  )
}
