import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import './styleguide.css'
import './globals.css'
import { queryClient } from '@/utils/queryClient'
import { QueryClientProvider } from '@tanstack/react-query'
import { StrictMode } from 'react'

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement)
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastContainer stacked position="bottom-left" />
      <App />
    </QueryClientProvider>
  </StrictMode>,
)
