import './UserProfileHeadbarComponent.css'
import { Box, Text } from '@chakra-ui/react'
import { useGetApiV1AccountAdm } from '../../services/auth'
import { useGetApiV1Student } from '../../services/student'
import { useGetApiV1Teacher } from '../../services/teacher'
import useLoggedUserStore from '../../store/useLoggedUserStore'

function UserProfileHeadbarComponent(_props) {
  const { loggedUser } = useLoggedUserStore()
  const LOGGED_USER_ROLE = {
    student: 'Aluno',
    teacher: 'Professor',
    independentteacher: 'Professor',
    monitor: 'Monitor',
    adm: 'Administrador',
    owner: 'Owner',
  }

  let useGetProfile

  if (loggedUser.user_role === 'student') {
    useGetProfile = useGetApiV1Student
  } else if (loggedUser.user_role === 'teacher' || loggedUser.user_role === 'monitor') {
    useGetProfile = useGetApiV1Teacher
  } else if (loggedUser.user_role === 'independentteacher') {
    useGetProfile = useGetApiV1Teacher
  } else if (loggedUser.user_role === 'adm') {
    useGetProfile = useGetApiV1AccountAdm
  } else if (loggedUser.user_role === 'owner') {
    useGetProfile = () => ({ data: { data: { message: { name: 'dev' } } } })
  }

  const { data: response } = useGetProfile()
  const profile = response?.data?.message

  return (
    <Box hideBelow={'md'} className="frame-2553">
      <img className="avatar-photo-02" src="/img/avatar---photo-02-20@2x.png" alt="avatar - photo 02" />
      <Box className="frame-2581">
        <Text className="publicsans-bold-nevada-10px">{LOGGED_USER_ROLE[loggedUser.user_role]}</Text>
        <Text noOfLines={1} className="publicsans-normal-black-14px">
          {profile?.name}
        </Text>
      </Box>
    </Box>
  )
}

export default UserProfileHeadbarComponent
