export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = reject
  })

export const base64ToBlob = async (base4) => {
  const header = 'data:image/png;base64,'
  const base64Response = await fetch(`${header}${base4}`)
  return await base64Response.blob()
}
