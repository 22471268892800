import { Box, Button, HStack, Image, Link, Spacer, VStack } from '@chakra-ui/react'
import './SupportMaterialList.css'
import { useDeleteApiV1ThemeSupportMaterialMaterialId } from '../../services/theme'

function SupportMaterialList(props) {
  const { supportMaterialList, deleteType, refreshSupportMaterialList, handleRemoveItem, isDetail = true } = props

  function HandleSupportMaterial() {
    return supportMaterialList.map((item, key) => {
      const { mutate: deleteSupportMaterial, isPending: isDeletingSupportMaterial } =
        useDeleteApiV1ThemeSupportMaterialMaterialId({
          mutation: {
            onSuccess: () => {
              refreshSupportMaterialList()
            },
          },
        })

      const itemName = isDetail ? `${item.title}.${item.fileName.split('.').pop()}` : `${item.title}.${item.extension}`

      return (
        <HStack spacing={4} className={'support-material-file'} key={key}>
          <HStack width={'100%'}>
            <Link color="gray.500" display="flex" gap={2} href={isDetail ? item.value : null} download>
              <Image src={'/img/paper-upload.svg'} marginLeft={'16px'} />
              {itemName}
            </Link>
            <Spacer />
            <Box>
              <Button
                onClick={() => {
                  if (deleteType === 'detalhamentoTema') return deleteSupportMaterial({ materialId: item.id })
                  if (deleteType === 'criarTema') return handleRemoveItem(item.title)
                }}
                marginRight="16px"
                cursor="pointer"
                isLoading={isDeletingSupportMaterial}
                variant="ghost">
                <Image src="/img/deleteIcon.svg" alt="Delete Icon" boxSize="20px" />
              </Button>
            </Box>
          </HStack>
        </HStack>
      )
    })
  }

  return (
    <VStack width={'100%'}>
      <HandleSupportMaterial />
    </VStack>
  )
}

export default SupportMaterialList
