import './FormStepNotSelected.css'

function FormStepNotSelected(props) {
  const { number, configuraesDaAtividade } = props

  return (
    <div className="frame-255">
      <div className="frame-2483">
        <div className="number-42 publicsans-normal-shady-lady-20px">{number}</div>
      </div>
      <div className="publicsans-normal-shady-lady-20px">{configuraesDaAtividade}</div>
    </div>
  )
}

export default FormStepNotSelected
