import type { EssayCompetencyViewModel } from '@/services/types'
import { HStack, Heading, Input, Stack } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'

type GradeProps = {
  competencies: EssayCompetencyViewModel[]
  isAbleToReview?: boolean
  setNewGrade?: (e: ChangeEvent<HTMLInputElement>) => void
  totalGrade: number
}

export const Grades = (props: GradeProps) => {
  const { competencies, isAbleToReview, totalGrade, setNewGrade } = props
  return (
    <>
      <Heading fontSize="sm">Avaliação das competências</Heading>
      <Stack borderRadius="8px" border="1px solid #F9FAFF" bgColor="gray.50" shadow="lg">
        <Stack py={2} px={6}>
          {competencies.map((competency) => (
            <HStack key={competency.id} justifyContent="space-between">
              <Heading fontSize="sm" color="gray.500">
                {competency.name}
              </Heading>
              <Input
                id={competency.id}
                name={competency.name || ''}
                borderWidth={isAbleToReview ? 1 : 0}
                borderColor="gray.200"
                bgColor={isAbleToReview ? 'white' : 'none'}
                color={isAbleToReview ? 'black' : 'gray.500'}
                width="100px"
                textAlign="right"
                readOnly={!isAbleToReview}
                pointerEvents={isAbleToReview ? 'auto' : 'none'}
                value={competency.grade}
                onChange={setNewGrade}
                onFocus={(e) => e.target.select()}
              />
            </HStack>
          ))}
        </Stack>
        <HStack py={2} px={6} justifyContent="space-between" bgColor="gray.100">
          <Heading fontSize="xs">Nota final</Heading>
          <Input
            borderColor="gray.200"
            width="100px"
            bgColor={isAbleToReview ? 'white' : 'none'}
            borderWidth={isAbleToReview ? 1 : 0}
            color={isAbleToReview ? 'black' : 'gray.500'}
            readOnly
            pointerEvents="none"
            textAlign="right"
            value={totalGrade}
          />
        </HStack>
      </Stack>
    </>
  )
}
