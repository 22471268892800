import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Spacer,
  Stack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { postApiV1AccountAdm } from '../../services/auth'
import { inputCNPJMask, inputPhoneMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  name: yup.string().min(5).max(255).required(),
  phoneNumber: yup.string().phone().required(),
  documentNumber: yup.string().cnpj().required(),
  email: yup.string().email().required(),
})

export const NewAdminModalBody = ({ onClose }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema) })

  async function submit(body) {
    try {
      await postApiV1AccountAdm(body)
      toast.success(`${body.email} cadastrado!`)
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} noValidate>
      <Stack spacing={6}>
        <Stack>
          <Heading size="sm">Detalhes</Heading>
          <Grid templateColumns="repeat(6, 1fr)" gap={5}>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <FormControl isInvalid={errors.name?.message}>
                <FormLabel>Nome</FormLabel>
                <Input id="name" {...register('name')} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <FormControl isInvalid={errors.phoneNumber?.message}>
                <FormLabel>Telefone</FormLabel>
                <Input id="phoneNumber" {...register('phoneNumber')} as={InputMask} mask={inputPhoneMask} />
                <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <FormControl isInvalid={errors.documentNumber?.message}>
                <FormLabel>CNPJ</FormLabel>
                <Input id="documentNumber" {...register('documentNumber')} as={InputMask} mask={inputCNPJMask} />
                <FormErrorMessage>{errors.documentNumber?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ sm: 3, lg: 2 }}>
              <FormControl>
                <FormLabel>Idioma</FormLabel>
                <Select id="language" {...register('language')}>
                  <option value="pt-BR">Português (Brasil)</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Divider />
        <Stack>
          <Heading size="sm">Autenticação</Heading>
          <Grid templateColumns="repeat(2, 1fr)" gap={5}>
            <GridItem>
              <FormControl isInvalid={errors.email?.message}>
                <FormLabel>Email</FormLabel>
                <Input id="email" {...register('email')} autoComplete="false" type="email" />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Flex gap={2} my={4} alignItems="center">
          <Spacer />
          <Button type="submit" variant="primary" isLoading={isSubmitting}>
            Cadastrar
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
