import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { useState } from 'react'
import { createTeacherApi } from '../../apis/teacherApi/teacherApi'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { buildMessage } from '../../utils/toastMiddleware'

const PopoverAdicionarProfessor = ({ isOpen, onOpen, onClose, groupsOptions, schoolId, fetchTeachers }) => {
  const [groupsValue, setGroupsValue] = useState([])
  const { loggedUser } = useLoggedUserStore()

  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const [newTeacher, setNewTeacher] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  })

  async function handleAddTeacher() {
    const { name, email, phoneNumber } = newTeacher
    const groups = groupsValue.map((group) => group.id)

    if (!name) {
      toast(buildMessage('error', 'Faltando informações de turma', 'Informe o nome do professor.'))
      return
    }
    if (!email) {
      toast(buildMessage('error', 'Faltando informações de turma', 'Informe o email do professor.'))
      return
    }

    const requestBody = {
      name,
      email,
      phoneNumber,
      groups,
      schoolId,
      language: loggedUser.user_language,
    }

    if (requestBody.name && requestBody.email) {
      setLoading(true)
      try {
        await createTeacherApi(requestBody)
        toast(buildMessage('success', 'Professor cadastrado com sucesso.', 'O Professor já pode acessar o sistema.'))
      } catch (_error) {
        toast(buildMessage('error', 'Erro ao adicionar professor.', 'Entre em contato com o suporte do Correção Online.'))
      }

      fetchTeachers()
      setLoading(false)
    }

    setNewTeacher({ name: '', email: '', phoneNumber: '' })
    setGroupsValue([])
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end" closeOnBlur={false}>
      <PopoverTrigger>
        <div className="button-detalhamento-tema" onClick={onOpen}>
          <div className="mainbutton-detalhamento-tema custom-button" style={{ height: '48px' }}>
            <div className="content-2-detalhamento-tema">
              <img
                className="ic-add-detalhamento-tema"
                src={'/img/ic-add-1.svg'}
                alt="button-addStudent"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
              <Text hideBelow={'md'} className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px">
                {'Adicionar Professor'}
              </Text>
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
        <PopoverArrow />
        <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'} />
        <PopoverHeader>
          <div className={'text-popover'}>Novo professor</div>
        </PopoverHeader>
        <PopoverBody marginTop={'18px'} width={'100%'}>
          <br />
          <Input
            className="input-default-style"
            value={newTeacher.name}
            onChange={(e) => setNewTeacher({ ...newTeacher, name: e.target.value })}
            style={{
              width: '100%',
              backgroundColor: 'white',
              border: '1px solid #d7e3fb',
              padding: '5px',
              marginBottom: '12px',
            }}
            placeholder={'Digite o nome do professor'}
            type="text"
          />
          <Input
            className="input-default-style"
            value={newTeacher.email}
            onChange={(e) => setNewTeacher({ ...newTeacher, email: e.target.value })}
            style={{
              width: '100%',
              backgroundColor: 'white',
              border: '1px solid #d7e3fb',
              padding: '5px',
              marginBottom: '12px',
            }}
            placeholder={'Digite o email do professor'}
            type="text"
          />
          <Input
            className="input-default-style"
            value={newTeacher.phoneNumber}
            onChange={(e) => setNewTeacher({ ...newTeacher, phoneNumber: e.target.value })}
            style={{
              width: '100%',
              backgroundColor: 'white',
              border: '1px solid #d7e3fb',
              padding: '5px',
              marginBottom: '18px',
            }}
            placeholder={'Digite o número de telefone do professor (opcional)'}
            type="text"
          />
          <Box w={'100%'} h={'100%'}>
            <MultiSelect
              placeholder={loading ? 'Aguarde um momento...' : 'Selecione turmas desse professor'}
              className={'min-width-multiselect'}
              onChange={(e) => {
                setGroupsValue(e)
              }}
              borderColor={'#d7e3fb'}
              backgroundColor={'white'}
              isDisabled={loading}
              options={groupsOptions}
              value={groupsValue}
              id={'turmas-teacher'}
              w={'100%'}
            />
          </Box>
        </PopoverBody>
        <PopoverFooter alignSelf={'center'} w={'100%'}>
          <Button
            backgroundColor={'#0A41D6'}
            color={'white'}
            width={'100%'}
            height={'48px'}
            isLoading={loading}
            marginBottom={'15px'}
            onClick={handleAddTeacher}>
            {' '}
            Adicionar{' '}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverAdicionarProfessor
