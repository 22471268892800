import './CreateSchool.css'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import useCriarEscolaStore, { STEP_SCHOOL } from '../../store/useCriarEscolaStore'
import Page from '../Page'
import SuccessfulCreationPage from '../SuccessfulCreationPage'
import CreateSchoolStep1 from './CreateSchoolStep1'
import CreateSchoolStep2 from './CreateSchoolStep2'

const CreateSchool = () => {
  const navigate = useNavigate()

  // Fetch states and actions from zustand store
  const { currentStep, criarEscolaData, setCurrentStep, cleanCriarEscolaData } = useCriarEscolaStore()

  const handleNextStep = () => {
    if (currentStep === STEP_SCHOOL.CREATED) {
      navigate(`/detalhamento-escola/${criarEscolaData.schoolId}`)
      cleanCriarEscolaData()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep === STEP_SCHOOL.CREATED || currentStep === STEP_SCHOOL.ABOUT) {
      navigate('/area-de-escolas')
      setCurrentStep(STEP_SCHOOL.ABOUT)
      cleanCriarEscolaData()
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_SCHOOL.ABOUT:
        return <CreateSchoolStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case STEP_SCHOOL.ADDRESS:
        return <CreateSchoolStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case STEP_SCHOOL.CREATED:
        return (
          <SuccessfulCreationPage
            handleRedirectReturn={handlePreviousStep}
            handleRedirectDetails={handleNextStep}
            detailsText={'Ver escola criada'}
            entityCreated={'escola nova!'}
            headbarLink={'/area-de-escolas'}
            listText={'Ver todas as escolas'}
          />
        )
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'adm'}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CreateSchool
