export const RoleTypes = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  INDEPENDENT_TEACHER: 'independentteacher',
  ADM: 'adm',
  OWNER: 'owner',
  MONITOR: 'monitor',
} as const

export type RoleType = (typeof RoleTypes)[keyof typeof RoleTypes]
