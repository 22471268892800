import { useEffect, useState } from 'react'
import '../ActivityDetails.css'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getActivityByIdApi } from '../../../apis/activitityApi/activityApi'
import { listSupportMaterialApi } from '../../../apis/themeApi/supportMaterialApi'
import { getThemeApi } from '../../../apis/themeApi/themeApi'
import GetModalEditActivity from '../../../components/GetModalEditActivity'
import PageSpinner from '../../../components/PageSpinner'
import SearchComponent from '../../../components/SearchComponent'
import SharedTablePagination from '../../../components/SharedTablePagination'
import SupportMaterialList from '../../../components/SupportMaterialList'
import { useDeleteApiV1ActivityActivityId, useGetApiV1ActivityActivityId } from '../../../services/activity'
import useNavigationStore from '../../../store/useNavigationStore'
import useStatusStore from '../../../store/useStatusStore'
import sanitizedDate from '../../../utils/DateSatinizerInput'
import { essayStatus } from '../../../utils/essayStatus'
import { formatDate } from '../../../utils/formatDate'
import {
  HandleEssayClickTeacher,
  HandleGroup,
  HandleStatusInput,
  HandleSubmittedDate,
} from '../../../utils/sharedTableColumnHandlers'

function ActivityDetailsTeacher() {
  const navigate = useNavigate()
  const { atividadeID } = useParams()

  const [activityData, setActivityData] = useState({})
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [theme, setTheme] = useState({})
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [essays, setEssays] = useState([])
  const [selectedTab, setSelectedTab] = useState('detalhes')
  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()
  const { isSuccess } = useStatusStore()
  const { setReturnTo } = useNavigationStore()

  const isEditDisabled = new Date() > new Date(activityData.deadline)

  const getApiV1ActivityActivityId = useGetApiV1ActivityActivityId(atividadeID)

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getActivityByIdApi(atividadeID)]).then(([activityResult]) => {
        Promise.all([listSupportMaterialApi(activityResult.theme.id), getThemeApi(activityResult.theme.id)]).then(
          ([supportMaterialResult, themeResult]) => {
            resolve({ activityResult, supportMaterialResult, themeResult })
          },
        )
      })
    })
  }

  useAddToHistory()

  useEffect(() => {
    fetchOptions().then(({ activityResult, supportMaterialResult, themeResult }) => {
      setActivityData(activityResult)
      setSupportMaterialList(supportMaterialResult)
      setTheme(themeResult)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (activityData) {
      setEssays(
        activityData.essays
          ? activityData.essays.filter((item) => {
              const result =
                item.student.name.toLowerCase().includes(filterText.toLowerCase()) ||
                (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(filterText)) ||
                item.group.name.toLowerCase().includes(filterText.toLowerCase()) ||
                essayStatus[item.status].label.toLowerCase().includes(filterText.toLowerCase())

              return result
            })
          : [],
      )
    }
  }, [filterText, activityData])

  useEffect(() => {
    if (isSuccess) {
      setReturnTo('/area-de-atividades')
    }
  }, [isSuccess, setReturnTo])

  const { mutate: removeActivity, isPending: isRemoveActivityPending } = useDeleteApiV1ActivityActivityId({
    mutation: {
      onSuccess: () => {
        toast.success('Atividade removida com sucesso')
        navigate('/area-de-atividades')
      },
      onError: () => {
        toast.error('Erro ao remover atividade')
      },
    },
  })

  async function updateActivityData() {
    await getApiV1ActivityActivityId.refetch().then((result) => {
      setActivityData(result.data.data.message)
    })
  }

  function HandleGroups() {
    const itemsPerPage = 5
    const [currentPage, setCurrentPage] = useState(1)

    if (!activityData.groups) {
      return ''
    }

    const totalPages = Math.ceil(activityData.groups.length / itemsPerPage)

    const handlePrevious = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }
    }

    const handleNext = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1)
      }
    }

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentItems = activityData.groups.slice(startIndex, endIndex)

    return (
      <div className="frame-2492-detalhamento-atividade">
        {currentItems.map((item, key) => {
          return <GroupMiniCard groupName={item.name} key={key} />
        })}
        <div className="card-turmas-detalhamento-atividade-2">
          <HStack justifyContent={'center'} height={'100%'}>
            <img className={'arrow-pagination'} src={'/img/arrowLeftPagination.svg'} onClick={handlePrevious} />
            <img className={'arrow-pagination'} src={'/img/arrowRightPagination.svg'} onClick={handleNext} />
          </HStack>
        </div>
      </div>
    )
  }

  function GroupMiniCard(props) {
    const { groupName } = props

    return (
      <div className="card-turmas-1-detalhamento-atividade">
        <div className="content-detalhamento-atividade">
          <div className="group-text-detalhamento-atividade valign-text-middle-detalhamento-atividade publicsans-normal-dove-gray-14px">
            {groupName}
          </div>
        </div>
      </div>
    )
  }

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function Data(props) {
    const { date } = props

    return (
      <div className="data-detalhamento-atividade">
        <img
          className="calendar-2851774-detalhamento-atividade"
          src={'/img/calendar-2851774-5@2x.png'}
          alt="calendar-2851774"
        />
        <div className="date-detalhamento-atividade publicsans-normal-nevada-14px">{date === '' ? '' : formatDate(date)}</div>
      </div>
    )
  }

  function navigationActivityTeacher(link) {
    setReturnTo(`/detalhamento-atividade/${atividadeID}`)
    navigate(link)
  }

  if (loading) return <PageSpinner />

  return (
    <HStack
      width={'90%'}
      flexDir={{ md: 'row', base: 'column' }}
      gap={'16px'}
      mb={5}
      justifyContent={'space-between'}
      className={'content-max-size'}>
      <VStack gap={5} className={'detalhamento-atividade-body'}>
        <Box className="atividade-detalhamento-atividade">
          {theme?.base64Image && (
            <Image minW="150px" height="auto" className={'activity-image-style'} src={theme?.base64Image} hideBelow={'md'} />
          )}
          <div className="frame-2593-detalhamento-atividade">
            <HStack className="frame-2594-detalhamento-atividade">
              <VStack width={'100%'} alignItems={'flex-start'} flex={1}>
                <Text className="publicsans-bold-nevada-14px">Atividade</Text>
                <Text fontSize={{ md: '24px', base: '18px' }} className="publicsans-normal-absolute-zero-24px">
                  {activityData.subject}
                </Text>
              </VStack>
              <HStack w={'100%'} justifyContent={'flex-end'} flex={0.5} flexDir={{ md: 'row', base: 'column' }}>
                <Text alignSelf={'center'} className="publicsans-bold-nevada-14px">
                  Editar
                </Text>
                <Tooltip
                  label={
                    <Box align="start" spacing={1}>
                      <Text>Atividade encerrada</Text>
                    </Box>
                  }
                  isDisabled={!isEditDisabled}
                  aria-label="explicação de desabilitação">
                  <Image
                    className="mode-edit-detalhamento-atividade"
                    alignSelf={'center'}
                    src="/img/mode-edit@2x.png"
                    alt="Mode edit"
                    onClick={isEditDisabled ? undefined : modalDisclosure.onOpen}
                    style={{
                      filter: isEditDisabled ? 'grayscale(100%)' : 'none',
                      cursor: isEditDisabled ? 'auto' : 'pointer',
                      opacity: isEditDisabled ? 0.5 : 1,
                    }}
                  />
                </Tooltip>
              </HStack>
              {modalDisclosure.isOpen && (
                <GetModalEditActivity
                  modalDisclosure={modalDisclosure}
                  activityData={activityData}
                  updateActivityData={updateActivityData}
                />
              )}
            </HStack>
            <div className="frame-2592-detalhamento-atividade">
              <div className="tema-detalhamento-atividade">
                <div className="publicsans-bold-nevada-10px">TEMA</div>
                <p
                  className="theme-name-detalhamento-atividade publicsans-normal-nevada-14px"
                  onClick={() => navigationActivityTeacher(`/detalhamento-tema/${activityData.theme.id}`)}>
                  {activityData.theme.name}
                </p>
              </div>
              <div className="tema-detalhamento-atividade">
                <div className="publicsans-bold-nevada-10px">INSTITUIÇÃO</div>
                <p className="publicsans-normal-nevada-14px">{activityData.institution.name}</p>
              </div>
              <HStack
                flexDir={{ md: 'row', base: 'column' }}
                alignItems={{ base: 'start', md: 'end' }}
                gap={5}
                className="frame-2606-detalhamento-atividade">
                <VStack alignItems={'start'} className="entrega-detalhamento-atividade">
                  <div className="publicsans-bold-nevada-10px">CRIAÇÃO</div>
                  <Data date={activityData.createdAt} />
                </VStack>
                <VStack alignItems={'start'} className="entrega-detalhamento-atividade">
                  <div className="publicsans-bold-nevada-10px">INICIO</div>
                  <Data date={activityData.validFrom} />
                </VStack>
                <VStack alignItems={'start'} className="entrega-1-detalhamento-atividade">
                  <div className="entrega-2-detalhamento-atividade publicsans-bold-nevada-10px">DATA FINAL</div>
                  <Data date={activityData.deadline} />
                </VStack>

                <HStack mt={3}>
                  <div className={'publicsans-bold-nevada-14px'}> Apagar atividade?</div>
                  <div>
                    <Popover
                      isOpen={popoverDisclosure.isOpen}
                      onOpen={popoverDisclosure.onOpen}
                      onClose={popoverDisclosure.onClose}
                      placement="bottom-end"
                      closeOnBlur={true}>
                      <PopoverTrigger>
                        <Image src={'/img/deleteIcon.svg'} marginRight={'15px'} cursor={'pointer'} />
                      </PopoverTrigger>
                      <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
                        <PopoverHeader>
                          <div className={'text-popover'}>Confirme Remoção da atividade</div>
                        </PopoverHeader>
                        <PopoverBody alignSelf={'center'} width={'100%'}>
                          <VStack width={'100%'}>
                            <Button
                              colorScheme={'red'}
                              width={'100%'}
                              height={'48px'}
                              isLoading={isRemoveActivityPending}
                              onClick={() => removeActivity({ activityId: atividadeID })}>
                              Remover atividade
                            </Button>
                            <Button
                              variant={'outline'}
                              height={'48px'}
                              width={'100%'}
                              isDisabled={isRemoveActivityPending}
                              onClick={popoverDisclosure.onClose}>
                              Cancelar
                            </Button>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                </HStack>
              </HStack>
            </div>
          </div>
        </Box>

        <HStack maxW={'100%'} overflowX={'auto'} className="frame-2595-detalhamento-atividade">
          <div
            className={`frame-2469-detalhamento-atividade ${selectedTab === 'detalhes' ? 'tab-border' : ''}`}
            onClick={() => changeSelectedTab('detalhes')}>
            <div
              className={`material-de-apoio-detalhamento-atividade ${selectedTab === 'detalhes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
              Detalhes da Atividade
            </div>
          </div>
          <div
            className={`frame-2459-detalhamento-atividade ${selectedTab === 'redacoes' ? 'tab-border' : ''}`}
            onClick={() => changeSelectedTab('redacoes')}>
            <div
              className={`redacoes-detalhamento-atividade ${selectedTab === 'redacoes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
              Redações
            </div>
          </div>
        </HStack>

        {selectedTab === 'detalhes' && (
          <VStack width={'100%'} gap={3}>
            <VStack width={'100%'}>
              <Text fontSize={{ md: '24px', base: '20px' }} className={'title-detalhe-atividade'}>
                {' '}
                Descrição da atividade
              </Text>
              <Container
                backgroundColor={'#f3f5ff'}
                width={'100%'}
                borderRadius={'12px'}
                minHeight={'100px'}
                maxWidth={'100%'}
                textAlign={activityData.description ? 'start' : 'center'}>
                <Text pt={3} pb={3} className={'publicsans-normal-nevada-16px'}>
                  {' '}
                  {activityData.description ? activityData.description : ''}
                </Text>
              </Container>
            </VStack>
            <VStack width={'100%'}>
              <Text fontSize={{ md: '24px', base: '20px' }} className={'title-detalhe-atividade'}>
                {' '}
                Material de apoio do tema
              </Text>
              {supportMaterialList.length === 0 ? (
                <Container
                  backgroundColor={'#f3f5ff'}
                  width={'100%'}
                  borderRadius={'12px'}
                  minHeight={'100px'}
                  maxWidth={'100%'}
                  textAlign={'center'}>
                  <Text marginTop={'35px'} className={'publicsans-normal-nevada-16px'}>
                    Não há material de apoio para o tema desta atividade.
                  </Text>
                </Container>
              ) : (
                <VStack width={'100%'} marginBottom={['0px', '32px']}>
                  <SupportMaterialList supportMaterialList={supportMaterialList} deleteType={'detalhamentoAtividade'} />
                </VStack>
              )}
            </VStack>
          </VStack>
        )}
        {selectedTab === 'redacoes' && (
          <VStack width={'100%'}>
            <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

            {essays.length ? (
              <SharedTablePagination
                data={essays}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Aluno',
                    render: (itemData) => (
                      <div className={'table-cell-container publicsans-normal-nevada-14px'}>{itemData.student.name}</div>
                    ),
                  },
                  {
                    title: 'Turma',
                    render: (itemData) => <HandleGroup groupName={itemData.group.name} />,
                  },
                  {
                    title: 'Status',
                    render: (itemData) => <HandleStatusInput status={itemData.status} />,
                  },
                  {
                    title: 'Data de Entrega',
                    render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.dateSubmitted} />,
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <HandleEssayClickTeacher essayId={itemData.id} />,
                  },
                ]}
              />
            ) : (
              <Box
                backgroundColor={'#f3f5ff'}
                width={'100%'}
                mt={'32px'}
                borderRadius={'12px'}
                minHeight={'100px'}
                maxWidth={'100%'}
                alignContent={'center'}
                textAlign={'center'}>
                <Text className={'publicsans-normal-nevada-16px'}>Ainda não foram criadas redações para esta atividade</Text>
              </Box>
            )}
          </VStack>
        )}
      </VStack>

      <Box
        mt={{ md: 0, base: 3 }}
        mb={5}
        maxWidth={{ base: '100%', md: '350px' }}
        className="frame-2591-detalhamento-atividade">
        <Text
          alignSelf={'center'}
          pt={3}
          fontSize={{ md: '24px', base: '20px' }}
          className="publicsans-normal-resolution-blue-24px">
          Turmas vinculadas
        </Text>
        <HandleGroups />
      </Box>
    </HStack>
  )
}

export default ActivityDetailsTeacher
