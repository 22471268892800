import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderColor: 'brand.200',
      borderWidth: '2px',
      borderRadius: 'sm',
      _checked: {
        border: 'none',
        bg: 'brand.200',
        color: 'white',
        _hover: {
          bg: 'brand.250',
        },
      },
    },
  },
}
