import './ResumoDeRedacoes.css'
import { useNavigate } from 'react-router-dom'
import useNavigationStore from '../../store/useNavigationStore'
import { essayStatus } from '../../utils/essayStatus'

function ResumoDeRedacoes(props) {
  const { status, number, className, daysOfEssays, folderIcon, linkReturnTo } = props
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()

  let filterText = status
  let label
  if (status === essayStatus['20']) {
    filterText = 'Submetida'
    label = 'Redações submetidas'
  } else if (status === essayStatus['30']) {
    filterText = 'Revisada'
    label = 'Redações revisadas'
  } else if (status === essayStatus['40']) {
    filterText = 'Concluída'
    label = 'Redações concluídas'
  }

  return (
    <div
      className={`frame-24-1 ${className || ''}`}
      onClick={() => {
        setReturnTo(linkReturnTo)
        navigate('/area-de-redacoes', { state: { filterText, daysOfEssays } })
      }}>
      <img className="folder-2851794-1" src={folderIcon} alt="folder-icon" />
      <div className="x-corr publicsans-bold-nevada-14px">{label}</div>
      <div className="number-style-2 publicsans-extra-extra-bold-absolute-zero-32px">{number}</div>
    </div>
  )
}

export default ResumoDeRedacoes
