import { create } from 'zustand'

interface NavigationState {
  history: string[]
  addToHistory: (path: string) => void
  goBack: () => void
  getPreviousPage: () => string | null
  resetHistory: () => void
  isHistoryEmpty: () => boolean
}

const useNavigationStoreHistory = create<NavigationState>((set) => ({
  history: [],

  addToHistory: (path: string) =>
    set((state) => {
      if (state.history[state.history.length - 1] !== path) {
        return { history: [...state.history, path] }
      }
      return state
    }),

  goBack: () =>
    set((state) => {
      const newHistory = [...state.history]
      newHistory.pop()
      return { history: newHistory }
    }),

  getPreviousPage: () => {
    const history = useNavigationStoreHistory.getState().history
    return history.length > 1 ? history[history.length - 2] : null
  },

  resetHistory: () =>
    set(() => ({
      history: [],
    })),

  isHistoryEmpty: () => {
    const history = useNavigationStoreHistory.getState().history
    return history.length === 0
  },
}))

export default useNavigationStoreHistory
