import './Title2.css'
import { Box, Text } from '@chakra-ui/react'

function Title2(props) {
  const { children } = props

  return (
    <Box className="title-7">
      <Text fontSize={{ md: '24px', base: '17px' }} className="publicsans-normal-resolution-blue-24px">
        {children}
      </Text>
    </Box>
  )
}

export default Title2
