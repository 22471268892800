import { useEffect, useState } from 'react'
import './StudentArea.css'
import { Box, Container, HStack, Text, VStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import Headbar from '../../components/Headbar'
import Menu from '../../components/Menu'
import NoContentContainer from '../../components/NoContentContainer'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import { getApiV1StudentStudentAdm } from '../../services/student'
import { truncateName } from '../../utils/truncateName'
import Page from '../Page'

function StudentArea() {
  const location = useLocation()

  const [students, setStudents] = useState([])
  const [studentsData, setStudentsData] = useState([])
  const [filterText, setFilterText] = useState(location.state?.filterText || '')

  async function handleInitialData() {
    const studentsResult = await getApiV1StudentStudentAdm()

    setStudentsData(studentsResult.data.message.filter((student) => !student.deleted))
  }

  useEffect(() => {
    handleInitialData()
  }, [])

  useEffect(() => {
    if (studentsData) {
      setStudents(
        studentsData.filter((item) => {
          return (
            item.name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.phoneNumber?.includes(filterText) ||
            item.email?.toLowerCase().includes(filterText.toLowerCase()) ||
            item.schools.find(
              (school) =>
                school.name.toLowerCase().includes(filterText.toLowerCase()) ||
                school.groups.find((group) => group.name.toLowerCase().includes(filterText.toLowerCase())),
            )
          )
        }),
      )
    }
  }, [filterText, studentsData])

  function handleSearchInputChange(e) {
    setFilterText(e.target.value)
  }

  function handleText(text) {
    return <Text>{text}</Text>
  }

  function handleSchools(schools) {
    const schoolsToShow = schools.slice(0, 3)
    const hasMoreSchools = schools.length > 3

    return (
      <HStack width={'100%'} gap={3}>
        {schoolsToShow.map((school, key) => (
          <Text className={'group-background'} key={key}>
            {truncateName(school.name, 12)}
          </Text>
        ))}
        {hasMoreSchools && <div className={'group-more'}>...</div>}
      </HStack>
    )
  }

  function handleGroups(schools) {
    const totalGroups = schools.reduce((sum, school) => sum + school.groups.length, 0)
    const hasMoreGroups = totalGroups > 3

    const result = schools.map((school) => {
      return (
        <HStack gap={3} key={school.id}>
          {school.groups.map((group, key) => (
            <Text className={'group-background'} key={key}>
              {truncateName(group.name, 8)}
            </Text>
          ))}
        </HStack>
      )
    })

    if (hasMoreGroups) {
      result.push(
        <div className={'group-more'} key={'more'}>
          ...
        </div>,
      )
    }

    return result
  }

  function handleDetalhes(itemData) {
    return (
      <div className={'publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-aluno/${itemData.id}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  return (
    <Box className={'screen-container'}>
      <HStack width={'100%'} height={'100%'} gap={0}>
        <Menu />
        <Page authPage={true} allowedUserType={'adm'} />
        <VStack width={'100%'} maxHeight={'100vh'} alignSelf={'start'} overflow={'auto'}>
          <Headbar hideHeaderElements={true} />
          <VStack className={'body-no-right'} width={'100%'} height={'100%'} gap={5}>
            <Container backgroundColor={'#f3f5ff'} width={'100%'} maxWidth={'100%'} padding={'24px'} borderRadius={'12px'}>
              <Text
                textAlign={{ base: 'center', md: 'start' }}
                fontSize={{ base: '25px', md: '40px' }}
                className={'publicsans-normal-absolute-zero-40px'}>
                Área de Alunos
              </Text>
            </Container>
            <div className="search-filter-1">
              <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />
            </div>
            {students.length === 0 ? (
              <NoContentContainer>Nenhum aluno foi associado ao seu perfil ainda.</NoContentContainer>
            ) : (
              <SharedTablePagination
                data={students}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Nome',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.name)}</div>,
                  },
                  {
                    title: 'Email',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.email)}</div>,
                  },
                  {
                    title: 'Telefone',
                    render: (itemData) => <div className={'pagination-style'}>{handleText(itemData.phoneNumber)}</div>,
                  },
                  {
                    title: 'Escolas',
                    render: (itemData) => <div className={'pagination-style'}>{handleSchools(itemData.schools)}</div>,
                  },
                  {
                    title: 'Turmas',
                    render: (itemData) => <div className={'pagination-style'}>{handleGroups(itemData.schools)}</div>,
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <div>{handleDetalhes(itemData)}</div>,
                  },
                ]}
              />
            )}
          </VStack>
        </VStack>
      </HStack>
    </Box>
  )
}

export default StudentArea
