import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { getStudentById, putStudentById } from '../../apis/studentApi/studentApi'
import { phoneNumberFormatter } from '../../utils/phoneNumberFormatter'

function GetModalEditStudent({ loading, setLoading, studentData, setStudentData, modalDisclosure }) {
  async function fetchStudent() {
    await Promise.all([getStudentById(studentData.id)]).then(([studentResult]) => {
      setStudentData(studentResult)
    })
  }

  const [studentNameError, setStudentNameError] = useState(false)

  const [editStudentData, setEditStudentData] = useState({
    name: studentData.name,
    phoneNumber: studentData.phoneNumber,
    groups: Array.of(studentData.schools ? studentData.schools[0].groups[0].id : []),
  })

  return (
    <Modal
      isOpen={modalDisclosure.isOpen}
      size={'2xl'}
      onClose={modalDisclosure.onClose}
      isCentered={true}
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
          Editar Aluno
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <ModalBody>
          <VStack alignItems={'flex-start'} gap={'16px'}>
            <div className={`${studentNameError ? 'error-form' : ''} publicsans-bold-nevada-16px`}>Nome do Aluno*</div>
            <Input
              className={'input-default-style'}
              onChange={(e) => {
                setStudentNameError(false)
                setEditStudentData({ ...editStudentData, name: e.target.value })
              }}
              defaultValue={editStudentData.name}
            />
            <div className={'publicsans-bold-nevada-16px'}>Numero de telefone</div>
            <Input
              className={'input-default-style'}
              onChange={(e) => {
                const formattedValue = phoneNumberFormatter(e.target.value)
                setEditStudentData({ ...editStudentData, phoneNumber: formattedValue })
              }}
              value={editStudentData.phoneNumber}
            />
          </VStack>
        </ModalBody>

        <ModalFooter
          width={'100%'}
          alignSelf={'center'}
          gap={{ md: '32px', base: '8px' }}
          flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Button
            backgroundColor={'#f9faff'}
            borderColor={'#648BF7'}
            border={'1px'}
            borderRadius={'8px'}
            color={'#062989'}
            height={'48px'}
            width={'100%'}
            onClick={modalDisclosure.onClose}>
            Cancelar
          </Button>
          <Button
            backgroundColor={'#0A41D6'}
            border={'1px'}
            borderRadius={'8px'}
            color={'#FFFFFF'}
            height={'48px'}
            width={'100%'}
            onClick={async () => {
              if (!editStudentData.name) {
                setStudentNameError(!editStudentData.name)
              } else {
                try {
                  setLoading(true)
                  await putStudentById(studentData.id, editStudentData)
                  await fetchStudent()
                } finally {
                  setLoading(false)
                  modalDisclosure.onClose()
                }
              }
            }}>
            {loading ? <Spinner size="xs" marginRight="2" color="#0A41D6" /> : null}
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditStudent
