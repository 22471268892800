import { Box, Center, Stack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { formatValue } from 'react-currency-input-field'
import { Pagination } from '../../../components/Pagination'
import { Table } from '../../../components/Table'
import { NoResults } from '../../../components/Table/no-result'
import { ArrowRightIcon } from '../../../components/icons/ArrowRight'
import { plansColumns } from './utils'

export const PlansSearch = ({ hits, page, isLoading, detailsAction }) => {
  const data = useMemo(() => {
    if (hits) {
      return hits.map((hit) => {
        return {
          ...hit,
          maxActiveStudents: `${hit.maxActiveStudents} estudantes`,
          price: formatValue({
            value: String(hit.price),
            intlConfig: { locale: 'pt-BR', currency: 'BRL' },
          }),
          details: () => (
            <Center cursor={'pointer'}>
              <ArrowRightIcon />
            </Center>
          ),
          action: () => detailsAction(hit),
        }
      })
    }
  }, [hits])

  return (
    <Stack spacing={4} width="100%" overflowX={'auto'}>
      <Box>
        <Table data={data} columns={plansColumns} isLoading={isLoading} />
        {!data.length && !isLoading && (
          <NoResults label="Nenhum plano cadastrado" buttonLabel="Cadastrar novo" buttonPath="/cliente/novo" />
        )}
      </Box>
      <Pagination
        page={page}
        onNext={() => {
          console.error('Precisa ser implementado')
          return
        }}
        onPrevious={() => {
          console.error('Precisa ser implementado')
          return
        }}
        limitReached
      />
    </Stack>
  )
}
