import { Icon } from '@chakra-ui/react'

export const AddIcon = () => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 32 32" fill="none">
      <path
        d="M16 11.103V20.8716M20.8889 15.9873H11.1111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2476 2.6665H9.75235C5.39679 2.6665 2.66663 5.74928 2.66663 10.1134V21.8862C2.66663 26.2504 5.38409 29.3332 9.75235 29.3332H22.2476C26.6158 29.3332 29.3333 26.2504 29.3333 21.8862V10.1134C29.3333 5.74928 26.6158 2.6665 22.2476 2.6665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
