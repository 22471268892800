import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormStepNotSelected from '../../components/FormStepNotSelected'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import { useGetApiV1SchoolSchoolIdInstitution } from '../../services/school'
import { useGetApiV1ThemeSchoolSchoolId } from '../../services/theme'
import useCreateActivityStore from '../../store/useCreateActivityStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'

const validationSchema = yup.object({
  subject: yup.string().min(5).max(255).required(),
  themeId: yup.string().required(),
  institutionId: yup.string().required(),
  description: yup.string().max(500),
})
type Form = yup.InferType<typeof validationSchema>

const maxCharacteres = 500

type CreateActivityStep1Props = {
  handlingNextStep: () => void
}

function CreateActivityStep1(props: CreateActivityStep1Props) {
  const { loggedUser } = useLoggedUserStore()
  const { handlingNextStep } = props
  const { setActivityData, activityData } = useCreateActivityStore()

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { data: themesResponse, isPending: isPendingTheme } = useGetApiV1ThemeSchoolSchoolId(loggedUser.schoolId)
  const { data: institutionsResponse, isPending: isPendingInstitution } = useGetApiV1SchoolSchoolIdInstitution(
    loggedUser.schoolId,
  )

  const themesResult = themesResponse?.data?.message || []
  const institutionsResult = institutionsResponse?.data?.message || []

  const themeOptions =
    themesResult.map((tema: { id: string; subject: string }) => ({
      value: tema.id,
      label: tema.subject,
      id: tema.id,
    })) || []

  const instituicoesOptions =
    institutionsResult.map((instituicao: { id: string; name: string }) => ({
      value: instituicao.id,
      label: instituicao.name,
      id: instituicao.id,
    })) || []

  const submit = (body: Form) => {
    setActivityData(body)
    handlingNextStep()
  }

  const setFormDataOnReload = () => {
    if (!activityData?.subject) return
    reset({
      subject: activityData?.subject || '',
      description: activityData?.description || '',
      themeId: activityData.themeId || '',
      institutionId: activityData.institutionId || '',
    })
  }

  useEffect(() => {
    // Waits themes and instituition to be loaded
    // then sets form data with localhost data
    if (!themesResponse || !institutionsResult) return
    setFormDataOnReload()
  }, [themesResponse, institutionsResult])

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-atividades'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar atividade
        </Heading>
        <VStack w={'100%'} mt={'32px'}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(submit)} noValidate>
            <StepsImage text={'Sobre a atividade'} number={1} />
            <Grid width={'100%'} gap={'32px'} mt={'32px'}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.subject && !!errors.subject.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Nome da atividade*</FormLabel>
                  <Input
                    id={'subject'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    {...register('subject')}
                  />
                  <FormErrorMessage>{errors.subject?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.themeId && !!errors.themeId.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Selecione o tema*</FormLabel>
                  <Select
                    placeholder={isSubmitting ? 'Aguarde um momento...' : 'Selecione um tema'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    isDisabled={isSubmitting}
                    id={'themeId'}
                    {...register('themeId')}>
                    {themeOptions.map((option, key) => (
                      <option className={'select-tema-text'} key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.themeId?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.institutionId && !!errors.institutionId.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Selecione a instituição*</FormLabel>
                  <Select
                    placeholder={isSubmitting ? 'Aguarde um momento...' : 'Selecione uma instituição'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    isDisabled={isSubmitting}
                    id={'institutionId'}
                    {...register('institutionId')}>
                    {instituicoesOptions.map((option, key) => (
                      <option className={'select-tema-text'} key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.institutionId?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.description && !!errors.description.message}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Descrição*</FormLabel>
                  <Textarea
                    resize="vertical"
                    id={'description'}
                    {...register('description')}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                  />
                  <Text
                    fontSize="sm"
                    zIndex={1000}
                    position="absolute"
                    bottom={1}
                    right={4}
                    color="gray.400"
                    fontFamily={'Public Sans'}>
                    {watch('description') ? watch('description')?.length : 0}/{maxCharacteres}
                  </Text>
                  <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Box mt={4} className="steps">
              <FormStepNotSelected number={2} configuraesDaAtividade={'Configurações da atividade'} />
            </Box>

            <Box display="flex" justifyContent="end" w={'100%'} gap={3} my={6}>
              <Button flex={0.5} type="submit" variant="primary" isLoading={isPendingTheme || isPendingInstitution}>
                Continuar
              </Button>
            </Box>
          </form>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateActivityStep1
