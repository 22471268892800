import { Badge, Box, Button, Center, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { useMemo, useState } from 'react'
import { RiArrowDownDoubleLine } from 'react-icons/ri'
import { Table } from '../../../components/Table'
import { NoResults } from '../../../components/Table/no-result'
import { logColumns } from './utils'

const LEVEL_COLORS = {
  Error: 'red',
  Info: 'blue',
  Warn: 'yellow',
}

export const LogSearch = ({ hits, isLoading, setSelectedItems }) => {
  const INITIAL_LIMIT = 20
  const [limit, setLimit] = useState(INITIAL_LIMIT)

  const data = useMemo(() => {
    if (hits) {
      return hits.map((hit) => {
        const date = format(new Date(hit.date), 'dd/MM/yyyy p', ptBR)
        const level = hit.level.replace(/"/g, '')
        return {
          ...hit,
          date: () => <Text color={hit.handled ? 'blackAlpha.400' : 'black'}>{date}</Text>,
          level: () => (
            <Center>
              <Badge colorScheme={LEVEL_COLORS[level]}>{level}</Badge>
            </Center>
          ),
          // details: () => (<Center cursor={'pointer'}><ArrowRightIcon /></Center>),
          // action: () => detailsAction(hit)
        }
      })
    }
  }, [hits])

  return (
    <Stack spacing={4} width="100%" marginBottom={7} overflowX={'auto'}>
      <Box>
        <Table
          data={data}
          columns={logColumns}
          isLoading={isLoading}
          setSelectedHitsFunction={setSelectedItems}
          limit={limit}
        />
        {!data.length && !isLoading && (
          <NoResults label="Nenhum registro no período selecionado" buttonLabel="Alterar periodo" buttonPath="/cliente/novo" />
        )}
        {data.length > INITIAL_LIMIT && (
          <Button
            width="100%"
            size="lg"
            variant="outline"
            rightIcon={<RiArrowDownDoubleLine />}
            onClick={() => setLimit((current) => current + 20)}>
            {data.length - limit < INITIAL_LIMIT ? `Exibir mais ${data.length - limit}` : `Exibir mais ${INITIAL_LIMIT}`}
          </Button>
        )}
      </Box>
    </Stack>
  )
}
