import { Link } from 'react-router-dom'
import './LoginHeader.css'
import { Box, Button, useMediaQuery } from '@chakra-ui/react'
import FontsUtils from '../../theme/foundations/FontsUtils'

function HeaderLogin() {
  const [isMobile] = useMediaQuery('(max-width: 375px)')

  function LogoPositivaHorizontal() {
    return (
      <Box marginLeft={isMobile ? '16px' : '32px'}>
        <img src={'/img/logo-positiva-horizontal.svg'} alt="Group" />
      </Box>
    )
  }

  function LoginButton() {
    return (
      <Link style={{ textDecoration: 'none' }} to={'/login'}>
        <Button
          className={'publicsans-bold-absolute-zero-14px'}
          variant={'outline'}
          marginRight={isMobile ? '16px' : '32px'}
          padding={'24px'}
          sx={FontsUtils.publicSansBoldAbsoluteZero}>
          Entrar
          <img className="buttonicon-login" src={'/img/ic-login.svg'} alt={'entrar icon'} />
        </Button>
      </Link>
    )
  }

  return (
    <div className="header-login">
      <LogoPositivaHorizontal />
      <LoginButton />
    </div>
  )
}

export default HeaderLogin
