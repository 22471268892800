import { QueryClient, keepPreviousData } from '@tanstack/react-query'

const oneDayInMs = 24 * 60 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: oneDayInMs,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
