import submitConfirmImage from '@/assets/submit-confirm.png'
import useCreateThemeStore from '@/store/useCreateThemeStore'
import { Button, Heading, Img, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Headbar from '../../components/Headbar'

function CreateThemeStep2() {
  const navigate = useNavigate()
  const { themeId } = useCreateThemeStore()

  const handleRedirectToAllThemes = () => navigate('/area-de-temas')
  const handleRedirectToTheme = () => navigate(`/detalhamento-tema/${themeId}`)

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-temas'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Img width="300px" height="auto" src={submitConfirmImage} />
        <Heading size="xl" fontWeight="normal" color="brand.200">
          Você criou um
        </Heading>
        <Heading size="xl" color="brand.200">
          tema novo!
        </Heading>
        <Button size="lg" minW="300px" onClick={handleRedirectToTheme} variant="primary" mt={12}>
          Ver tema criado
        </Button>
        <Button size="lg" minW="300px" onClick={handleRedirectToAllThemes} variant="outline" mt={5}>
          Voltar
        </Button>
      </VStack>
    </VStack>
  )
}

export default CreateThemeStep2
