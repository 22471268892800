import submitConfirmImage from '@/assets/submit-confirm.png'
import useCreateActivityStore from '@/store/useCreateActivityStore'
import { Button, Heading, Img, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Headbar from '../../components/Headbar'
import useStatusStore from '../../store/useStatusStore'

function CreateActivityStep3() {
  const navigate = useNavigate()
  const { activityId } = useCreateActivityStore()
  const { setIsSuccess } = useStatusStore()

  const handleRedirectToAllActivities = () => navigate('/area-de-atividades')
  const handleRedirectToActivity = () => {
    setIsSuccess(true)
    navigate(`/detalhamento-atividade/${activityId}`)
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={'/area-de-atividades'} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Img width="300px" height="auto" src={submitConfirmImage} />
        <Heading size="xl" fontWeight="normal" color="brand.200">
          Você criou uma
        </Heading>
        <Heading size="xl" color="brand.200">
          atividade nova!
        </Heading>
        <Button size="lg" minW="300px" onClick={handleRedirectToActivity} variant="primary" mt={12}>
          Ver atividade criada
        </Button>
        <Button size="lg" minW="300px" onClick={handleRedirectToAllActivities} variant="outline" mt={5}>
          Ver todas as atividades
        </Button>
      </VStack>
    </VStack>
  )
}

export default CreateActivityStep3
