import { Box, Button, HStack } from '@chakra-ui/react'
import { useWavesurfer } from '@wavesurfer/react'
import { useEffect, useRef, useState } from 'react'
import { GrPause, GrPlay, GrTrash } from 'react-icons/gr'
import { toTimer } from '../../utils/toHour'

enum PlayerState {
  PLAYING = 'playing',
  PAUSED = 'paused',
}

type VoicePreviewProps = {
  src: string
  deleteAudio?: () => void
}

export const AudioPlayer = (props: VoicePreviewProps) => {
  const { src, deleteAudio } = props

  const containerRef = useRef(null)
  const [playerState, setPlayerState] = useState(PlayerState.PAUSED)
  const { wavesurfer, currentTime } = useWavesurfer({
    container: containerRef,
    waveColor: 'rgb(200, 200, 200 )',
    height: 'auto',
    url: src,
    progressColor: 'rgb(10, 65, 214)',
  })

  useEffect(() => {
    wavesurfer?.on('play', () => setPlayerState(PlayerState.PLAYING))
    wavesurfer?.on('pause', () => setPlayerState(PlayerState.PAUSED))
  }, [wavesurfer])

  const onTogglePlayer = async () => {
    switch (playerState) {
      case PlayerState.PAUSED:
        await wavesurfer?.play()
        break
      case PlayerState.PLAYING:
        wavesurfer?.pause()
        break
    }
  }

  return (
    <HStack
      w="100%"
      display="flex"
      gap={4}
      padding={3}
      borderColor="brand.300"
      borderWidth="1px"
      borderStyle="solid"
      backgroundColor="white"
      position="relative"
      borderRadius="lg">
      <Button
        variant="outline"
        borderRadius="full"
        borderColor="brand.200"
        width="60px"
        height="60px"
        boxShadow="0px 0px 10px 0px rgba(107, 107, 121, 0.24)"
        onClick={onTogglePlayer}>
        {playerState === PlayerState.PLAYING && <GrPause />}
        {playerState === PlayerState.PAUSED && <GrPlay style={{ marginRight: '-4px' }} />}
      </Button>
      <Box ref={containerRef} height="60px" flexGrow={1} />
      <Box>{toTimer(currentTime)}</Box>
      {deleteAudio && (
        <Button onClick={deleteAudio}>
          <GrTrash />
        </Button>
      )}
    </HStack>
  )
}
