import './GroupsArea.css'
import { Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import AdmGroupsArea from './AdmGroupsArea'
import StudentGroupsArea from './StudentGroupsArea'
import TeacherGroupsArea from './TeacherGroupsArea'

function GroupsArea() {
  const location = useLocation()
  const schools = location.state?.schools

  const { loggedUser } = useLoggedUserStore()

  function getComponentByRole() {
    if (loggedUser.user_role === 'student') {
      return <StudentGroupsArea />
    }
    if (loggedUser.user_role === 'adm') {
      return <AdmGroupsArea schools={schools} />
    }
    return <TeacherGroupsArea />
  }

  return (
    <Box height={'100%'} className="container-center-horizontal-area-de-turmas">
      {getComponentByRole()}
    </Box>
  )
}

export default GroupsArea
