import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Select: ComponentStyleConfig = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      backgroundImage: `url(${'/img/ic-arrowdown-4@2x.png'})`,
      backgroundSize: '24px 24px',
      backgroundPosition: 'right 16px center',
      backgroundRepeat: 'no-repeat',
      appearance: 'none',
    },
    icon: {
      width: '0',
      height: '0',
    },
  },
}
