import { HStack, Image, Input } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'

type SearchComponentProps = {
  filterText?: string
  handleSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function SearchComponent({ filterText, handleSearchInputChange }: SearchComponentProps) {
  return (
    <HStack width="100%" backgroundColor="white" borderRadius="lg" border="1px solid" height="48px" borderColor="brand.400">
      <Image height="24px" pl={3} minWidth="24px" src="/img/search-2851823@2x.png" alt="lupa" />
      <Input
        className="publicsans-normal-nevada-16px"
        placeholder="Buscar..."
        width="100%"
        type="search"
        _focusVisible={{ outline: 'none' }}
        border="none"
        value={filterText}
        onChange={handleSearchInputChange}
      />
    </HStack>
  )
}
