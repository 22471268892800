import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Popover: ComponentStyleConfig = {
  baseStyle: {
    content: {
      borderColor: 'gray.200',
      boxShadow: 'lg',
    },
  },
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
        minWidth: 'unset !important',
      },
    },
  },
}
