import type React from 'react'
import { useEffect, useState } from 'react'
import './InitialPageAdm.css'
import { Box, HStack, Select, Spacer, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import FontsUtils from '../../theme/foundations/FontsUtils'
import Page from '../Page'

import LayoutMenu from '@/layouts/LayoutMenu'
import type { SchoolAdmInstitutionViewModel, SchoolEssayAdmViewModel, SchoolViewModel } from '@/services/types'
import { useNavigate } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { getSchoolInfoAdmApi, listAllSchoolsOfLoggedUserApi } from '../../apis/schoolApi/schoolApi'
import NoContentContainer from '../../components/NoContentContainer'

function InitialPageAdm() {
  const [loading, setLoading] = useState(true)
  const [schools, setSchools] = useState<SchoolViewModel[]>([])
  const [amountOfStudents, setAmountOfStudents] = useState<React.ReactElement>()
  const [amountOfTeachers, setAmountOfTeachers] = useState<React.ReactElement>()
  const [amountOfGroups, setAmountOfGroups] = useState<React.ReactElement>()
  const [amountOfSchools, setAmountOfSchools] = useState<React.ReactElement>()
  const [daysFilter, setDaysFilter] = useState(30)
  const [institutionNamesList, setInstitutionNamesList] = useState<string[]>([])
  const [institutionFilter, setInstitutionFilter] = useState<string>()
  const [averageGrades, setAverageGrades] = useState<Partial<SchoolEssayAdmViewModel[]>>([])
  const [schoolResultChart, setSchoolResultChart] = useState<SchoolAdmInstitutionViewModel[]>([])

  const [essayResultChart, setEssayResultChart] = useState<SchoolEssayAdmViewModel[]>([])

  const navigate = useNavigate()

  const [isMobile] = useMediaQuery('(max-width: 767px)')

  const fetchInitialData = async (): Promise<{
    schoolsResult: SchoolViewModel[]
    schoolsDetailsResult: SchoolEssayAdmViewModel[]
  }> => {
    return new Promise((resolve) => {
      Promise.all([listAllSchoolsOfLoggedUserApi(), getSchoolInfoAdmApi(daysFilter)]).then(
        ([schoolsResult, schoolsDetailsResult]) => {
          resolve({ schoolsResult, schoolsDetailsResult })
        },
      )
    })
  }

  function handleInitialData() {
    setLoading(true)

    fetchInitialData().then(({ schoolsResult, schoolsDetailsResult }) => {
      setSchools(schoolsResult)

      setAverageGrades(
        schoolsDetailsResult.filter((school: SchoolEssayAdmViewModel) => {
          return (
            school.deleted === false &&
            !!school.averageGradeClosedEssaysPerInstitution &&
            school.averageGradeClosedEssaysPerInstitution.length > 0
          )
        }),
      )

      setEssayResultChart(
        schoolsDetailsResult.filter(
          (school) => school.deleted === false && ((school.totalEssayClosed ?? 0) > 0 || (school.totalEssaySubmitted ?? 0) > 0),
        ),
      )

      setLoading(false)
    })
  }

  function setFilteredGrades() {
    const filteredGrades = averageGrades.filter((school) =>
      school?.averageGradeClosedEssaysPerInstitution?.find((schoolData) => schoolData.name === institutionFilter),
    )

    const mappedResult = filteredGrades.map((school) => {
      const filteredAverageGrade = school?.averageGradeClosedEssaysPerInstitution?.filter(
        (schoolData) => schoolData.name === institutionFilter,
      )[0]
      return {
        ...school,
        averageStudentGrade: filteredAverageGrade?.averageGrade,
      }
    })

    setSchoolResultChart(mappedResult)
  }

  useEffect(() => {
    const institutionsListLocal = averageGrades.map((school) => {
      return school?.averageGradeClosedEssaysPerInstitution?.map((institution) => institution.name)
    })

    const result = [...new Set(institutionsListLocal.filter((institution) => institution?.length !== 0).flat())] as string[]

    setInstitutionNamesList(result)

    setInstitutionFilter(institutionFilter || result[0])

    setFilteredGrades()
  }, [averageGrades])

  useEffect(() => {
    setFilteredGrades()
  }, [institutionFilter])

  useEffect(() => {
    handleInitialData()
  }, [])

  useEffect(() => {
    handleInitialData()
  }, [daysFilter])

  useEffect(() => {
    handleOverview()
  }, [loading])

  function calculoTotalAlunos() {
    return schools.reduce((acumulador, school) => {
      return acumulador + (school.totalStudents ?? 0)
    }, 0)
  }

  function calculoTotalProfessores() {
    return schools.reduce((acumulador, school) => {
      return acumulador + (school.totalTeachers ?? 0)
    }, 0)
  }

  function calculoTotalTurmas() {
    return schools.reduce((acumulador, school) => {
      return acumulador + (school.totalGroups ?? 0)
    }, 0)
  }

  function calculoTotalEscolas() {
    return schools.length
  }

  function handleOverview() {
    setAmountOfStudents(
      <OverviewComponent
        folderIcon={'/img/person-icon.svg'}
        text={'Total de alunos'}
        link={'/area-de-alunos'}
        number={calculoTotalAlunos()}
      />,
    )

    setAmountOfTeachers(
      <OverviewComponent
        folderIcon={'/img/teacher-icon.svg'}
        text={'Total de professores'}
        link={'/area-de-professores'}
        number={calculoTotalProfessores()}
      />,
    )

    setAmountOfGroups(
      <OverviewComponent
        folderIcon={'/img/folder-2851794@2x.png'}
        text={'Total de turmas'}
        link={'/area-de-turmas'}
        state={{ schools }}
        number={calculoTotalTurmas()}
      />,
    )

    setAmountOfSchools(
      <OverviewComponent
        folderIcon={'/img/folder-pen-icon.png'}
        text={'Total de escolas'}
        link={'/area-de-escolas'}
        number={calculoTotalEscolas()}
      />,
    )
  }

  function OverviewComponent(props) {
    const { text, number, folderIcon, link, state } = props
    return (
      <HStack justifyContent={'space-between'} className={'overview-container'} onClick={() => navigate(link, { state })}>
        <img className="folder-2851794-1" src={folderIcon} alt="folder-icon" />
        <div className="x-corr publicsans-bold-nevada-14px">{text}</div>
        <div className="number-style-2 publicsans-extra-extra-bold-absolute-zero-32px">{number}</div>
      </HStack>
    )
  }

  return (
    <Page authPage={true} allowedUserType={'adm'}>
      <LayoutMenu isLoading={false}>
        <LayoutMenu.Content>
          <Box className="header">
            <Text fontSize={{ md: '40px', base: '30px' }} className="publicsans-normal-absolute-zero-40px">
              Olá, Administrador!
            </Text>
            <Box className="oque-vamos-corrigir-hoje publicsans-normal-nevada-20px">
              <HStack flexDir={{ md: 'row', base: 'column' }} w={'100%'} alignItems={'start'} justifyContent={'space-between'}>
                <Text width={'100%'} fontSize={{ md: '20px', base: '17px' }}>
                  Fique por dentro das suas escolas!
                </Text>
              </HStack>
            </Box>
          </Box>
          <div className="status-initial-page">
            {amountOfStudents}
            {amountOfTeachers}
            {amountOfGroups}
            {amountOfSchools}
          </div>
          {schools ? (
            <>
              <HStack justifyContent={'start'} w={'100%'} flexDir={{ base: 'column', md: 'row' }}>
                <Text fontSize={{ md: '32px', base: '25px' }} className={'publicsans-normal-absolute-zero-32px'}>
                  Desempenho Escolar
                </Text>
                <HStack
                  flexDir={{ base: 'column-reverse', md: 'row' }}
                  flex={1}
                  alignItems={{ base: 'start', md: 'start' }}
                  w={'100%'}>
                  <VStack width={'100%'} maxW={'170px'} alignItems={'start'}>
                    <Text fontSize={'10px'} marginTop={'-13px'} color={FontsUtils.fontGray.color} marginLeft={'5px'}>
                      Instituição
                    </Text>
                    <Select
                      onChange={(e) => {
                        setInstitutionFilter(e.target.value)
                      }}
                      borderColor={'brand.300'}
                      backgroundColor={'white'}
                      isDisabled={loading}>
                      {institutionNamesList.length > 0 ? (
                        institutionNamesList.map((institution, key) => {
                          return (
                            <option className={'select-days-text'} value={institution} key={key}>
                              {institution}
                            </option>
                          )
                        })
                      ) : (
                        <option>N/A</option>
                      )}
                    </Select>
                  </VStack>
                  <Spacer />
                  <Select
                    onChange={(e) => {
                      setDaysFilter(Number.parseInt(e.target.value))
                    }}
                    borderColor={'#d7e3fb'}
                    backgroundColor={'white'}
                    isDisabled={loading}
                    defaultValue={30}
                    maxW={'120px'}>
                    <option className={'select-days-text'} value={30}>
                      {'30 dias'}
                    </option>
                    <option className={'select-days-text'} value={90}>
                      {'90 dias'}
                    </option>
                    <option className={'select-days-text'} value={180}>
                      {'180 dias'}
                    </option>
                    <option className={'select-days-text'} value={365}>
                      {'365 dias'}
                    </option>
                  </Select>
                </HStack>
              </HStack>
              {schoolResultChart.length > 0 ? (
                <Box overflowX={'auto'} width={'100%'} whiteSpace={'nowrap'}>
                  <ResponsiveContainer
                    height={300}
                    width={Math.max(schoolResultChart.length * 80, isMobile ? window.innerWidth : window.innerWidth - 140)}>
                    <BarChart data={schoolResultChart}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="averageStudentGrade"
                        maxBarSize={50}
                        fill="#8884d8"
                        name="Média de Notas"
                        onClick={(event) => navigate(`/detalhamento-escola/${event.id}`)}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              ) : (
                <NoContentContainer>Não há redações concluídas suficientes no periodo escolhido.</NoContentContainer>
              )}

              <Text
                fontSize={{ md: '32px', base: '25px' }}
                whiteSpace={'pre-line'}
                alignSelf={{ base: 'center', md: 'start' }}
                className={'publicsans-normal-absolute-zero-32px'}>
                Redações feitas x Redações corrigidas
              </Text>
              {essayResultChart.length > 0 ? (
                <Box style={{ overflowX: 'auto' }} width={'100%'} whiteSpace={'nowrap'}>
                  <ResponsiveContainer
                    height={300}
                    width={Math.max(essayResultChart.length * 80, isMobile ? window.innerWidth : window.innerWidth - 140)}>
                    <BarChart data={essayResultChart}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="totalEssaySubmitted" maxBarSize={50} fill="#648bf7" name="Em Progresso" />
                      <Bar dataKey="totalEssayClosed" maxBarSize={50} fill="#02b680" name="Corrigidas" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              ) : (
                <NoContentContainer>Não há redações suficientes no periodo escolhido.</NoContentContainer>
              )}
            </>
          ) : (
            ''
          )}
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default InitialPageAdm
