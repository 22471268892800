import { create } from 'zustand'

const useCreateGroupStore = create((set) => ({
  currentStep: 1,
  groupId: '',
  criarTurmaData: {
    name: '',
    description: '',
    shift: 0,
  },

  setCurrentStep: (step) => set({ currentStep: step }),
  setGroupIdStep: (newGroupId) => set({ groupId: newGroupId }),
  setCriarTurmaData: (data) =>
    set((state) => ({
      criarTurmaData: { ...state.criarTurmaData, ...data },
    })),
  cleanCriarTurmaData: () => set({ criarTurmaData: {}, currentStep: 1 }),
}))

export default useCreateGroupStore
