/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */

export interface AssistantUsageBasicInfoViewModel {
  assistantPromptId?: string;
  /** @nullable */
  assistantPromptName?: string | null;
  /** @nullable */
  commentReaction?: string | null;
  essayId?: string;
  /** @nullable */
  essayThemeTitle?: string | null;
  /** @nullable */
  reaction?: string | null;
  /** @nullable */
  result?: string | null;
  studentId?: string;
  /** @nullable */
  studentName?: string | null;
  teacherId?: string;
  /** @nullable */
  teacherName?: string | null;
  tokensUsed?: number;
}
