import type { NewSupportMaterialViewModel } from '@/services/types'
import { toast } from 'react-toastify'

export const handleFileChange = (files: File[], themeId: string): Promise<NewSupportMaterialViewModel[]> => {
  const maxTotalSize = 5 * 1024 * 1024 // 5MB

  const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0)

  if (totalSize > maxTotalSize) {
    toast.error('O tamanho total dos arquivos excede o limite de 5MB')
    return Promise.resolve([])
  }

  const processFile = (file): Promise<NewSupportMaterialViewModel> => {
    return new Promise((resolve, reject) => {
      if (!file) {
        toast.error('Nenhum arquivo foi fornecido')
        return reject(new Error('Nenhum arquivo foi fornecido'))
      }

      const extractFileDetails = (file) => {
        const fileParts = file.name.split('.')
        const extension = fileParts.pop()
        const title = fileParts.join('.')
        const type = ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase()) ? 2 : 3

        return { title, extension, type }
      }

      const { title, extension, type } = extractFileDetails(file)

      const reader = new FileReader()

      reader.onloadend = () => {
        const base64URL = reader.result?.toString()

        if (base64URL) {
          const base64Data = base64URL.split(',')[1]

          const payload: NewSupportMaterialViewModel = {
            title: title,
            type: type,
            value: base64Data,
            extension: extension,
            themeId: themeId,
          }

          resolve(payload)
        } else {
          reject(new Error('Erro ao processar arquivo'))
        }
      }

      reader.onerror = () => {
        toast.error('Erro ao ler arquivo')
        reject(new Error('Erro ao ler arquivo'))
      }

      reader.readAsDataURL(file)
    })
  }

  const fileProcessingPromises = Array.from(files).map((file) => processFile(file))

  return Promise.all(fileProcessingPromises)
}
