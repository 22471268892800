import { Center, Spinner } from '@chakra-ui/react'
import type { ReactNode } from 'react'

type PageSpinnerProps = {
  isLoading?: boolean
  children: ReactNode
}

function PageSpinner(props: PageSpinnerProps) {
  const { isLoading = true, children } = props
  return (
    <>
      {isLoading ? (
        <Center width="100%" height="100%">
          <Spinner size="xl" color="brand.200" />
        </Center>
      ) : (
        children
      )}
    </>
  )
}

export default PageSpinner
