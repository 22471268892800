import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import { inputPhoneMask } from '../../utils/inputMasks'
import Page from '../Page'
import './profile.css'
import { AreaHeader } from '@/components/AreaHeader'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useEffect } from 'react'
import { useGetApiV1AccountAdm, usePutApiV1AccountAdm } from '../../services/auth'
import { useGetApiV1Student, usePutApiV1Student } from '../../services/student'
import { useGetApiV1Teacher, usePutApiV1Teacher, usePutApiV1TeacherIndependentTeacher } from '../../services/teacher'

const validationSchema = yup.object({
  name: yup.string().min(3).max(60).required(),
  phoneNumber: yup.string().phone().required(),
})

type Form = yup.InferType<typeof validationSchema>

type UseGetProfileHook = typeof useGetApiV1Student | typeof useGetApiV1Teacher | typeof useGetApiV1AccountAdm
type UseUpdateProfileHook =
  | typeof usePutApiV1Student
  | typeof usePutApiV1Teacher
  | typeof usePutApiV1TeacherIndependentTeacher
  | typeof usePutApiV1AccountAdm

const Profile = () => {
  const { loggedUser } = useLoggedUserStore()

  let useGetProfile: UseGetProfileHook | undefined = undefined
  let useUpdateProfile: UseUpdateProfileHook | undefined = undefined

  if (loggedUser.user_role === 'student') {
    useGetProfile = useGetApiV1Student
    useUpdateProfile = usePutApiV1Student
  } else if (loggedUser.user_role === 'teacher' || loggedUser.user_role === 'monitor') {
    useGetProfile = useGetApiV1Teacher
    useUpdateProfile = usePutApiV1Teacher
  } else if (loggedUser.user_role === 'independentteacher') {
    useGetProfile = useGetApiV1Teacher
    useUpdateProfile = usePutApiV1TeacherIndependentTeacher
  } else if (loggedUser.user_role === 'adm') {
    useGetProfile = useGetApiV1AccountAdm
    useUpdateProfile = usePutApiV1AccountAdm
  } else {
    throw new Error('Invalid user role')
  }

  const {
    data: response,
    refetch: getProfile,
    isLoading: isProfileLoading,
    isFetching: isProfileFetching,
  } = (useGetProfile as typeof useGetApiV1Student)()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const getDataProfile = response?.data?.message as Partial<Form>

  useEffect(() => {
    setValue('name', getDataProfile?.name ?? '')
    setValue('phoneNumber', getDataProfile?.phoneNumber ?? '')
  }, [isProfileLoading])

  const { mutate: updateProfile } = useUpdateProfile({
    mutation: {
      onSuccess: () => {
        getProfile()
        toast.success('Dados salvos com sucesso!')
      },
    },
  })

  const onSubmit = async (data: Form) => {
    const formattedData = {
      name: data.name,
      phoneNumber: data.phoneNumber,
    }
    await updateProfile({ data: formattedData })
  }

  return (
    <Page authPage={true} allowedUserType={''}>
      <LayoutMenu isLoading={isProfileLoading}>
        <LayoutMenu.Content>
          <AreaHeader title="Perfil do Usuário" />
          <Box w="100%">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <VStack maxW="500px" spacing={5}>
                <FormControl isInvalid={!!errors?.name}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Nome</FormLabel>
                  <Input
                    id="name"
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    className={`input-field ${errors.name ? 'input-field-error' : 'input-field-normal'}`}
                    defaultValue={getDataProfile?.name}
                    {...register('name')}
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel className={'publicsans-bold-nevada-16px'}>Telefone</FormLabel>
                  <Input
                    id="phoneNumber"
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    className={`input-field ${errors.phoneNumber ? 'input-field-error' : 'input-field-normal'}`}
                    {...register('phoneNumber')}
                    defaultValue={getDataProfile?.phoneNumber}
                    as={InputMask}
                    mask={inputPhoneMask}
                  />
                  <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  alignSelf="end"
                  variant="primary"
                  isLoading={isSubmitting || isProfileFetching}
                  minWidth={'200px'}
                  size={'lg'}>
                  Salvar
                </Button>
              </VStack>
            </form>
          </Box>
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default Profile
