import HighlightIcon from '@/assets/highlight-on.svg'
import type { CompleteEssayViewModel } from '@/services/types'
import { useToggleURLQueryParams } from '@/utils/useToogleQueryParam'
import {
  Box,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { CgDetailsMore } from 'react-icons/cg'
import { FaHighlighter } from 'react-icons/fa'
import { MdOutlineComment } from 'react-icons/md'
import { RxBorderWidth } from 'react-icons/rx'
import { useTouchDevice } from './EssayReviewArea/EssayReviewArea.hooks'
import { GeneralComment } from './GeneralComment'
import { EssayReviewSidebar, useEssayReviewSidebar } from './useEssayReviewSidebar'

type MenuEssayProps = {
  essay: CompleteEssayViewModel
  isImage: boolean
  isAbleToReview: boolean
  marker: MarkerProps
}

export const EssayMenu = (props: MenuEssayProps) => {
  const { essay, isImage, marker, isAbleToReview } = props
  const isTouchDevice = useTouchDevice()
  const isTextReviewDesktop = !isImage && !isTouchDevice

  return (
    <VStack height="100%" p={4} position="absolute" right={0} top={0} zIndex={2} pointerEvents="none">
      <DetailsButton />
      <GeneralCommentButton essayId={essay?.id || ''} isAbleToReview={isAbleToReview} />
      {isImage && isAbleToReview && <MarkerButton {...marker} />}
      <Spacer />
      {isAbleToReview && !isTextReviewDesktop && <CommentButton />}
    </VStack>
  )
}

type MarkerProps = {
  size: number
  setSize: (lineHeight: number) => void
}
const MarkerButton = (props: MarkerProps) => {
  const { size, setSize } = props
  return (
    <Popover placement="left">
      <Tooltip label="Tamanho do marcador" placement="left">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              pointerEvents="auto"
              size="md"
              shadow="md"
              aria-label="Marcador"
              isRound
              icon={<RxBorderWidth fontSize="20px" />}
              _hover={{ color: 'brand.200' }}
              bgColor="white"
              color="gray.500"
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <HStack>
              <Slider min={15} max={120} step={5} defaultValue={size} onChange={(val) => setSize(val)}>
                <SliderTrack>
                  <SliderFilledTrack bgColor="brand.200" />
                </SliderTrack>
                <SliderThumb bgColor="brand.200" />
              </Slider>
              <Box>{size}</Box>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

type GeneralCommentProps = {
  essayId: string
  isAbleToReview: boolean
}

const GeneralCommentButton = (props: GeneralCommentProps) => {
  const { essayId, isAbleToReview } = props

  return (
    <Popover placement="left-end">
      <Tooltip label="Comentários gerais" placement="left">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              pointerEvents="auto"
              isRound
              shadow="md"
              aria-label="Comentários gerais"
              size="md"
              icon={<MdOutlineComment fontSize="24px" />}
              _hover={{ color: 'brand.200' }}
              bgColor="white"
              color="gray.500"
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <Portal>
        <PopoverContent>
          <PopoverBody>
            <PopoverCloseButton />
            <GeneralComment essayId={essayId} isAbleToReview={isAbleToReview} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

const DetailsButton = () => {
  const { toogleSidebar, isCurrentSideBarOpen } = useEssayReviewSidebar()

  return (
    <Tooltip label="Detalhes" placement="left">
      <IconButton
        pointerEvents="auto"
        display={{ base: 'flex', lg: 'none' }}
        isRound
        shadow="md"
        aria-label="Detalhes"
        size="md"
        icon={<CgDetailsMore fontSize="24px" />}
        _hover={{ color: 'brand.200' }}
        bgColor="white"
        color="gray.500"
        onClick={() => toogleSidebar(EssayReviewSidebar.DETAILS)}
        isActive={isCurrentSideBarOpen(EssayReviewSidebar.DETAILS)}
      />
    </Tooltip>
  )
}

const CommentButton = () => {
  const { toggleURLQueryParam, hasURLQueryParams } = useToggleURLQueryParams()
  const isCommentModeActive = hasURLQueryParams(['isCommentModeActive'])
  const tooltipText = `${isCommentModeActive ? 'Desativar' : 'Ativar'} modo comentário`

  return (
    <Tooltip label={tooltipText} placement="left">
      <IconButton
        pointerEvents="auto"
        shadow="md"
        size="lg"
        aria-label="Modo comentário"
        isRound
        width="56px"
        height="56px"
        icon={isCommentModeActive ? <Image src={HighlightIcon} alt="comentários" /> : <FaHighlighter fontSize="20px" />}
        bgColor={isCommentModeActive ? 'brand.200' : 'white'}
        _hover={{ color: 'brand.200' }}
        _active={{ color: 'brand.200' }}
        color="brand.200"
        onClick={() => toggleURLQueryParam('isCommentModeActive', 'true')}
      />
    </Tooltip>
  )
}
