import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { createInstitutionApi } from '../../apis/institutionApi/institutionApi'
import FormStepNotSelected from '../../components/FormStepNotSelected'
import Headbar from '../../components/Headbar'
import StepsImage from '../../components/StepsImage'
import useCreateInstitutionStore from '../../store/useCreateInstitutionStore'
import FontsUtils from '../../theme/foundations/FontsUtils'
import { institutionGradeTypeLabels } from '../../utils/institutionGradeTypes'

const validationSchema = yup.object({
  name: yup.string().required().min(2).max(200),
  maxGrade: yup.number().typeError('Obrigatório').min(1).max(100000).required(),
  institutionGradeType: yup.string().required(),
})

function CreateInstitutionStep1(props) {
  const { handleReturn, handlingNextStep } = props
  const { createInstitutionData } = useCreateInstitutionStore()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const onSubmit = async (data) => {
    try {
      const body = {
        ...data,
        institutionGradeType: Number.parseInt(data.institutionGradeType, 10),
        maxGrade: Number.parseInt(data.maxGrade, 10),
      }
      const result = await createInstitutionApi(createInstitutionData.schoolId, body)
      handlingNextStep(result)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <VStack w={'100%'} h={'100%'}>
      <Headbar returnTo={-1} />
      <VStack w={'100%'} maxW={'500px'} p={4}>
        <Heading color="brand.200" alignSelf={'start'}>
          Criar Instituição
        </Heading>
        <VStack w={'100%'} mt={5}>
          <form style={{ width: '100%', height: '100%' }} onSubmit={handleSubmit(onSubmit)} noValidate>
            <StepsImage text={'Sobre a instituição'} number={1} />
            <Grid width={'100%'} gap={5} mt={'32px'}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.name}>
                  <FormLabel className="form-label publicsans-bold-nevada-16px">Nome*</FormLabel>
                  <Input borderColor={'brand.300'} backgroundColor={'white'} height={'48px'} {...register('name')} />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.institutionGradeType}>
                  <FormLabel className="form-label publicsans-bold-nevada-16px">Tipo de avaliação</FormLabel>
                  <Select
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    height={'48px'}
                    placeholder={'Selecionar a forma de avaliação dessa instituição'}
                    {...register('institutionGradeType')}
                    defaultValue={'10'}>
                    {Object.entries(institutionGradeTypeLabels).map((option, key) => (
                      <option className={'select-grade-type-text'} key={key} value={option[0]}>
                        {option[1]}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.institutionGradeType?.message}</FormErrorMessage>
                  <Text mt={1} className="form-description publicsans-normal-gray-16px">
                    {createInstitutionData.institutionGradeType === '0'
                      ? 'A nota final da redação será composta pela média das competências.'
                      : 'A nota final da redação será composta pela soma das competências.'}
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.maxGrade}>
                  <FormLabel className="form-label publicsans-bold-nevada-16px">Nota máxima*</FormLabel>
                  <Input borderColor={'brand.300'} backgroundColor={'white'} height={'48px'} {...register('maxGrade')} />
                  <FormErrorMessage>{errors.maxGrade?.message}</FormErrorMessage>
                  <Text mt={1} className="form-description publicsans-normal-gray-16px">
                    É a nota máxima possível das redações associadas a essa instituição, por exemplo a nota máxima da
                    instituição ENEM é 1000.
                  </Text>
                </FormControl>
              </GridItem>
            </Grid>

            <Box mt={5}>
              <FormStepNotSelected number={2} configuraesDaAtividade={'Enviar'} />
            </Box>

            <HStack mt={5} width={'100%'} flexDir={{ base: 'column-reverse', md: 'row' }}>
              <Button width={'100%'} height={'48px'} variant="outline" isDisabled={isSubmitting} onClick={handleReturn}>
                Voltar
              </Button>
              <Button
                type={'submit'}
                width={'100%'}
                height={'48px'}
                variant="primary"
                fontSize={'m'}
                isLoading={isSubmitting}
                sx={FontsUtils.publicSansBoldWhite}>
                Continuar
              </Button>
            </HStack>
          </form>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default CreateInstitutionStep1
