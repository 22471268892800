import { usePostApiV1AccountConfirmEmail } from '@/services/auth'
import { Box, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import HeaderLogin from '../../components/LoginHeader'

function AccountConfirmation() {
  const navigate = useNavigate()
  const postAccountConfirmation = usePostApiV1AccountConfirmEmail({
    mutation: {
      onSuccess: () => {
        toast.success('Conta confirmada.')
        navigate('/login')
      },
      onError: (error) => {
        if (error?.response?.status === 400) return toast.error('Conta já foi confirmada.')
        toast.error('Entre em contato com o suporte do Correção Online')
      },
    },
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get('userId')
    const language = queryParams.get('language')
    const code = queryParams.get('code')?.replace(/ /g, '+')

    if (!code) toast.error('Código não presente na url!')
    if (!userId) toast.error('ID do usuário não presente na url!')
    if (!language) toast.error('Idioma não presente na url!')

    if (!code || !userId || !language) return navigate('/login')

    const data = { userId, language, code }
    postAccountConfirmation.mutate({ data })
  }, [])

  return (
    <VStack height={'100%'} gap={0} width={'100%'}>
      <HeaderLogin />
      <HStack width={'100%'} gap={0} height={'100%'} justifyContent={'center'}>
        <Box flex={1} height="100%" maxW="750px" display={{ base: 'none', md: 'block' }}>
          <IlustracaoLogin />
        </Box>
        <Box flex={1} alignSelf="center" sx={{ textAlign: 'center' }}>
          <VStack gap={8} maxWidth="700px">
            <Text color="brand.200" fontSize="3xl" fontWeight="bold">
              Confirmação de conta
            </Text>
            <Text fontSize="2xl" color="gray.600">
              Aguarde enquanto sua conta está sendo confirmada.
            </Text>
            <Spinner />
          </VStack>
        </Box>
      </HStack>
    </VStack>
  )
}

export default AccountConfirmation
