import {
  Box,
  Table as ChakraTable,
  Checkbox,
  Icon,
  Skeleton,
  StatDownArrow,
  StatUpArrow,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react'
import { forwardRef, useEffect, useMemo, useRef } from 'react'
import { useRowSelect, useSortBy, useTable } from 'react-table'

import { colors } from '../../theme/foundations/colors'

const CHECKBOX_CLASS = 'checkbox-col'

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <Checkbox colorScheme="linkedin" iconColor="white" ref={resolvedRef} height="100%" {...rest} />
    </>
  )
})

export function Table({ columns, data, isLoading = true, setSelectedHitsFunction = null, limit = 12 }) {
  const skeletonData = useMemo(() => {
    const accessors = columns.map((object) => object.accessor)
    const samples = columns.map((object) => object.sample)
    return Array.from(Array(10), () => {
      const object = {}
      accessors.forEach((accessor, index) => {
        object[accessor] = samples[index]
      })
      return object
    })
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: isLoading && !data.length ? skeletonData : data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '40px',
          Header: ({ getToggleAllRowsSelectedProps, isAllRowsSelected }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} isChecked={isAllRowsSelected} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} isChecked={row.isSelected} />
            </div>
          ),
        },
        ...columns,
      ])
    },
  )

  function handleClick(e, row) {
    const acceptedElements = ['div', 'td', 'svg']
    if (acceptedElements.includes(e.target.localName) && e.target.classList[0] !== CHECKBOX_CLASS) {
      const cell = row.cells.find((cell) => cell.column.id === 'action')
      if (cell) cell.value()
    }
  }

  useEffect(() => {
    if (setSelectedHitsFunction) {
      const originalValues = selectedFlatRows.map((d) => d.original)
      setSelectedHitsFunction(originalValues)
    }
  }, [selectedRowIds])

  return (
    <Box minHeight={rows.length && '65vh'}>
      <ChakraTable backgroundColor="white" borderRadius="10px" boxShadow="5px 8px 24px #00000017" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => {
            const { key, headerGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Tr key={key} {...headerGroupProps} backgroundColor={'#F3F5FF'}>
                {headerGroup.headers.map((column) => {
                  const { key, ...headerProps } = column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    style: { textAlign: column['textAlign'] },
                  })
                  return (
                    column.id !== 'action' && (
                      <Th key={key} {...headerProps} isNumeric={column.isNumeric} padding="10px" textAlign="center">
                        <strong>{column.render('Header')}</strong>
                        <chakra.span pl="4">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={StatDownArrow} />
                            ) : (
                              <Icon as={StatUpArrow} />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    )
                  )
                })}
              </Tr>
            )
          })}
        </Thead>
        <Tbody minHeight={'60vh'} {...getTableBodyProps()}>
          {rows.slice(0, limit).map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            return (
              <Tr
                key={key}
                {...rowProps}
                backgroundColor={row.isSelected && colors.brand[300]}
                _hover={{ backgroundColor: !row.isSelected && '#e4e6f0' }}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = {
                    ...cell.getCellProps({
                      style: {
                        width: cell.column.width !== 150 ? cell.column.width : 'auto',
                        textAlign: cell.column['textAlign'],
                      },
                    }),
                  }
                  return (
                    cell.column.id !== 'action' && (
                      <Td
                        className={cell.column.id === 'selection' ? CHECKBOX_CLASS : null}
                        key={key}
                        paddingX={2}
                        paddingY={7}
                        alignContent={'center'}
                        borderRight="1px solid #dfe2f2"
                        cursor={cell.column.id === 'details' ? 'pointer' : 'default'}
                        onClick={(e) => (cell.column.id === 'details' ? handleClick(e, row) : null)}
                        {...cellProps}>
                        <Skeleton isLoaded={!isLoading && data.length}>
                          {typeof cell.value === 'function' ? cell.value() : cell.render('Cell')}
                        </Skeleton>
                      </Td>
                    )
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </ChakraTable>
    </Box>
  )
}
