import { debounce } from '@/utils/debounce'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { usePutApiV1SharedAddressSchoolSchoolId } from '../../services/shared'
import { inputCEPMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  street: yup.string().required().min(5).max(150),
  number: yup.string().required().min(1).max(10),
  zipCode: yup.string().required().zipCode(),
  city: yup.string().required().max(50),
  state: yup.string().required().max(50),
  complement: yup.string().max(50),
})

const ModalEditAddressSchool = ({ isOpen, onClose, schoolId, schoolData, refetch }) => {
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      street: schoolData?.address?.street || '',
      number: schoolData?.address?.number || '',
      zipCode: schoolData?.address?.zipCode || '',
      city: schoolData?.address?.city || '',
      state: schoolData?.address?.state || '',
      complement: schoolData?.address?.complement || '',
    },
  })

  useEffect(() => {
    setValue('street', schoolData?.address?.street || '')
    setValue('number', schoolData?.address?.number || '')
    setValue('zipCode', schoolData?.address?.zipCode || '')
    setValue('city', schoolData?.address?.city || '')
    setValue('state', schoolData?.address?.state || '')
    setValue('complement', schoolData?.address?.complement || '')
  }, [schoolData, setValue])

  const putApiV1SharedAddressSchoolSchoolId = usePutApiV1SharedAddressSchoolSchoolId({
    mutation: {
      onSuccess: () => {
        toast.success('Endereço alterado com sucesso.')
        onClose()
        refetch()
      },
    },
  })

  const fetchAddress = async (zipCode) => {
    try {
      await validationSchema.validateAt('zipCode', { zipCode: zipCode })

      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      const data = await response.json()

      if (data.erro) {
        resetAddress('CEP não encontrado')
      } else {
        setValue('street', data.logradouro)
        setValue('city', data.localidade)
        setValue('state', data.uf)
        clearErrors()
      }
    } catch (error) {
      resetAddress(error.message)
    }
  }

  const fetchAddressDebounce = useCallback(debounce(fetchAddress), [])
  const resetAddress = (message) => {
    setValue('street', '')
    setValue('city', '')
    setValue('state', '')
    setError('zipCode', { type: 'manual', message })
  }

  const onSubmit = (body) => {
    putApiV1SharedAddressSchoolSchoolId.mutate({
      schoolId: schoolId,
      data: body,
    })
  }

  return (
    <Modal isOpen={isOpen} size={'2xl'} onClose={onClose} isCentered={true} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
          Editar Endereço da Escola
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid mt={4} gap={5} width={'100%'}>
              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.zipCode?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">CEP</FormLabel>
                  <Input
                    as={InputMask}
                    mask={inputCEPMask}
                    borderColor={'brand.300'}
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    {...register('zipCode')}
                    onChange={(e) => {
                      fetchAddressDebounce(e.target.value.replace(/\D/g, ''))
                    }}
                  />
                  <FormErrorMessage>{errors.zipCode?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <Grid templateColumns={{ sm: '1fr', md: '3fr 1fr' }} gap={4}>
                  <GridItem>
                    <FormControl isInvalid={errors.street?.message}>
                      <FormLabel className="publicsans-bold-nevada-16px">Rua</FormLabel>
                      <Input
                        h={'48px'}
                        w={'100%'}
                        borderColor={'brand.300'}
                        backgroundColor={'white'}
                        {...register('street')}
                        isDisabled={true}
                      />
                      <FormErrorMessage>{errors.street?.message}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={errors.number?.message}>
                      <FormLabel className="publicsans-bold-nevada-16px">Número</FormLabel>
                      <Input
                        h={'48px'}
                        w={'100%'}
                        borderColor={'brand.300'}
                        backgroundColor={'white'}
                        {...register('number')}
                      />
                      <FormErrorMessage>{errors.number?.message}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.complement?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">Complemento</FormLabel>
                  <Input
                    h={'48px'}
                    w={'100%'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    {...register('complement')}
                  />
                  <FormErrorMessage>{errors.complement?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.city?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">Cidade</FormLabel>
                  <Input
                    h={'48px'}
                    w={'100%'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    {...register('city')}
                    isDisabled={true}
                  />
                  <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ sm: 3, lg: 2 }}>
                <FormControl isInvalid={errors.state?.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">Estado</FormLabel>
                  <Input
                    h={'48px'}
                    w={'100%'}
                    borderColor={'brand.300'}
                    backgroundColor={'white'}
                    {...register('state')}
                    isDisabled={true}
                  />
                  <FormErrorMessage>{errors.state?.message}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <ModalFooter
              width={'100%'}
              alignSelf={'center'}
              gap={{ md: '32px', base: '8px' }}
              flexDir={{ md: 'row', base: 'column-reverse' }}>
              <Button
                backgroundColor={'#f9faff'}
                isDisabled={putApiV1SharedAddressSchoolSchoolId.isPending}
                borderColor={'#648BF7'}
                border={'1px'}
                borderRadius={'8px'}
                color={'#062989'}
                height={'48px'}
                width={'100%'}
                onClick={onClose}>
                Cancelar
              </Button>
              <Button
                variant={'primary'}
                type="submit"
                isLoading={putApiV1SharedAddressSchoolSchoolId.isPending}
                height={'48px'}
                width={'100%'}>
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalEditAddressSchool
