import type { StyleFunctionProps } from '@chakra-ui/react'
// @ts-ignore-file
import { MultiSelectTheme } from 'chakra-multiselect'

export const MultiSelect = {
  ...MultiSelectTheme,
  baseStyle: (props: StyleFunctionProps) => ({
    ...MultiSelectTheme.baseStyle(props),

    control: {
      ...MultiSelectTheme.baseStyle(props).control,
      border: '1px solid var(--hawkes-blue)',
      minHeight: '48px',
      bgColor: 'white',
      minWidth: '100%',
    },

    list: {
      ...MultiSelectTheme.baseStyle(props).list,
      border: '1px solid var(--hawkes-blue)',
      boxShadow: '0px 4px 16px #00000020',
      emptyResultsLabel: 'Sem resultados',
    },

    actionGroup: {
      ...MultiSelectTheme.baseStyle(props).actionGroup,
      clearButtonProps: {
        color: 'gray.400',
        'aria-label': 'clear',
      },
      toggleButtonProps: {
        color: '#0A41D6',
        'aria-label': 'arrow',
        marginRight: '10px',
      },
    },
  }),
}
