/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { ActivityWithStudentViewModel } from './activityWithStudentViewModel';
import type { KeyValueViewModel } from './keyValueViewModel';

export interface ThemeViewModel {
  /** @nullable */
  activities?: ActivityWithStudentViewModel[] | null;
  amountActivities?: number;
  /** @nullable */
  base64Image?: string | null;
  createdAt?: string;
  /** @nullable */
  description?: string | null;
  id?: string;
  school?: KeyValueViewModel;
  /** @nullable */
  subject?: string | null;
}
