import './DateWithCalendarIcon.css'
import { Image } from '@chakra-ui/react'
import { differenceInDays, isPast } from 'date-fns'
import { abbreviatedMonth } from '../../utils/DateSatinizerInput'

type DateWithCalendarIconProps = {
  imagePath: string
  date: Date
  shouldHighlight?: boolean
}

function DateWithCalendarIcon({ imagePath, date, shouldHighlight = false }: DateWithCalendarIconProps) {
  const currentDate = new Date()
  const daysUntilDeadline = differenceInDays(date, currentDate)

  let iconColor = 'none'

  if (shouldHighlight) {
    if (isPast(date)) {
      iconColor = 'invert(31%) sepia(100%) saturate(5457%) hue-rotate(0deg) brightness(90%) contrast(108%)'
    } else if (daysUntilDeadline <= 3) {
      iconColor = 'invert(38%) sepia(94%) saturate(2000%) hue-rotate(360deg) brightness(80%) contrast(110%)'
    }
  }

  return (
    <div className="data">
      <Image className="image-style" src={imagePath} alt="calendar icon" style={{ filter: iconColor }} />
      <div className="date publicsans-normal-nevada-14px">{abbreviatedMonth(date)}</div>
    </div>
  )
}

export default DateWithCalendarIcon
