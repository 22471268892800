import './InstitutionArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useEffect, useState } from 'react'
import { listAllInstitutionsFromSchool } from '../../apis/institutionApi/institutionApi'
import InstitutionCard from '../../components/InstitutionCard'
import SearchComponent from '../../components/SearchComponent'
import SharedCardPagination from '../../components/SharedCardPagination'
import useCreateInstitutionStore from '../../store/useCreateInstitutionStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'

function InstitutionArea() {
  const { loggedUser } = useLoggedUserStore()
  const itemsPerPage = window.innerWidth > 2390 ? 3 : 5
  const { setCreateInstitutionData } = useCreateInstitutionStore()

  const [institutions, setInstitutions] = useState()
  const [institutionsData, setInstitutionsData] = useState()

  useEffect(() => {
    fetchAllInstitutions()
  }, [])

  async function fetchAllInstitutions() {
    const data = await listAllInstitutionsFromSchool(loggedUser.schoolId)
    setInstitutionsData(data)
    setCreateInstitutionData({ schoolId: loggedUser.schoolId })
  }

  useEffect(() => {
    setInstitutions(institutionsData)
  }, [institutionsData])

  function handleSearchInputChange(e) {
    const inputText = e.target.value

    setInstitutions(
      institutionsData.filter((item) => {
        return item.name.toLowerCase().includes(inputText.toLowerCase())
      }),
    )
  }

  return (
    <Page authPage={true} allowedUserType={'independentteacher'}>
      <LayoutMenu isLoading={false}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Instituições"
            button={{
              isVisible: loggedUser.user_role !== 'student',
              navigateTo: '/criar-instituicao',
              label: 'Criar instituição',
            }}
          />

          <SearchComponent handleSearchInputChange={handleSearchInputChange} />

          {institutions ? (
            <SharedCardPagination
              data={institutions}
              RenderComponent={InstitutionCard}
              itemsPerPage={itemsPerPage}
              props={{ fetchAllInstitutions, schoolId: loggedUser.schoolId }}
            />
          ) : null}
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default InstitutionArea
