import { Box, Image, Input, Tooltip } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const DatePickerInput = forwardRef(({ value, onClick, tooltipLabel, ...props }, ref) => (
  <Tooltip label={tooltipLabel} isDisabled={!tooltipLabel}>
    <Box
      onClick={onClick}
      display="flex"
      alignItems="center"
      backgroundColor={'white'}
      border="1px solid #d7e3fb"
      borderRadius="md">
      <Image src="/img/calendar-2851774-10@2x.png" alt="calendar" boxSize="24px" marginLeft={'14px'} />
      <Input
        ref={ref}
        value={value}
        placeholder="Selecione a data"
        readOnly
        {...props}
        h={'48px'}
        fontFamily={'Public Sans-Regular, Helvetica'}
        color={'#828282'}
        border={'none'}
      />
    </Box>
  </Tooltip>
))
