import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Params {
  [key: string]: string
}

export const useToggleURLQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const newParams: Params = {}
    searchParams.forEach((value, key) => {
      newParams[key] = value
    })
  }, [location.search])

  const toggleURLQueryParam = (paramName: string, paramValue: string) => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has(paramName)) {
      searchParams.delete(paramName)
    } else {
      searchParams.set(paramName, paramValue)
    }
    navigate({ pathname: location.pathname, search: searchParams.toString() })
  }

  const deleteURLQueryParams = (paramNames: string[]) => {
    const searchParams = new URLSearchParams(location.search)
    paramNames.forEach((paramName) => {
      searchParams.delete(paramName)
    })
    navigate({ pathname: location.pathname, search: searchParams.toString() })
  }

  const hasURLQueryParams = (paramNames: string[]) => {
    const searchParams = new URLSearchParams(location.search)
    return paramNames.some((paramName) => searchParams.has(paramName))
  }

  return { hasURLQueryParams, toggleURLQueryParam, deleteURLQueryParams }
}
