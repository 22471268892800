import './CreateInstitution.css'
import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useCreateInstitutionStore, { STEP_INSTITUTION } from '../../store/useCreateInstitutionStore'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'
import SuccessfulCreationPage from '../SuccessfulCreationPage'
import CreateInstitutionStep1 from './CreateInstitutionStep1'

const CreateInstitution = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()
  const { loggedUser } = useLoggedUserStore()

  const { cleanCreateInstitutionData } = useCreateInstitutionStore()

  const handleNextStep = () => {
    return new Promise((resolve) => {
      if (currentStep === STEP_INSTITUTION.ABOUT) {
        setCurrentStep(currentStep + 1)
        cleanCreateInstitutionData()
        resolve()
      } else {
        navigate(-1)
        resolve()
      }
    })
  }

  const handlePreviousStep = () => {
    setCurrentStep(currentStep === STEP_INSTITUTION.ABOUT)
    cleanCreateInstitutionData()
    if (loggedUser.user_role === 'independentteacher') {
      navigate('/area-de-instituicoes')
    } else {
      navigate(-1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_INSTITUTION.ABOUT:
        return <CreateInstitutionStep1 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
      case STEP_INSTITUTION.CREATED:
        return (
          <SuccessfulCreationPage
            handleRedirectReturn={handleNextStep}
            entityCreated={'instituição nova'}
            headbarLink={-1}
            listText={'Voltar'}
          />
        )
      default:
        return null
    }
  }

  return (
    <Page authPage={true} allowedUserType={'independentteacher adm'}>
      <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
        {renderStepContent()}
      </Flex>
    </Page>
  )
}

export default CreateInstitution
