import { create } from 'zustand'
import { StatusType } from '../utils/StatusType'

interface GroupTeacherViewModel {
  groups?: GroupTeacherViewModel[] | null
  id?: string
  name?: string | null
}

interface LoggedUser {
  user_email: string
  user_role: string
  user_language: string
  user_name: string
  user_id: string
  institutionId: string
  schoolId: string
  schools: GroupTeacherViewModel[] | null
}

interface LoggedUserStore {
  loggedUser: LoggedUser
  status: StatusType
  setStatus: (data: StatusType) => void
  setLoggedUserData: (data: Partial<LoggedUser>) => void
  cleanLoggedUserData: () => void
}

const useLoggedUserStore = create<LoggedUserStore>((set) => ({
  loggedUser: {
    user_email: localStorage.getItem('user_email') || '',
    user_role: localStorage.getItem('user_role') || '',
    user_language: localStorage.getItem('user_language') || '',
    user_name: localStorage.getItem('user_name') || '',
    user_id: localStorage.getItem('user_id') || '',
    institutionId: '',
    schoolId: localStorage.getItem('schoolId') || '',
    schools: [],
  },
  status: StatusType.IDLE,
  setStatus: (data) => set(() => ({ status: data })),
  setLoggedUserData: (data) => {
    const key = Object.keys(data)[0] as keyof LoggedUser
    const value = Object.values(data)[0] as string
    localStorage.setItem(key, value)
    set((state) => ({
      loggedUser: { ...state.loggedUser, ...data },
    }))
  },
  cleanLoggedUserData: () =>
    set({
      loggedUser: {
        user_email: '',
        user_role: '',
        user_language: '',
        user_name: '',
        user_id: '',
        institutionId: '',
        schoolId: '',
        schools: null,
      },
    }),
}))

export default useLoggedUserStore
