import { useEffect } from 'react'
import './GetModalEditGroup.css'
import { shiftMapping } from '@/utils/shiftENUM.ts'
import { useBackNavigation } from '@/utils/useBackNavigation.ts'
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { useGetApiV1SchoolSchoolIdGroupGroupId, usePutApiV1SchoolSchoolIdGroup } from '../../services/school'

interface ModalDisclosure {
  isOpen: boolean
  onClose: () => void
}

interface GroupsData {
  id: string
  name: string
  description: string
  shift: string | number
}

interface GetModalEditGroupProps {
  modalDisclosure: ModalDisclosure
  groupsData: GroupsData
  setGroupsData: (data: GroupsData) => void
  schoolId: string
}

interface FormValues {
  name: string
  description: string | null
  shift: string
}

interface UpdateSchoolGroupViewModel {
  name: string
  description?: string | null
  shift?: number
  groupId: string
}

const validationSchema = yup
  .object({
    name: yup.string().required().min(6).max(50),
    description: yup.string().nullable().max(200).default(null),
    shift: yup.string().required(),
  })
  .required()

function GetModalEditGroup({ modalDisclosure, groupsData, setGroupsData, schoolId }: GetModalEditGroupProps) {
  const { data: groupInformation, refetch: fetchGroup } = useGetApiV1SchoolSchoolIdGroupGroupId(schoolId, groupsData.id)
  const handleBackNavigation = useBackNavigation()

  if (!schoolId) {
    toast.error('Escola não encontrada')
    handleBackNavigation()
  }

  useEffect(() => {
    if (groupInformation?.data) {
      setGroupsData({
        id: groupInformation.data?.message?.id || '',
        name: groupInformation.data?.message?.name || '',
        description: groupInformation.data?.message?.description || '',
        shift: groupInformation.data?.message?.shift || '0',
      })
    }
  }, [groupInformation, setGroupsData])

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: groupsData.name,
      description: groupsData.description,
      shift: String(groupsData.shift),
    },
  })

  const { mutate: putEditGroup, isPending } = usePutApiV1SchoolSchoolIdGroup({
    mutation: {
      onSuccess: async () => {
        await fetchGroup()
        modalDisclosure.onClose()
      },
      onError: () => {
        console.error('Erro ao editar a turma')
      },
    },
  })

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const updatedData: UpdateSchoolGroupViewModel = {
      ...data,
      shift: Number(data.shift),
      groupId: groupsData.id,
    }
    putEditGroup({ schoolId: schoolId, data: updatedData })
  }

  return (
    <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} isCentered={true} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent width={['300px', '100%']}>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
          Editar Turma
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <ModalBody>
          <VStack alignItems={'flex-start'} gap={'16px'}>
            <div className={'publicsans-bold-nevada-16px'}>Nome da turma*</div>
            <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
              <Input className={`input-default-style ${errors.name ? 'error-input' : ''}`} {...register('name')} />
              <Text
                fontSize="sm"
                position="absolute"
                zIndex={999}
                bottom="5px"
                right="20px"
                color="#a9a8a8"
                fontFamily={'Public Sans'}>
                {watch('name')?.length ?? 0}/50
              </Text>
            </Box>
            {errors.name && <Text color="red">{errors.name.message}</Text>}

            <div className={'publicsans-bold-nevada-16px'}>Mensagem Personalizada</div>
            <Box position="relative" w={'100%'} h={'100%'} backgroundColor={'white'}>
              <Textarea
                placeholder=""
                {...register('description')}
                resize="vertical"
                w={'100%'}
                h={'100%'}
                minHeight={'150px'}
                borderColor={errors.description ? 'red' : '#d7e3fb'}
                className={errors.description ? 'error-input' : ''}
              />
              <Text
                fontSize="sm"
                position="absolute"
                zIndex={999}
                bottom="5px"
                right="20px"
                color="#a9a8a8"
                fontFamily={'Public Sans'}>
                {watch('description')?.length ?? 0}/200
              </Text>
            </Box>
            {errors.description && <Text color="red">{errors.description.message}</Text>}

            <div className={'publicsans-bold-nevada-16px'}>Turno*</div>
            <Box position="relative" w={'100%'} h={'100%'}>
              <Select
                placeholder="Selecionar Turno"
                {...register('shift')}
                isInvalid={!!errors.shift}
                defaultValue={groupsData.shift}
                className={`input-default-style ${errors.shift ? 'error-input' : ''}`}>
                {Object.entries(shiftMapping).map(([value, label], key) => (
                  <option className={'select-tema-text'} key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              {errors.shift && <Text color="red">{errors.shift.message}</Text>}
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter
          alignSelf={'center'}
          width={'100%'}
          gap={{ md: '32px', base: '8px' }}
          flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Button variant={'outline'} height={'48px'} width={'100%'} isDisabled={isPending} onClick={modalDisclosure.onClose}>
            Cancelar
          </Button>
          <Button variant={'primary'} height={'48px'} width={'100%'} isLoading={isPending} onClick={handleSubmit(onSubmit)}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GetModalEditGroup
