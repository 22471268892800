import { Container, Text } from '@chakra-ui/react'

function NoContentContainer({ children }) {
  return (
    <Container
      backgroundColor={'brand.600'}
      width={'100%'}
      borderRadius={'12px'}
      minHeight={'100px'}
      maxWidth={'100%'}
      alignContent={'center'}
      textAlign={'center'}>
      <Text className={'publicsans-normal-nevada-16px'}>{children}</Text>
    </Container>
  )
}

export default NoContentContainer
