/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-10-27 17:35:14 UTC)
 * OpenAPI spec version: v1
 */
import type { KeyValueViewModel } from './keyValueViewModel';
import type { EssayBalanceTransactionSource } from './essayBalanceTransactionSource';
import type { EssayBalanceTransactionType } from './essayBalanceTransactionType';

export interface SchoolEssayTransactionViewModel {
  activity?: KeyValueViewModel;
  amount?: number;
  date?: string;
  id?: string;
  school?: KeyValueViewModel;
  source?: EssayBalanceTransactionSource;
  teacher?: KeyValueViewModel;
  type?: EssayBalanceTransactionType;
}
