import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { updateSchoolApi } from '../../apis/schoolApi/schoolApi'
import { putApiV1SchoolUpdateThumbnail } from '../../services/school'
import { ImageUploadArea } from '../ImageUploadArea/ImageUploadArea'

const validationSchema = yup.object({
  name: yup.string().required().min(5).max(254),
  thumbnail: yup.string(),
})

const ModalEditSchool = ({ isOpen, onClose, schoolId, schoolData, refetch }) => {
  const [selectedImage, setSelectedImage] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: schoolData?.name,
    },
  })

  const [loading, setLoading] = useState(false)

  const handleImageUpload = (image) => {
    setValue('thumbnail', image)
    setSelectedImage(true)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    await updateSchoolApi({ id: schoolId, name: data.name }).then(async () => {
      const requestBody = { id: schoolId, thumbnail: data.thumbnail }

      if (selectedImage) {
        await putApiV1SchoolUpdateThumbnail(requestBody)
        setSelectedImage(false)
      }
    })
    toast.success('Escola editada com sucesso')
    onClose()
    refetch()
    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} size={'2xl'} onClose={onClose} isCentered={true} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-atividade'}>
          Editar Escola
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack alignItems={'flex-start'} gap={'16px'}>
              <FormControl isInvalid={errors.thumbnail && !!errors.thumbnail.message}>
                <FormLabel className={'publicsans-bold-nevada-16px'}>Imagem da escola</FormLabel>
                <ImageUploadArea onUpload={handleImageUpload} preImage={schoolData?.thumbnail} />
                <FormErrorMessage>{errors.thumbnail?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name">Nome da Escola</FormLabel>
                <Input id="name" defaultValue={schoolData?.name} {...register('name')} />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter alignSelf={'center'} gap={{ md: '32px', base: '8px' }} flexDir={{ md: 'row', base: 'column-reverse' }}>
            <Button
              backgroundColor={'#f9faff'}
              isLoading={loading}
              borderColor={'#648BF7'}
              border={'1px'}
              borderRadius={'8px'}
              color={'#062989'}
              height={'48px'}
              width={'218px'}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              backgroundColor={'#0A41D6'}
              isLoading={loading}
              border={'1px'}
              borderRadius={'8px'}
              color={'#FFFFFF'}
              height={'48px'}
              width={'218px'}
              type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ModalEditSchool
