import useNavigationStoreHistory from '@/store/useNavigationStoreHistory'
import handleRedirectPath from '@/utils/handleRedirectPath'
import { useNavigate } from 'react-router-dom'

interface UseBackNavigationProps {
  returnTo?: string
}

export const useBackNavigation = ({ returnTo }: UseBackNavigationProps = {}) => {
  const navigate = useNavigate()
  const getPreviousPage = useNavigationStoreHistory((state) => state.getPreviousPage)
  const goBack = useNavigationStoreHistory((state) => state.goBack)

  const handleBackNavigation = () => {
    const previousPage = getPreviousPage()
    if (previousPage) {
      navigate(previousPage)
      goBack()
    } else {
      navigate(returnTo || handleRedirectPath())
    }
  }

  return handleBackNavigation
}
