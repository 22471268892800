import { useGetApiV1SchoolSchoolIdGroup } from '@/services/school.ts'
import { usePutApiV1TeacherTeacherId } from '@/services/teacher.ts'
import useLoggedUserStore from '@/store/useLoggedUserStore.ts'
import { inputPhoneMask } from '@/utils/inputMasks'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { MultiSelect } from 'chakra-multiselect'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { validatePhone } from 'validations-br'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  phoneNumber: yup
    .string()
    .nullable()
    .test('phone', 'Telefone inválido', (value) => {
      return !value || validatePhone(value)
    }),
  groups: yup.array().required().min(1, 'O monitor deve pertencer ao menos a uma turma'),
})

export interface MonitorFormValues {
  name: string
  phoneNumber?: string
  groups: string[]
}

export interface MonitorEditProps {
  monitorData: {
    id: string
    name: string
    phoneNumber: string
    groups: string[]
    schools: { groups: { name: string }[] }[]
  }
  isOpen: boolean
  onClose: () => void
  fetchMonitor: () => void
}

export function ModalEditMonitor({ monitorData, isOpen, onClose, fetchMonitor }: MonitorEditProps) {
  const { loggedUser } = useLoggedUserStore()

  const [turmaOptions, setTurmaOptions] = useState<{ value: string; label: string; id: string }[]>([])
  const [turmaValue, setTurmaValue] = useState<string[]>(monitorData?.schools[0].groups.map((item) => item.name))

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: monitorData.name,
      phoneNumber: monitorData.phoneNumber,
      groups: monitorData.groups,
    },
  })

  const { data: groupsList, isLoading: isFetchingGroups } = useGetApiV1SchoolSchoolIdGroup(loggedUser.schoolId)

  useEffect(() => {
    if (groupsList?.data?.message) {
      setTurmaOptions(
        groupsList.data.message.map((turma) => ({
          value: turma.name || '',
          label: turma.name || '',
          id: turma.id || '',
        })),
      )
    } else {
      setTurmaOptions([])
    }
  }, [groupsList])

  const { mutate: updateMonitor, isPending } = usePutApiV1TeacherTeacherId({
    mutation: {
      onSuccess: () => {
        fetchMonitor()
        toast.success('Monitor alterado com sucesso!')
        onClose()
      },
      onError: () => {
        toast.error('Erro ao atualizar o monitor.')
      },
    },
  })

  useEffect(() => {
    if (turmaValue && turmaOptions.length > 0) {
      const selectedGroups = turmaValue.map((turma) => turmaOptions.find((item) => turma === item.value)?.id)
      setValue('groups', selectedGroups)
    }
  }, [turmaValue, turmaOptions, setValue])

  const onSubmit: SubmitHandler<MonitorFormValues> = (data) => {
    updateMonitor({ teacherId: monitorData.id, data })
  }

  return (
    <Modal isOpen={isOpen} size={'2xl'} onClose={onClose} isCentered={true} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={'400'} className={'title-modal-detalhamento-monitor'}>
          Editar Monitor
        </ModalHeader>
        <ModalCloseButton color={'#0a41d6'} marginTop={'5px'} />
        <ModalBody>
          <VStack alignItems={'flex-start'} gap={'16px'}>
            <FormControl isInvalid={!!errors.name}>
              <div className={'publicsans-bold-nevada-16px'}>Nome do monitor*</div>
              <Input h={'48px'} w={'100%'} backgroundColor={'white'} borderColor={'#d7e3fb'} {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phoneNumber}>
              <div className={'publicsans-bold-nevada-16px'}>Número do monitor</div>
              <Input
                h={'48px'}
                w={'100%'}
                backgroundColor={'white'}
                borderColor={'#d7e3fb'}
                {...register('phoneNumber')}
                as={InputMask}
                mask={inputPhoneMask}
              />
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.groups}>
              <div className={'publicsans-bold-nevada-16px'}>Turmas vinculadas ao monitor</div>
              <Box w={'100%'} h={'100%'}>
                <MultiSelect
                  placeholder={isFetchingGroups ? 'Aguarde um momento...' : 'Selecionar turma'}
                  onChange={(e) => {
                    if (Array.isArray(e)) {
                      setTurmaValue(
                        e.map((turma) => {
                          if (typeof turma === 'object' && turma?.value) {
                            return String(turma.value)
                          }
                          return String(turma)
                        }),
                      )
                    }
                  }}
                  borderColor={'#d7e3fb'}
                  backgroundColor={'white'}
                  disabled={isFetchingGroups}
                  options={turmaOptions}
                  value={turmaValue}
                  w={'100%'}
                  height={'100%'}
                />
                <FormErrorMessage>{errors.groups?.message}</FormErrorMessage>
              </Box>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter
          width={'100%'}
          alignSelf={'center'}
          gap={{ md: '32px', base: '8px' }}
          flexDir={{ md: 'row', base: 'column-reverse' }}>
          <Button variant={'outline'} height={'48px'} width={'100%'} onClick={onClose} disabled={isPending}>
            Cancelar
          </Button>
          <Button variant={'primary'} height={'48px'} width={'100%'} onClick={handleSubmit(onSubmit)} isLoading={isPending}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
