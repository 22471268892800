import { usePostApiV1SchoolSchoolIdGroup } from '@/services/school.ts'
import type { NewSchoolGroupViewModel } from '@/services/types'
import { type ShiftMappingKey, shiftMapping } from '@/utils/shiftENUM'
import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required().min(5).max(50),
  shift: yup.string().required().default('0'),
  description: yup.string().nullable().max(200).default(null),
})

interface PopoverAdicionarTurmaProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  fetchGroups: () => void
  schoolId: string
}

interface FormValues {
  name: string
  description: string | null
  shift: string
}

function PopoverAddGroup({ isOpen, onOpen, onClose, fetchGroups, schoolId }: PopoverAdicionarTurmaProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const { mutate: createGroupApi, isPending: isPendingGroupCreation } = usePostApiV1SchoolSchoolIdGroup({
    mutation: {
      onSuccess: () => {
        toast.success('Turma adicionada com sucesso.')
        fetchGroups()
        reset()
      },
      onError: () => {
        toast.error('Erro ao adicionar turma.')
      },
    },
  })

  const submit = (data: FormValues) => {
    const newData: NewSchoolGroupViewModel = {
      ...data,
      shift: Number(data.shift),
    }
    createGroupApi({ schoolId, data: newData })
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom-end" closeOnBlur={false}>
      <PopoverTrigger>
        <div className="button-detalhamento-tema" onClick={onOpen}>
          <div className="mainbutton-detalhamento-tema custom-button" style={{ height: '48px' }}>
            <div className="content-2-detalhamento-tema">
              <img
                className="ic-add-detalhamento-tema"
                src={'/img/ic-add-1.svg'}
                alt="button-addStudent"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
              <Text hideBelow={'md'} className="main-button-detalhamento-tema custom-button-text publicsans-bold-white-14px">
                {'Adicionar Turma'}
              </Text>
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent maxWidth={'300px'} width={['280px', '448px']} borderRadius={'10px'}>
        <PopoverArrow />
        <PopoverCloseButton marginTop={'7px'} color={'#0a41d6'} />
        <PopoverHeader>
          <div className={'text-popover'}>Nova turma</div>
        </PopoverHeader>
        <PopoverBody height={'100%'}>
          <form onSubmit={handleSubmit(submit)} noValidate>
            <VStack gap={3} alignItems={'start'}>
              <Input
                className="input-default-style"
                {...register('name')}
                placeholder={'Digite o nome da turma'}
                type="text"
                isInvalid={!!errors.name}
                errorBorderColor="red.300"
              />
              {errors.name && <Text color="red.500">{errors.name.message}</Text>}
              <Input
                className="input-default-style"
                {...register('description')}
                placeholder={'Informe uma descrição da turma (opcional)'}
                type="text"
              />
              <Box w={'100%'} h={'48px'}>
                <Select
                  placeholder={isPendingGroupCreation ? 'Aguarde um momento...' : 'Selecionar Turno da turma'}
                  {...register('shift')}
                  isDisabled={isPendingGroupCreation}
                  isInvalid={!!errors.shift}
                  errorBorderColor="red.300">
                  {Object.entries(shiftMapping).map(([value, label], key) => (
                    <option className={'select-tema-text'} key={key} value={value as unknown as ShiftMappingKey}>
                      {label}
                    </option>
                  ))}
                </Select>
                {errors.shift && <Text color="red.500">{errors.shift.message}</Text>}
              </Box>
              <Button
                variant={'primary'}
                isLoading={isPendingGroupCreation}
                color={'white'}
                width={'100%'}
                height={'48px'}
                type="submit"
                mt={3}>
                Adicionar
              </Button>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverAddGroup
