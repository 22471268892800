import { RoleTypes } from './RoleTypes'

export default function handleRedirectPath() {
  const role = localStorage.getItem('user_role')

  let result

  switch (role) {
    case RoleTypes.INDEPENDENT_TEACHER:
      result = '/inicio-professor-independente'
      break
    case RoleTypes.MONITOR:
    case RoleTypes.TEACHER:
      result = '/inicio-professor'
      break
    case RoleTypes.STUDENT:
      result = '/inicio-aluno'
      break
    case RoleTypes.OWNER:
      result = '/usuarios'
      break
    case RoleTypes.ADM:
      result = '/inicio-adm'
      break
    default:
      break
  }

  return result
}
