import { httpRequestHelper } from '../ApiMiddleware'
import { BACKEND_API } from '../Constants'

export const listAllSchoolsOfLoggedUserApi = async () => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school`)

  return response.data.message
}

export const addBatchOfStudentsApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/school/students`, data)

  return response.data
}

export const getAllStudentsApi = async (schoolID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/student/school/${schoolID}`)

  return response.data.message
}

export const getAllAssistantAccountsApi = async (schoolID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolID}/assistant/balance`)
  return response.data.message
}

export const createSchoolApi = async (name, document) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/school`, {
    name,
    document,
  })

  return response.data.message
}

export const getAllAssistantTypesApi = async () => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/assistant/list-assistant-types`)
  return response.data.message
}

export const getSchoolByIdApi = async (schoolID) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/${schoolID}`)

  return response.data.message
}

export const createAssistantAccountApi = async (data) => {
  const response = await httpRequestHelper.postWithAuth(`${BACKEND_API}/v1/school/assistant/balance`, data)

  return response.data.message
}

export const updateAssistantBalanceApi = async (schoolID, newBalance) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school/${schoolID}/assistant/balance/${newBalance}`)

  return response.data.message
}

export const updateAssistantActivateApi = async (schoolID) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school/${schoolID}/assistant/activate-balance`)

  return response.data.message
}

export const updateAssistantDeactivateApi = async (schoolID) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school/${schoolID}/assistant/deactivate-balance`)

  return response.data.message
}

export const updateSchoolApi = async (data) => {
  const response = await httpRequestHelper.putWithAuth(`${BACKEND_API}/v1/school`, data)

  return response.data.message
}

export const deleteSchoolApi = async (schoolID) => {
  const response = await httpRequestHelper.delWithAuth(`${BACKEND_API}/v1/school/${schoolID}`)

  return response.data.message
}

export const getSchoolInfoAdmApi = async (daysFilter) => {
  const response = await httpRequestHelper.getWithAuth(`${BACKEND_API}/v1/school/school-adm?days=${daysFilter}`)

  return response.data.message
}
