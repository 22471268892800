import { ALLOWED_USERS } from '../../apis/Constants'
import handleRedirectPath from '../../utils/handleRedirectPath'

export type MenuItemType = {
  menuPicturePath: string
  selectedPicturePath: string
  menuTitle: string
  urlToBeSelected: string[]
  urlToRedirect: () => string
  allowedUsers: string[]
}

export const menuItemsList: MenuItemType[] = [
  {
    menuTitle: 'Início',
    menuPicturePath: '/img/home-menu-icon.svg',
    selectedPicturePath: '/img/home-menu-icon-focus.svg',
    urlToBeSelected: ['/inicio', '/usuarios'],
    urlToRedirect: () => handleRedirectPath(),
    allowedUsers: ALLOWED_USERS.INICIO,
  },
  {
    menuTitle: 'Atividades',
    menuPicturePath: '/img/redacoes-menu-icon.svg',
    selectedPicturePath: '/img/redacoes-menu-icon-focus.svg',
    urlToBeSelected: ['/area-de-atividades'],
    urlToRedirect: () => '/area-de-atividades',
    allowedUsers: ALLOWED_USERS.ATIVIDADES,
  },
  {
    menuTitle: 'Escolas',
    menuPicturePath: '/img/icon-category.svg',
    selectedPicturePath: '/img/icon-category-selected.svg',
    urlToBeSelected: ['/area-de-escolas'],
    urlToRedirect: () => '/area-de-escolas',
    allowedUsers: ALLOWED_USERS.ESCOLAS,
  },
  {
    menuTitle: 'Temas',
    menuPicturePath: '/img/icon-themes.svg',
    selectedPicturePath: '/img/icon-themes-selected.svg',
    urlToBeSelected: ['/area-de-temas'],
    urlToRedirect: () => '/area-de-temas',
    allowedUsers: ALLOWED_USERS.TEMAS,
  },
  {
    menuTitle: 'Instituições',
    menuPicturePath: '/img/institutionIcon.svg',
    selectedPicturePath: '/img/institutionIconSelected.svg',
    urlToBeSelected: ['/area-de-instituicoes'],
    urlToRedirect: () => '/area-de-instituicoes',
    allowedUsers: ALLOWED_USERS.INSTITUTION,
  },
  {
    menuTitle: 'Turmas',
    menuPicturePath: '/img/turma-menu-icon.svg',
    selectedPicturePath: '/img/turma-menu-icon-focus.svg',
    urlToBeSelected: ['/area-de-turmas'],
    urlToRedirect: () => '/area-de-turmas',
    allowedUsers: ALLOWED_USERS.TURMAS,
  },
  {
    menuTitle: 'Monitores',
    menuPicturePath: '/img/353543445-254758ac-1510-4ddd-b68d-b3837058e55a.svg',
    selectedPicturePath: '/img/353543446-46df454f-0241-497a-a4bb-9e16fffbcba6.svg',
    urlToBeSelected: ['/area-de-monitores'],
    urlToRedirect: () => '/area-de-monitores',
    allowedUsers: ALLOWED_USERS.MONITORES,
  },
  {
    menuTitle: 'Perfil',
    menuPicturePath: '/img/profile-2851821-1.svg',
    selectedPicturePath: '/img/profile-2851821-5.svg',
    urlToBeSelected: ['/profile'],
    urlToRedirect: () => '/profile',
    allowedUsers: ALLOWED_USERS.PROFILE,
  },
]
