export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, waitFor = 500) => {
  let timeout: NodeJS.Timeout

  const debounced = (...args: Parameters<F>) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), waitFor)
  }

  return debounced
}
