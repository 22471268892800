export const plansColumns = [
  {
    Header: 'Nome',
    accessor: 'name',
    sample: 'sadasdasd',
  },
  {
    Header: 'Preço (R$)',
    accessor: 'price',
    sample: 'R$ 123',
  },
  {
    Header: 'Máximo de estudantes',
    accessor: 'maxActiveStudents',
    sample: '12',
  },
  {
    Header: 'Detalhes',
    accessor: 'details',
    sample: 'asd',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
